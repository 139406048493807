import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react'
import { Container, Row } from 'react-bootstrap'
import IconButtonMedium from "components/elements/IconButtonMedium";
import ButtonMedium1 from 'components/elements/ButtonMedium1';
import Headline6 from 'components/elements/Headline6';
import CaptionText from 'components/elements/CaptionText';
import Image from 'components/elements/Image';
import Heart2 from 'components/icons/Heart2';
import LabelTag1 from './LabelTag1';
import { createUseStyles } from 'react-jss'
import ExplainerTextTiny from './ExplainerTextTiny';
import { translate } from 'utils/Translate';
import { useNavigate } from 'react-router-dom';
import { setupBasketData } from 'utils/setup';
import { pointsConvertor } from "../../utils/general";
import Activity from "utils/Activity";



const jss = createUseStyles({
    ProductWrapper: {
        height: (styleGuide) => styleGuide.productLargeHeightMobile,
        width: (styleGuide) => styleGuide.productLargeWidthMobile,
        flexDirection: 'row'
    },
    ProductCarouselImage: {
        width: '150%',
    },
    '@media screen and (min-width: 768px)': {
        ProductWrapper: {
            height: (styleGuide) => styleGuide.productLargeHeightTab,
            width: (styleGuide) => styleGuide.productLargeWidthTab
        },
    },
    '@media screen and (min-width: 1280px)': {
        ProductWrapper: {
            height: (styleGuide) => styleGuide.productLargeHeight,
            width: (styleGuide) => styleGuide.productLargeWidth,
        },
    },
    '@media screen and (max-width: 1023px)': {
        ProductWrapper: {
            flexDirection: 'column-reverse',
            height: '90vw !important',
        },
        ProductCarouselImage: {
            width: '100%'
        }
    },
    // '@media screen and (min-width: 900px) and (max-width: 1023px)': {
    //     ProductWrapper: {
    //         height: '90vw !important',
    //     }
    // },
    // '@media screen and (min-width: 600px) and (max-width: 900px)': {
    //     ProductWrapper: {
    //         height: '60vw !important',
    //     }
    // },
    '@media screen and (min-width: 500px) and (max-width: 600px)': {
        ProductWrapper: {
            height: '100vw !important',
        }
    },
    '@media screen and (max-width: 500px)': {
        ProductWrapper: {
            height: '120vw !important',
        }
    },
    '@media screen and (max-width: 400px)': {
        ProductWrapper: {
            height: '130vw !important',
        }
    },
    '@media screen and (max-width: 300px)': {
        ProductWrapper: {
            height: '150vw !important',
        }
    },
})

const productInfoHeight = 100

const ProductLarge2 = (props) => {
    const { styleGuide, userData, setBasket } = useContext(AppContext)
    const navigate = useNavigate()
    const classes = jss(styleGuide)
    const showTag = !!props.showTag
    const hideLike = !!props.hideLike
    const imageHeight = `100%`
    const backGroundSize = !!props.imageSize ? '100% 100%' : null;
    const {
        points,
        text1,
        text2,
        wishlistCallback,
        wishlistIconStyles,
        imageSrc,
        imageStyles,
        text1Styles,
        text2Styles,
        pointsStyles,
        tagOptions,
        tagStyles,
        liked,
    } = props
    const productInfoStrip = <ProductInfo {...{ text1, text1Styles, text2, text2Styles, points, pointsStyles }} />
    const isLikedProduct = (typeof liked === 'boolean') ? liked : false
    const likeUnlike = async (e) => {
        if (wishlistCallback) await wishlistCallback(e)
        await setupBasketData({ userData, setBasket })
    }
    return (
        <div
            className={`${classes.ProductWrapper} ${props.className ? props.className : ''}`}
            style={{
                display: 'flex',
                position: 'relative',
                cursor: 'pointer',
                ...props.style
            }}
            onClick={() => {
                if (props.productID) {
                    Activity({ activityType: 'click', activityName: props.activityName, activityArgument: 'Product', activityArgumentID: props.productID });
                    navigate(`/product/${props.productID}`)
                }
            }}
        >

            {!styleGuide.productInfoAsOverlay ? productInfoStrip : null}
            {!!props.showProductStrip ? productInfoStrip : null}
            <Image
                className={classes.ProductCarouselImage}
                src={imageSrc}
                size={backGroundSize}
                style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    zIndex: 0,
                    height: imageHeight,
                    backgroundColor: styleGuide.productInfoBackground,
                    ...imageStyles
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: showTag ? 'space-between' : 'flex-end'
                }}
                >
                    {
                        showTag
                            ? <LabelTag1 {...tagOptions}
                                style={{
                                    alignSelf: 'flex-start',
                                    marginTop: '20px',
                                    marginLeft: '20px',
                                    zIndex: 1,
                                    ...tagStyles
                                }} />
                            : null
                    }
                    {
                        !hideLike
                            ? <IconButtonMedium
                                inverse={isLikedProduct ? true : false}
                                style={{
                                    alignSelf: 'flex-end',
                                    marginTop: '20px',
                                    marginRight: '20px',
                                    zIndex: 1,
                                    ...wishlistIconStyles
                                }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    likeUnlike(e)
                                }
                                }
                                icon={
                                    <Heart2
                                        fill='white'
                                        style={{ height: 10, width: 10 }}
                                        stroke={styleGuide.color7}
                                        strokeWidth="1.5px"
                                    />
                                }
                            />
                            : null
                    }
                </div>
            </Image>
        </div>
    )
}

const ProductInfo = (props) => {
    const { styleGuide, userData, brandData, langCode } = useContext(AppContext)
    const {
        points,
        text1,
        text2,
        text1Styles,
        text2Styles,
        pointsStyles,
    } = props

    const productPrice = points.match(/\d/g).join('')
    const walletAmountPoints = userData && !isNaN(userData.walletAmountPoints) ? Number(userData.walletAmountPoints) : 0
    const deficitPoints = !isNaN(productPrice) ? productPrice - walletAmountPoints : 0
    const isDeficit = brandData?.features?.enableOnePointUsers ? false : deficitPoints > 0 ? true : false
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const taxProduct = userData.taxPercentage ? Math.ceil(points / (brandData.brandPointRatio || 1) * userData.taxPercentage / 100) : null

    return (
        <div
            className='product-large-2-info'
            style={{
                position: 'relative',
                bottom: 0,
                zIndex: 1,
                width: '100%',
                background: styleGuide.productInfoBackground,
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {!!taxProduct && points &&
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: styleGuide.productInfoBackground,
                        textAlign: "end",
                        borderTop: `1px solid ${styleGuide.color4}`,
                        height: 18
                    }}
                >
                    <ExplainerTextTiny
                        style={{ verticalAlign: "text-top", marginRight: 16, paddingTop: 0, paddingBottom: 0, lineHeight: '12px' }}
                        fallbackText={`${translate('_PRODUCT_TAXATION_TEXT_', 'Beskatning kr.')} ${pointsConvertor(brandData,taxProduct, '')}`}
                    />
                </div>
            }
            <div className='product-large-2-label' style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                {!!text1 && <Headline6 style={{ color: styleGuide.productInfoText1Color, ...text1Styles }}> {text1} </Headline6>}
                {!!text2 && <CaptionText style={{ color: styleGuide.productInfoText2Color, ...text2Styles }}> {text2} </CaptionText>}
            </div>
            {brandData && brandData.features && brandData.features.points === false ? null :
                <div className='product-large-2-points' style={{ padding: 20 }}>
                    <ButtonMedium1
                        className='product-large-2-points-button'
                        showIcon={false}
                        style={pointsStyles}>
                        {!!brandData?.features?.disablePrice ? brandData?.features?.productButtonText[langCode] : pointsConvertor(brandData,points, pointSuffix)}
                    </ButtonMedium1>
                </div>}
            {
                !!isDeficit &&
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: styleGuide.color3, textAlign: 'center' }}>
                    <ExplainerTextTiny style={{ verticalAlign: 'middle' }} fallbackText={`${translate('_USER_POINT_DEFICIENT_FOR_PRODUCT_TEXT_', 'Du mangler')} ${pointsConvertor(brandData,deficitPoints, translate("_POINTS_", "point"))}`} />
                </div>
            }
        </div>
    )
}

export default ProductLarge2;