import React from 'react'
import { AppContext } from "contexts/AppContext";
import { useEffect, useContext, useState } from 'react'
import RegisterForm from './RegisterForm'
import RegisterSuccess from './RegisterSuccess'
import RegisterError from './RegisterError'
import "./Register.css"
import RegisterCreated from './RegisterCreated';

const Register = () => {
    const { brandData } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    let brandSlug = brandData && brandData.brandSlug;
    const isOkBrand = brandData && brandData.BrandID === 187 // for OK brand

    return (
        <div>
            <div style={{ display: "flex", width: "100px", height: "35px",margin:'auto',marginTop:'7%',marginBottom:'1%',justifyContent:'center' }}>
                <img alt='Brand Logo' src={brandData && brandData.brandLogo ? brandData.brandLogo:''}/>
            </div>
            {!success && !error && <RegisterForm setError={setError} setSuccess={setSuccess} />}
            {success && !isOkBrand && <RegisterSuccess />}
            {success && !!isOkBrand && <RegisterCreated />}
            {error && <RegisterError
             errorMessage='Du er allerede oprettet i klubben.'
             subMessage={`Venligst log ind på <a class='link' href='http://${brandSlug}.upgrader.club'>${brandSlug}.upgrader.club</a>`}/>}
        </div>
    )
}

export default Register