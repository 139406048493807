import React, { useContext } from 'react'
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, Checkbox, ListItemText } from '@mui/material';
import './css/InputStyles.css'
import { AppContext } from 'contexts/AppContext';
import {createUseStyles} from 'react-jss'
import CaretDown from 'components/icons/CaretDown';
import RoundIconButton1 from './RoundIconButton1';

const jss = createUseStyles({
    labelStyle : {
        color: (styleGuide) => styleGuide.color6,
        "&.Mui-focused":{
            color: (styleGuide) => styleGuide.color6
        }
    }
})

const CustomSelect2 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const defaultUnderlineColor = props.value ? styleGuide.color7 : styleGuide.color4
    const selectStyle = {
        backgroundColor: 'white',
        "&:hover:before": {
            borderBottom : `2pt solid white !important`,
        },
        "&:hover:after": {
            borderBottom : `2pt solid white !important`,
        },
        "&:before": {
            borderBottom : `2pt solid white !important`,
        },
        "&:after": {
            borderBottom : `2pt solid white !important`,
        },
        "& :focus":{
            backgroundColor: 'white'
        }
    }

    const menuItemStyle = {
        ".MuiPaper-root": {
            borderRadius : `${styleGuide.selectDropdownBorderRadius}`,
            bottom: 200
        },
        "ul":{
            borderRadius: 0,
            padding: '0 0 0 0'
        },
        "li:hover":{
            backgroundColor: `${styleGuide.color16} !important`,
            color: `${styleGuide.color1}`
        },
    }

    const labelStyle = {
        zIndex: props.disabled ? 1 : `inherit`,
        marginLeft: '15px',
        color: styleGuide.color6,
        "&.Mui-focused":{
            color: styleGuide.color7
        }
    }

    const helpTextStyle = {
        marginLeft: '15px',
        backgroundColor: 'white'
    }
    const helpText = props.helpObj && props.helpObj.helpText ? props.helpObj.helpText : ''
    const errorText = props.helpObj && props.helpObj.errorText ? props.helpObj.errorText : ''
    return (
        <div style={props.wrapperStyle}>
        <FormControl fullWidth variant="standard" error={props.error} hiddenLabel={false}>
            <InputLabel className={classes.labelStyle} style={{...labelStyle,  ...props.custlabelStyle}} id="select-label">{props.label}</InputLabel>
            <Select 
                variant='standard'
                labelId="select-label"
                style={props.style}
                value={props.value}
                className={props.className}
                onChange={props.onChange}
                sx={selectStyle}
                    MenuProps={
                        {
                            PaperProps: {
                                sx: {
                                    ...menuItemStyle,
                                       width: 100,
                                       minWidth: 'max-content'
                                    }
                                },
                            variant: 'menu',
                        }}
            >   
                {props.emptyValue 
                    ? <MenuItem style={{padding: 10}} value={null}>{props.emptyValue.text || ''} </MenuItem> 
                    : null
                }

                {(props.options && props.options.length)
                    ? props.options.map(elem => {
                            return (
                                <MenuItem key={elem.value} value={elem.value} style={props.customMenuStyle}>
                                    { props.multiple 
                                        ?   <>
                                                <Checkbox checked={!!props.value && props.value.indexOf(elem.value) > -1} />
                                                <ListItemText primary={elem.label} />
                                            </>
                                        : elem.label}
                                </MenuItem>
                            )
                        })
                    : null 
                }
            </Select>
            {!props.disabled && <FormHelperText id="helper-text" style={helpTextStyle}>{ props.error ? errorText : helpText}</FormHelperText>}
        </FormControl>
        </div>
    )
}

export default CustomSelect2;