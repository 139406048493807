const Like = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.333008 11.8327H2.75725V4.83268H0.333008V11.8327ZM13.6663 5.41602C13.6663 4.77435 13.1209 4.24935 12.4542 4.24935H8.62998L9.20573 1.58352L9.22392 1.39685C9.22392 1.15768 9.12089 0.936016 8.95725 0.778516L8.31483 0.166016L4.32695 4.01018C4.1027 4.22018 3.96937 4.51185 3.96937 4.83268V10.666C3.96937 11.3077 4.51483 11.8327 5.18149 11.8327H10.636C11.1391 11.8327 11.5694 11.541 11.7512 11.121L13.5815 7.00852C13.636 6.87435 13.6663 6.73435 13.6663 6.58268V5.41602Z" fill={pathFill}/>
        </svg> 
        )
}
export default Like