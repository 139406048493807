import Translate from "utils/Translate";
import { upgColors, fontFamily } from "styles/styles";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const Headline3 = (props) => {
    const { styleGuide } = useContext(AppContext)

    const headline3Style = {
      fontFamily: styleGuide.fontFamily,
      fontWeight: styleGuide.headline3FontWeight,
      fontSize: styleGuide.headline3FontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.headline3LineHeight,
      color: styleGuide.color7
    }

  
    return (
      <span onClick={props.onClick} style={{...headline3Style, ...props.style}} className={props.className} >
        {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
      </span>
    )
}

export default Headline3