import React, { useContext} from "react";
import Package1 from "./Package1"
import Package2 from "./Package2"
import Package3 from "./Package3"
import { AppContext } from "contexts/AppContext";

function Package() {
    const { defaultTheme } = useContext(AppContext);
  
    const selectPackage = {
      "ThemeONE": <Package1 />,
      "ThemeTWO": <Package2 />,
      "ThemeTHREE": <Package3 />
    }

    return <>
      {selectPackage[defaultTheme]}
    </>
  }
  
  export default Package;