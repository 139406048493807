const CaretDownDouble = (props) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg viewBox="-3 -3 21.00 21.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={pathFill} strokeWidth="1.5" transform="matrix(1, 0, 0, 1, 0, 0)rotate(90)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="1.8"> <path d="M7 14L14 7.5L7 1M1.5 14L8.5 7.5L1.5 1" stroke={pathFill} strokeLinecap="square"></path> </g><g id="SVGRepo_iconCarrier"> <path d="M7 14L14 7.5L7 1M1.5 14L8.5 7.5L1.5 1" stroke={pathFill} strokeLinecap="square"></path> </g></svg>
    )
}
export default CaretDownDouble

