import Radio from '@mui/material/Radio'; // MUIRadio is a custom alias for the default export
import FormControlLabel from '@mui/material/FormControlLabel';
import { RadioFilled, RadioEmpty } from 'components/icons/RadioButton';
import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react';

const CustomRadio = (props) => {
    const {styleGuide} = useContext(AppContext)
    const {className, label, checked, onChange} = props
    return <FormControlLabel 
        label={label || <span style={{marginRight: 0}}></span>}
        control={
            <Radio
                checked={checked}
                onChange={onChange}
                className={className || ''} 
                icon={<RadioEmpty />} 
                checkedIcon={<RadioFilled fill={styleGuide.color16} />} 
            />
        } 
    />
}

export default CustomRadio

