import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Google from '../icons/Google.js';
import Edge from '../icons/Edge.js';
import Safari from '../icons/Safari.js';
import Opera from '../icons/Opera.js';
import Samsung from '../icons/Samsung.js';
import Firefox from '../icons/Firefox.js';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const IconFontStyles = {
  color: '#00adf2',
  fontWeight: 400,
  fontSize: '12px',
  textAlign: 'center',
  textDecoration:'none',
  cursor: 'pointer',
  display:'block'
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
              open={open}
              style={{width:'600px',height:'700px',margin:'auto'}}
      >
        <BootstrapDialogTitle id="customized-dialog-title" style={{fontSize:"28px",textAlign:"center",fontWeight: 'bold',marginTop:'30px'}}>
       Unsupported browser
        </BootstrapDialogTitle>
        <Typography gutterBottom style={{textAlign:"center",fontWeight: 'bold'}}>
        Please use one of the supported browsers:
        </Typography>
        <Typography gutterBottom>
          <div style={{ padding:'0px 25px', margin:'auto', marginTop:15, display:'grid', gridTemplateColumns:'150px 150px 150px', gridTemplateRows:'100px'}}>
            <div>
              <Google />
              <a style={IconFontStyles} target='_blank' href='https://www.google.com/chrome/'>Chrome {"≥"} 100</a>
            </div>
            <div>
              <Safari />
              <a style={IconFontStyles} target='_blank' href='https://www.apple.com/in/safari/'>Safari {"≥"} 14</a>
            </div>
            <div>
              <Opera />
              <a style={{...IconFontStyles, marginTop: '5px' }} target='_blank' href='https://www.opera.com/'>Opera {"≥"} 90</a>
            </div>
            <div>
              <Samsung />
              <a style={IconFontStyles} target='_blank' href='https://www.samsung.com/us/support/owners/app/samsung-internet'>Samsung Internet {"≥"} 14</a>
            </div>
            <div>
              <Firefox />
              <a style={IconFontStyles} target='_blank' href='https://www.mozilla.org/'>Firefox {"≥"} 100</a>
            </div>
            <div>
              <Edge />
              <a style={{ ...IconFontStyles, marginTop: '19px' }} target='_blank' href='https://www.microsoft.com/en-us/edge'>Edge {"≥"} 100</a>
            </div>
          </div>
        </Typography>
        <Typography gutterBottom style={{textAlign:"center",fontWeight: 'bold',margin:'20px 0px'}}>
          <span style={{color:'#00adf2'}}>IE</span> browser is not supported.
        </Typography>
      </BootstrapDialog>
    </div>
  );
}
