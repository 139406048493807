import React, { useContext, useState } from 'react'
import { AppContext } from 'contexts/AppContext';
import { createUseStyles } from 'react-jss'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import CaretDown from 'components/icons/CaretDown';
import { Button, Container, Modal } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import RoundIconButton1 from 'components/elements/RoundIconButton1';
import "./Order.css"
import { translate } from 'utils/Translate';
import ExplainerText from 'components/elements/ExplainerText';
import Headline6 from 'components/elements/Headline6';
import ContentSmall from 'components/elements/ContentSmall';
import Image from 'components/elements/Image';
import { useNavigate } from 'react-router-dom';
import { newLineToBreakTag, pointsConvertor } from 'utils/general';
import { callAPI } from 'utils/API';
import IconButtonMedium from 'components/elements/IconButtonMedium';
import Close from 'components/icons/Close';
import ContentLarge from 'components/elements/ContentLarge';
import ButtonMedium3 from 'components/elements/ButtonMedium3';
import ButtonMedium1 from 'components/elements/ButtonMedium1';

const jss = createUseStyles({
    Order: {

    }
})

const Order = (props) => {
    const { styleGuide, userData, brandData } = useContext(AppContext)
    const { order, refresh } = props
    const [alert, setAlert] = useState(order && order.alertCount)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showResult, setShowResult] = useState(false)

    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const showCancel = brandData?.features?.showCancelSeasonOrder && order?.orderType?.toUpperCase() === "SEASON" && ['NEW','TENTATIVE'].includes(order?.orderFlowStatus?.toUpperCase())
    const cancelOrder = async (event) => {
        event.stopPropagation()
        setShowConfirmation(false)
        if(!order?.OrderID) return 
        const cancelResult = await callAPI("order/cancel", "POST", { body: { OrderID: order.OrderID }})
        if(cancelResult?.affectedRows || cancelResult?.changedRows) {
            refresh()
            setShowResult(true)
            setTimeout(()=>{
                setShowResult(false)
            }, 5000)
        }
    }

    return !order ? null : <>
        <Modal
            show={showResult}
            onHide={() => setShowResult(false)}
            centered
        >
            <Modal.Header
                className="pb-0 border-0"
                closeButton
                style={{ fontSize: 9 }}
            />
            <Modal.Body className="pt-0">
                <ContentLarge fallbackText="Order cancelled" translationKey="_ORDER_CANCEL_SUCCESS_" />
            </Modal.Body>
        </Modal>
        <Modal
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            centered
        >
            <Modal.Header
                className="pb-0 border-0"
                closeButton
                style={{ fontSize: 9 }}
            />
            <Modal.Body className="pt-0">
                <ContentLarge fallbackText="Confirm cancellation" translationKey="_ORDER_CANCEL_CONFIRM_CAPTION_" />
            </Modal.Body>
            <Modal.Footer>
                <ButtonMedium1 showIcon={false} translationKey="_CANCEL_" fallbackText="Afvis" onClick={cancelOrder} />
                <ButtonMedium3 showIcon={false} translationKey="_BUTTON_CAPTION_CLOSE_" fallbackText="Luk" onClick={(event) => {
                    event.stopPropagation()
                    setShowConfirmation(false)
                    }} />
            </Modal.Footer>
        </Modal>
        <div style={{position: "relative"}}>
            {
                alert && (
                    <div style={{position: "absolute", top: 45, padding: '5px 15px', zIndex: 2}}>
                        <Image style={{ width: 15, height: 30 }} src={`url(https://i.imgur.com/8ZvWaVU.png)`} />
                    </div> 
                )
            }
            <Accordion id='orders-block' onClick={async()=> {
                if(alert) {
                    const updateAlertCount = await callAPI("order/updateOrderAlert", "POST", { body: { OrderID: order.OrderID }});
                    if(updateAlertCount) setAlert(null)
                }
            }}>
                <AccordionSummary expandIcon={<RoundIconButton1 icon={<CaretDown fill="white" />} />} >
                    <div className='order-summary'>
                        <div className='order-summary-one'>
                            <div className='order-status'>
                                <ExplainerText translationKey='_ORDERLIST_ORDER_STATUS_CAPTION_' fallbackText='Ordrestatus' />
                                <Headline6 translationKey={order.orderFlowStatusTranslationKey} fallbackText={`${order.orderFlowStatus || '-'}`} />
                            </div>
                            <div className='order-number'>
                                <ExplainerText translationKey='_ORDERLIST_ORDER_NUMBER_CAPTION_' fallbackText='Ordrenummer' />
                                <Headline6 fallbackText={`${order.OrderID || '-'}`} />
                            </div>
                        </div>
                        <div className='order-summary-two'>
                            <div className='order-date'>
                                <ExplainerText fallbackText={`${order.date || '-'}`} />
                                <Headline6 fallbackText={`${ pointsConvertor(brandData,(order.points + order.totalFreightPoints), pointSuffix)}`} />
                            </div>
                            <div className='order-track'>
                                <ExplainerText translationKey='_ORDERLIST_ORDER_TRACK_CAPTION_' fallbackText='Følg din ordre' />
                                {
                                    !!order.track
                                        ? <a href={`//gls-group.eu/DK/da/find-pakke?match=${order.track}`} target='_blank' rel='noreferrer' style={{ textDecoration: 'none', marginRight: '54px' }}>
                                            <Headline6 style={{ color: styleGuide.color16 }} translationKey='_ORDERLIST_ORDER_TRACK_LINK_LABEL_' fallbackText={order.track} />
                                        </a>
                                        : <Headline6 style={{ color: styleGuide.color4, cursor: 'not-allowed' }} translationKey='_ORDERLIST_ORDER_TRACK_LINK_LABEL_' fallbackText='TRACK AND TRACE' />

                                }
                            </div>
                            {
                                !!showCancel && <div className='order-status'>
                                    <ExplainerText 
                                        translationKey='_ORDERLIST_ORDER_CANCEL_CAPTION_' 
                                        fallbackText='Cancel order' 
                                        />
                                    <IconButtonMedium 
                                        title={translate('_ORDERLIST_ORDER_CANCEL_CAPTION_', 'Cancel order')}
                                        icon={<Close />}
                                        onClick={(event)=>{
                                            event.stopPropagation()
                                            setShowConfirmation(true)
                                        }}
                                        />
                                </div>
                            }
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0, borderTop: `1px solid ${styleGuide.color4}` }} >
                    <div className='order-items-container'>
                        <div className='order-items'>
                            {
                                order.orderLines.map((orderLine, idx, arr) => <OrderItem key={idx} showDivider={idx < arr.length - 1} orderLine={orderLine} />)
                            }
                        </div>
                        <div style={{display: 'flex', flexDirection:'column', gap: '16px'}}>
                            <div className='order-items-address'>
                                <div style={{ backgroundColor: styleGuide.color2, width: '100%', display: 'flex', flexDirection: 'column', gap: 5, padding: 30, maxHeight: 200 }}>
                                    <Headline6 style={{ marginBottom: 20 }} translationKey="_BASKET_OVERVIEW_DELIVERY_INFORMATION_CAPTION_" fallbackText="Leveringsoplysninger" />
                                    <ContentSmall fallbackText={order.orderConsigneeName || userData.userName} />
                                    <ContentSmall fallbackText={`${order.orderConsigneeAddress1}, ${order.orderConsigneeZipcode} ${order.orderConsigneeCity}`} />
                                    <ContentSmall fallbackText={userData.userEmail || ''} />
                                    <ContentSmall fallbackText={!userData.userPhone? translate('_USER_PHONE_NOT_AVAILABLE_', 'Telefonnummer er ikke tilgængeligt') : userData.userPhone}
                                    />
                                </div>
                                {
                                    order && order.orderRemark && (
                                        <div style={{padding: 30}}>
                                            <span>
                                                <Headline6 translationKey="_REMARK_" fallbackText='Bemærkning: ' />
                                            </span>
                                            {newLineToBreakTag(order.orderRemark)}
                                        </div>
                                    )
                                }
                            </div>
                            {order?.totalFreightPoints > 0 && <div className='order-items-address'>
                                <div style={{ backgroundColor: styleGuide.color2, width: '100%', display: 'flex', flexDirection: 'column', gap: 5, padding: 30, maxHeight: 200 }}>
                                    <Headline6 style={{ marginBottom: 20 }} translationKey="_BASKET_OVERVIEW_FREIGHT_OVERVIEW_CAPTION_" fallbackText="Fragt" />
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <ContentSmall translationKey="_BASKET_OVERVIEW_FREIGHT_OVERVIEW_ORDERVALUE_LABEL_" fallbackText={'Samlet ordreværdi'} />
                                        <ContentSmall fallbackText={`${ pointsConvertor(brandData,order.points, pointSuffix)}`} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <ContentSmall translationKey="_BASKET_OVERVIEW_FREIGHT_OVERVIEW_POINT_LABEL_" fallbackText={'Fragt points'} />
                                        <ContentSmall fallbackText={`${ pointsConvertor(brandData,order.totalFreightPoints, pointSuffix)}`} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <ContentSmall translationKey="_BASKET_OVERVIEW_FREIGHT_OVERVIEW_TOTAL_LABEL_" fallbackText={'Total inkl. fragt'} />
                                        <ContentSmall fallbackText={`${ pointsConvertor(brandData,(order.points + order.totalFreightPoints), pointSuffix)}`} />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    </>
}

const OrderItem = (props) => {
    const { styleGuide, brandData, langCode } = useContext(AppContext)
    const { orderLine, showDivider } = props
    const navigate = useNavigate()
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    const goToProduct = () => navigate(`/product/${orderLine.ProductID}`)

    const productTitle = !!orderLine && !!orderLine.translations && !!orderLine.translations.length > 0
        ? orderLine.translations.find(
                (translation) => translation.languageKey === langCode
        ) 
        ? orderLine.translations.find(
            (translation) => translation.languageKey === langCode
        ).elementTitle
            : orderLine.elementTitle
        : orderLine.elementTitle

    return !orderLine ? null : <>
        <div className='order-item-wrapper' style={{ borderColor: showDivider ? styleGuide.color4 : 'transparent' }}>
            <div className='order-item-container' >
                <Image className='order-item-image' style={{ borderColor: styleGuide.color2 }} src={`url(${orderLine.imageUrl})`} onClick={goToProduct} />
            </div>
            <div className='order-item-details'>
                <div className='order-item-info'>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <Headline6 fallbackText={orderLine.manufacturer || ' '} />
                        <ExplainerText onClick={goToProduct} style={{ color: styleGuide.color5 }} fallbackText={productTitle} />
                        <div style={{ display: 'flex', marginTop: '0.25rem', alignItems: 'center' }}>
                            {
                                ((orderLine.variationColor && orderLine.variationSize) || orderLine.variationTitle) &&
                                <>
                                    <ExplainerText
                                        style={{ color: styleGuide.color5 }}
                                        fallbackText={orderLine.variationSize ? `${orderLine.variationSize}` : `${orderLine.variationTitle}`}
                                    />
                                    {
                                        orderLine.variationColor
                                            ? <ExplainerText style={{ color: styleGuide.color5 }} fallbackText={`, ${orderLine.variationColor}`} />
                                            : null
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <ExplainerText style={{ color: styleGuide.color5 }} fallbackText={
                            <>
                                <ExplainerText style={{ color: styleGuide.color5 }} translationKey="_QUANTITY_" fallbackText="Antal" />
                                <span>: {orderLine.quantity || '-'}</span>
                            </>
                        } />

                    </div>
                </div>
                <div className='order-item-points'>
                    <Headline6 style={{ color: styleGuide.color16 }} fallbackText={`${ pointsConvertor(brandData,orderLine.points, pointSuffix)}`} />
                </div>
            </div>
        </div>
    </>
}

export default Order;

export const Divider = (props) => <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch', height: 1, backgroundColor: props.color || 'black' }} />
