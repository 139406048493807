import {Col} from "react-bootstrap";
import Footer from "components/blocks/footer/Footer";
import Header from "components/blocks/header/Header";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "contexts/AppContext";
import { callAPI } from "utils/API";
import PostCard from "components/elements/PostCard";
import { Grid } from "@mui/material";
import Headline3 from "components/elements/Headline3";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import { createUseStyles } from "react-jss";
import { getVideoType } from "components/elements/Video";
import { Link } from 'react-router-dom'
import _ from 'lodash'
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import ProductNormal from "components/elements/ProductNormal";
import { useNavigate } from "react-router-dom";
import HomePageBanner1 from "components/blocks/homePageBanner/HomePageBanner1";
import HomePageBanner2 from "components/blocks/homePageBanner/HomePageBanner2";
import HomePageBanner3 from "components/blocks/homePageBanner/HomePageBanner3";
import {translate} from "utils/Translate"
import ProductLarge from "components/elements/ProductLarge";
import ProductLarge2 from "components/elements/ProductLarge2";
import HighlightedProductBlock from "components/blocks/highlightedProductBlock/HighlightedProductBlock";
import CountDownBlock from "components/blocks/countDownBlock/CountDownBlock";
import Headline4 from "components/elements/Headline4";
import Headline2 from "components/elements/Headline2";
import { CPRPopUp } from 'components/blocks/cprPopUp/CPRPopUP';
import Activity from "utils/Activity";
import HomePageBanner4 from "components/blocks/homePageBanner/HomePageBanner4";
import Information from "components/blocks/InfromationBlock/Information";
import ScrollButton from "components/elements/ScrollButton";
import { pointsConvertor } from "utils/general";
import moment from "moment";
import TaxCampaignHighlightBlock from "components/blocks/TaxCampaignHighlightBlock/TaxCampaignHighlightBlock";
import HOFBlock from "components/blocks/HOFBlock/HOFBlock";

function hexToRgb(hex, a) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : null;
}

const jss = createUseStyles({
  ThemeONEhomeBanner: {
    marginBottom: 50,
  },
  ThemeTWOhomeBanner: {
    marginBottom: 100,
  },
  ThemeTHREEhomeBanner: {
    marginBottom: 150,
  },
  seeMoreButton: {
    position: "relative",
    marginTop: 30,
  },
  contentWrapper: {
    paddingLeft: "10%",
    paddingRight: "10%",
    display: "flex",
    flexDirection: "column",
  },
  categorySectionWrapper: {
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    color: "white"
  },
  categoryName:{
    position: "absolute",
    bottom: "20px",
    left: "20px",
    fontSize: "25px",
  },
  "@media screen and (max-width: 576px)": {
    ThemeONEhomeBanner: {
      marginBottom: 100,
    },
    ThemeTWOhomeBanner: {
      marginBottom: 150,
    },
  },
  "@media screen and (min-width: 576px) and (max-width: 768px)": {
    ThemeONEhomeBanner: {
      marginBottom: 100,
    },
    ThemeTWOhomeBanner: {
      marginBottom: 200,
    },
  },
  "@media screen and (min-width: 768px) and (max-width: 976px)": {
    ThemeONEhomeBanner: {
      marginBottom: 100,
    },
    ThemeTWOhomeBanner: {
      marginBottom: 150,
    },
  },
  "@media screen and (min-width: 976px) and (max-width: 1024px)": {
    ThemeONEhomeBanner: {
      marginBottom: 100,
    },
    ThemeTWOhomeBanner: {
      marginBottom: 350,
    },
  },
  "@media screen and (min-width: 1280px)": {
    seeMoreButton: {
      position: "absolute",
      marginTop: 0,
      top: 15,
      right: 0,
    },
    contentWrapper: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
});

const maxLengthForNewsTitle = 50
const maxLengthForNewsDescription = 75

const Home = () => {
    const { accessibleBlogs,brandData,defaultTheme, themeConfig,langCode } = useContext(AppContext);
    const [latestPosts, setLatestPosts] = useState(null);
    const [informationPosts, setInformationPosts] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [hofs, setHofs] = useState([]);
    const [christmasCalendars, setChristmasCalendars] = useState([]);
    const [othersLookingProducts, setOthersLookingProducts] = useState([]);
    const [scrollTargets, setScrollTargets] = useState([]);
    const classes = jss();
    let {frontPageBannerColor,frontPageBannerContent,frontPageBannerTitle,frontPageTextColorBlack, enableFrontPageFAQButton=false}= brandData && brandData.features
  
    const fetchCategories = async () => {
      let categories = await callAPI("/product/suggestedCategories", "GET");
      if(categories && categories.length > 0){
        categories = categories.filter(category => category && category.imageURL && category)
      }
      setCategoriesData( _.shuffle(categories))
      return
    }

    const fetchPopularProducts = async () => {
      let popularProductsFromAPI = await callAPI("/product/popularProducts", "GET");
      if(!popularProductsFromAPI || !popularProductsFromAPI.length) return
      popularProductsFromAPI.sort((a,b) => new Date(b.catalogTimeInsert) - new Date(a.catalogTimeInsert))
      popularProductsFromAPI = popularProductsFromAPI.slice(0,10).map(product=>{
        if(product.images && product.images.length){
          product.images.sort((a,b) => a.poolOrder - b.poolOrder);
          product.imageSrc = product.images[0].imageOriginSource;
        }
        return product
      });
      return setPopularProducts(popularProductsFromAPI)
    }

    const fetchOthersLookingProducts = async () => {
      let othersLookingProductsFromAPI = await callAPI("/product/othersLookingProducts", "GET");
      if(!othersLookingProductsFromAPI || !othersLookingProductsFromAPI.length) return
      othersLookingProductsFromAPI.sort((a,b) => new Date(b.catalogTimeInsert) - new Date(a.catalogTimeInsert))
      othersLookingProductsFromAPI = othersLookingProductsFromAPI.slice(0,10).map(product=>{
        if(product.images && product.images.length){
          product.images.sort((a,b) => a.poolOrder - b.poolOrder);
          product.imageSrc = product.images[0].imageOriginSource;
        }
        return product
      });
      return setOthersLookingProducts(othersLookingProductsFromAPI)
    }

    const fetchLatestPosts = async () => {
      if (!accessibleBlogs.length) return []
      const BlogIDs = accessibleBlogs
          .filter(item => item.blogType === 'NEWS')
        .map((item) => item.BlogID);
      const limit = 2;
      const sortBy = "timeStart";
      const sortOrder = "DESC";
      const latestPosts = await callAPI(
          "/post/getPostsOfMultipleBlogs",
          "GET",
          { query: { BlogIDs, sortBy, sortOrder, limit } }
      );
      if(latestPosts && latestPosts.length > 0) {
        setLatestPosts(latestPosts)
      }
    }

    const fetchLatestInformationPosts = async () => {
      if (!accessibleBlogs.length) return []
      const BlogIDs = accessibleBlogs
          .filter(item => item.blogType === "INFORMATION")
        .map((item) => item.BlogID);
      const sortBy = "timeStart";
      const sortOrder = "DESC";
      const latestPosts = await callAPI(
          "/post/getPostsOfMultipleBlogs",
          "GET",
          { query: { BlogIDs, sortBy, sortOrder } }
      );
      if(latestPosts && latestPosts.length > 0) {
        setInformationPosts(latestPosts)
      }
    }
  
    const fetchCompetition = async () => {
      const competitions = await callAPI(`/competitions`);
      let competitionsArr = []
      let christmasCalendarArr = []
      competitions && competitions.length && competitions.forEach(competition => {
        if(competition.competitionType === "CHRISTMAS_CALENDAR") {
          christmasCalendarArr.push(competition)
        } else {
          competitionsArr.push(competition)
        }
      });
      setCompetitions(competitionsArr)
      setChristmasCalendars(christmasCalendarArr)
    }
  
    const fetchHofs = async () => {
      const hofs = await callAPI(`/halloffame`);
      setHofs(hofs)
    }
  
    useEffect(() => {
      fetchCompetition()
      fetchHofs()
      !themeConfig.hideHomeCategoriesBlock && fetchCategories()
      !themeConfig.disablePopularProduct && fetchPopularProducts()
      !themeConfig.hideOthersLookingBlock && fetchOthersLookingProducts()
    }, []);
    
    useEffect(async() => {
      fetchLatestPosts()
      !!themeConfig.homeInformationBlock && fetchLatestInformationPosts()
    }, [accessibleBlogs])

    useEffect(() => {
      const sections = ["HomepageBanner"]
      if(!themeConfig.hideHighlightedProductsBlock) sections.push("HighlightedProductBlock")
      if(!themeConfig.hideHomeCategoriesBlock && categoriesData && !!categoriesData.length) sections.push("CategorySection")
      if(!!brandData?.features?.isCountDown) sections.push("CountDownBlock")
      if(themeConfig.homeInformationBlock && !!informationPosts?.length) sections.push("InformationBlock")
      if(!!competitions?.length) sections.push("CompetitionsBlock")
      if(!!christmasCalendars?.length) sections.push("ChristmasCalendarBlock")
      if(!!latestPosts) sections.push("LatestPostsSection")
      if(!themeConfig.hideOthersLookingBlock && !!othersLookingProducts?.length) sections.push("OthersLookingProductsSection")
      sections.push("footer")
      setScrollTargets([...sections])
    },[brandData, themeConfig, latestPosts, christmasCalendars, competitions, document.documentElement.offsetHeight])
    return (
      <>
        {/* {brandData && brandData.features && brandData.features.LaunchCPRPopupAfterLogin && <CPRPopUp />} */}
        <div className={classes[`${defaultTheme}homeBanner`]}>
          <HomepageBanner
            carouselItems={popularProducts}
            homeBannerTitle={frontPageBannerTitle && typeof frontPageBannerTitle === 'object' ?
              frontPageBannerTitle.hasOwnProperty(langCode) ? frontPageBannerTitle[langCode] : '' : frontPageBannerTitle ? frontPageBannerTitle : ''}
            homeBannerText={frontPageBannerContent && typeof frontPageBannerContent === 'object' ?
              frontPageBannerContent.hasOwnProperty(langCode) ?  frontPageBannerContent[langCode] : '' : frontPageBannerContent ? frontPageBannerContent : ''}
            homeBannerTextColor={frontPageTextColorBlack}
            homeBannerColor={frontPageBannerColor} 
            enableHomeBannerFAQButton={enableFrontPageFAQButton}/></div>
        <div className={`${defaultTheme}homeContent`}>
          {!themeConfig.hideHighlightedProductsBlock && <HighlightedProductBlock className="homeContentWrapper" />}
          <TaxCampaignHighlightBlock className="homeContentWrapper" />
          {!!hofs?.length && <HofsBlock hofs={hofs} />}
          {!themeConfig.hideHomeCategoriesBlock && categoriesData && !!categoriesData.length && <CategorySection categories={categoriesData} />}
          <CountDownBlock styles={{marginTop: 150}} />
          {themeConfig.homeInformationBlock && !!informationPosts?.length && <InformationBlock informationPosts={informationPosts} />}
          {!!competitions?.length && <CompetitionsBlock competitions={competitions} />}
          {!!christmasCalendars?.length && <ChristmasCalendarBlock christmasCalendars={christmasCalendars} />}
          {!!latestPosts ? <LatestPostsSection latestPosts={latestPosts} /> : null }
          {!themeConfig.hideOthersLookingBlock && !!othersLookingProducts?.length && <OthersLookingProductsSection othersLookingProducts={othersLookingProducts} refreshOthersLookingProducts={fetchOthersLookingProducts}/>}
        </div>
        <ScrollButton scrollTargets={[...scrollTargets]} />
      </>
    )
}
export default Home;

const HomepageBanner = (props) => {
  const { defaultTheme, styleGuide, accessibleShops, brandData, langCode } = useContext(AppContext);
  const { carouselItems, homeBannerText, homeBannerTitle,homeBannerColor,homeBannerTextColor, enableHomeBannerFAQButton } = props
  const ShopID = accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null 
  const navigate = useNavigate()
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  switch (defaultTheme) {
    case 'ThemeONE':
      return <HomePageBanner1 
        backgroundColor= { homeBannerColor || styleGuide.color14}
        title={homeBannerTitle || 'Overskrift for velkomst'}
        text={homeBannerText || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
        textColor={homeBannerTextColor ? styleGuide.color18 : styleGuide.color1}
        buttonText={ShopID ? translate('_HOME_BANNER_BUTTON_TEXT_', 'Gå til shoppen') : null}
        buttonAction={() => {
          Activity({activityType: 'click',activityName: 'home',activityArgument: 'Shop',activityArgumentID: ShopID})
          navigate(`shop/${ShopID}`)}}
        enableFAQButton={enableHomeBannerFAQButton}
        faqButtonText={enableHomeBannerFAQButton? translate('_HOME_BANNER_FAQ_BUTTON_TEXT_','Gå til FAQ') : null}
        faqButtonAction = { () => {
          Activity({activityType: 'click',activityName: 'home',activityArgument: 'FAQ',activityArgumentID: ''})
          navigate(`help`)
        }}
        carouselItems={ carouselItems.map((item, idx) => 
          <ProductLarge2
          // <ProductLarge
            key={idx}
            productID={item.ProductID}
            showTag={item.enableDiscount ? true : false}
            tagOptions={{
                fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                showClose: false,
            }}
            tagStyles={{borderRadius: 8}}
            points={(item.enableMulti && item.minCount > 0) 
              ? `${item.discountPointComputed 
                  ? item.minCount * item.discountPointComputed 
                  : item.minCount * item.productPointComputed}` 
              : `${item.discountPointComputed 
                  ? item.discountPointComputed 
                  : item.productPointComputed}`}
            className="bannerProduct"
            hideLike={true}
            style={{ width: '100%' }}
            text2={!!item.translations &&
                  !!item.translations.length
                  ? item.translations.find(
                      (translation) => translation.languageKey === langCode
                    ) ? item.translations.find(
                      (translation) => translation.languageKey === langCode
                    ).elementTitle
                  : item.elementTitle
              : item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
            text1={item.manufacturer}
            imageSrc={`url(${item.imageSrc})`}
            activityName='home'
          />)
        }
      />

    case 'ThemeTWO':
      return <HomePageBanner2
      backgroundColor= { homeBannerColor || styleGuide.color14}
      title={homeBannerTitle || 'Overskrift for velkomst'}
      text={homeBannerText || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
      textColor={homeBannerTextColor ? styleGuide.color18 : styleGuide.color1}
      buttonText={translate('_HOME_BANNER_BUTTON_TEXT_', 'Gå til shoppen')}
      buttonAction={() => {Activity({activityType: 'click',activityName: 'home',activityArgument: 'Shop',activityArgumentID: ShopID})
      navigate(`shop/${ShopID}`)}}
    />

    case 'ThemeTHREE':
      return <HomePageBanner3 
      backgroundColor= { homeBannerColor || styleGuide.color14}
      title={homeBannerTitle || 'Overskrift for velkomst'}
      text={homeBannerText || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
      textColor={homeBannerTextColor ? styleGuide.color18 : styleGuide.color1}
      buttonText={ShopID ? translate('_HOME_BANNER_BUTTON_TEXT_', 'Gå til shoppen') : null}
      buttonAction={() => {
        Activity({activityType: 'click',activityName: 'home',activityArgument: 'Shop',activityArgumentID: ShopID})
        navigate(`shop/${ShopID}`)}}
      carouselItems={ carouselItems.map((item, idx) => 
        <ProductLarge2
          key={idx}
          productID={item.ProductID}
          showTag={item.enableDiscount ? true : false}
          tagOptions={{
              fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed,pointSuffix)}`,
              showClose: false,
          }}
          imageSize={true}
          tagStyles={{borderRadius: 8}}
          points={(item.enableMulti && item.minCount > 0) 
            ? `${item.discountPointComputed 
                ? item.minCount * item.discountPointComputed 
                : item.minCount * item.productPointComputed}` 
            : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`
          }
          showProductStrip={true}
          hideLike={true}
          style={{ width: '100%',height:'600px'}}
          text2={!!item.translations &&
            !!item.translations.length
            ? item.translations.find(
                (translation) => translation.languageKey === langCode
              ) ? item.translations.find(
                (translation) => translation.languageKey === langCode
              ).elementTitle
            : item.elementTitle
        : item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
          text1={item.manufacturer}
          imageSrc={`url(${item.imageSrc})`}
          activityName='home'
        />)
      }
    />

    case 'ThemeFOUR':
      return <HomePageBanner4
      backgroundColor= { homeBannerColor || styleGuide.color14}
      title={homeBannerTitle || 'Overskrift for velkomst'}
      text={homeBannerText || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
      textColor={homeBannerTextColor ? styleGuide.color18 : styleGuide.color1}
    />
  }
  return null
}

export const CompetitionsBlock = (props) => {
  const contextState = useContext(AppContext);
  const {
    styleGuide,
  } = contextState;
  const {competitions} = props
  const classes = jss()
  const navigate = useNavigate()

  return (
    <div id="CompetitionsBlock" style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 100, marginBottom: 100 , position: 'relative'}}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Headline2 translationKey="_HOME_PAGE_COMPETITION_" fallbackText="Konkurrencer" />
        </div>
        {
          competitions && competitions.length < 2 ?
          <>
            {competitions.map((competition, idx) => (
              <CountDownBlock key={idx} competition={competition} styles={{marginTop: 50}} buttonTitle="Deltag i konkurrencen"/>
            ))}
          </> :
          <div className="countDownHorizontalItems">
            <HorizontalScrollBlock>
              {competitions.map((competition, idx) => (
                <CountDownBlock key={idx} competition={competition} buttonTitle="Deltag i konkurrencen"/>
              ))}
            </HorizontalScrollBlock>
          </div>
        }
      </div>
    </div>
  )
}

export const HofsBlock = (props) => {
  const contextState = useContext(AppContext);
  const {
    styleGuide,
  } = contextState;
  const {hofs} = props
  const classes = jss()
  const navigate = useNavigate()

  return (
    <div id="CompetitionsBlock" style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 100, marginBottom: 100 , position: 'relative'}}>
      <div>
        {
          hofs && hofs.length < 2 ?
          <>
            {hofs.map((hof, idx) => (
              <HOFBlock hof={hof} key={idx} styles={{marginTop: 50}}/>
            ))}
          </> :
          <div className="countDownHorizontalItems">
            <HorizontalScrollBlock>
              {hofs.map((hof, idx) => (
                <HOFBlock key={idx} hof={hof}/>
              ))}
            </HorizontalScrollBlock>
          </div>
        }
      </div>
    </div>
  )
}

export const ChristmasCalendarBlock = (props) => {
  const contextState = useContext(AppContext);
  const {
    styleGuide,
  } = contextState;
  const {christmasCalendars} = props
  const classes = jss()
  const navigate = useNavigate()

  return (
    <div id="ChristmasCalendarBlock" style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 100, marginBottom: 100 , position: 'relative'}}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 50 }}>
          <Headline2 translationKey="_HOME_PAGE_CHRISTMAS_CALENDAR_" fallbackText="Julekalender" />
        </div>
        {
          christmasCalendars && christmasCalendars.length < 2 ?
          <>
            {christmasCalendars.map((christmasCalendar, idx) => (
              <CountDownBlock key={idx} competition={christmasCalendar} styles={{marginTop: 50}} countdown={false} buttonTitle="Deltag"/>
            ))}
          </> :
          <div className="countDownHorizontalItems">
            <HorizontalScrollBlock>
              {christmasCalendars.map((christmasCalendar, idx) => (
                <CountDownBlock key={idx} competition={christmasCalendar} countdown={false} buttonTitle="Deltag"/>
              ))}
            </HorizontalScrollBlock>
          </div>
        }
      </div>
      <div className={classes.seeMoreButton}>
        <ButtonMedium4
          style={{ margin: "auto" }}
          showIcon={false}
          translationKey="_HOME_CHRISTMAS_CALENDAR_SEE_MORE_BUTTON_"
        >
          Se alle Julekalendere
        </ButtonMedium4>
      </div>
    </div>
  )
}

export const InformationBlock = (props) => {
  const contextState = useContext(AppContext);
  const {
    styleGuide,
  } = contextState;
  const {informationPosts} = props
  const classes = jss()
  const navigate = useNavigate()

  return (
    <div id="InformationBlock" style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 100, marginBottom: 100 , position: 'relative'}}>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Headline2 translationKey="_HOME_PAGE_INFORMATIONS_" fallbackText="Information" />
        </div>
        {
          informationPosts && informationPosts.length < 2 ?
          <>
            {informationPosts.map((information, idx) => (
              <Information key={idx} information={information} styles={{marginTop: 50}}  buttonTitle="See mere"/>
            ))}
          </> :
          <div className="countDownHorizontalItems">
            <HorizontalScrollBlock>
              {informationPosts.map((information, idx) => (
                <Information key={idx} information={information} buttonTitle="See mere"/>
              ))}
            </HorizontalScrollBlock>
          </div>
        }
      </div>
      <div className={classes.seeMoreButton}>
        <Link to={`/informations`} style={{ textDecoration: "none" }}>
          <ButtonMedium4
            style={{ margin: "auto" }}
            showIcon={false}
            translationKey="_HOME_INFORMATIONS_SEE_MORE_BUTTON_"
          >
            Se alle informationer
          </ButtonMedium4>
        </Link>
      </div>
    </div>
  )
}

const LatestPostsSection = (props) => {
  const navigate = useNavigate()
  const { accessibleBlogs, menuData} = useContext(AppContext);
  const classes = jss()
  const latestNewsSectionStyles = {
    marginTop: 100,
    marginBottom: 100,
    position: 'relative',
  }
  if(!props.latestPosts) return (
    <Col xs='12' className='mb-4 text-center'>
      <h5>Der er ingen poster på denne blog</h5>
    </Col>
  )

  let blogHeading

  if (!!menuData || menuData.length) {
    const blogMenuItem = menuData.filter(menu => menu.poolName.toLowerCase() === 'blog')
    if (!!blogMenuItem || blogMenuItem.length) {
      blogHeading = blogMenuItem[0].menuTitle || "Nyheder"
    }
  }

  const isVideo = (attachments) => {
    const videoAttachment = attachments.filter(item => item.LinkID && item.linkRef);
    return videoAttachment.length ? true : false;
  }

  const getVideoLink = (attachments) => {
    const videoAttachment = attachments.filter(item => item.LinkID && item.linkRef);
    const link = videoAttachment.length ? videoAttachment[0].linkRef : null
    const videoInfo = getVideoType(link)
    return videoInfo ? videoInfo.url : null
  }

  return (
    <div id="LatestPostsSection" style={latestNewsSectionStyles}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        <Grid item xs={12} md={6} style={{marginBottom:30}}>
          <Headline3 translationKey='_HOME_NEWS_SECTION_CAPTION_' fallbackText={blogHeading}></Headline3>
        </Grid>
        <Grid item xs={12} md={6}>
          <div></div>
        </Grid>
        {
          props.latestPosts.sort((a,b) => a.poolOrder - b.poolOrder).map(post => {
            post.attachments.sort((a,b) => a.poolOrder - b.poolOrder)
            const date = moment(post.timeStart || post.timeInsert).format('DD.MM.YYYY')
            const cardImage = post.cardImage || (post.attachments.length && post.attachments[0].imageOriginSource ? post.attachments[0].imageOriginSource : 'https://i.imgur.com/qtmXhnZ.jpg')
            const video = post.attachments.length && isVideo(post.attachments) ? getVideoLink(post.attachments) : null
            const blog = accessibleBlogs && accessibleBlogs.length ? accessibleBlogs.filter(item => item.BlogID === post.BlogID) : null
            const blogName = blog && blog.length ? blog[0].moduleName : ''
            return (
              <Grid item xs={12} md={6} key={post.PostID}>
                <PostCard
                  onClick={() => {
                    Activity({activityType: 'click',activityName: 'home',activityArgument: 'Post',activityArgumentID: post.PostID})
                    navigate(`post/${post.PostID}`)}}
                  caption = { blogName } 
                  title = { post.elementTitle.length > maxLengthForNewsTitle ? post.elementTitle.substr(0, maxLengthForNewsTitle-3) + '...' : post.elementTitle || '' }
                  description = { post.elementContent.substr(0, maxLengthForNewsDescription - 3) + '...' || '' }
                  date = { date }
                  imageSrc = { `url(${cardImage})` }
                  type={ video ? 'video' : 'image' }
                  videoLink={ video }
                />
              </Grid>
            )
          })
        }
      </Grid>
      <div className={classes.seeMoreButton}>
        <Link to={`/posts`} style={{ textDecoration: "none" }}>
        <ButtonMedium4 style={{margin: 'auto'}} showIcon={false} translationKey='_HOME_NEWS_SEE_MORE_BUTTON_'>Se flere nyheder</ButtonMedium4>
        </Link>
      </div>
    </div>
  )
}

const CategorySection = (props) => {
  const { styleGuide, accessibleShops, langCode } = useContext(AppContext);
  const classes = jss();
  const navigate = useNavigate()
  const ShopID = accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null  
  const CategorySectionStyles = {
    position: "relative",
    marginTop: 100
  };
  return !props || !props.categories ? null : (
    <div id="CategorySection" style={CategorySectionStyles} >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6} style={{ marginBottom: 30 }}>
          <Headline3 translationKey="_HOME_CATEGORY_SECTION_CAPTION_">
            Måske disse kategorier er noget for dig?
          </Headline3>
        </Grid>
        <Grid item xs={12} md={6}>
          <div></div>
        </Grid>
        {props.categories.slice(0,3).map((category) => {
          return (
               <Grid item xs={12} md={4} key={category.CategoryID} style={{margin: 'auto'}}>
              <div
                className={classes.categorySectionWrapper}
                style={{
                  boxShadow: `0px 0px 4px ${styleGuide.color4}`,
                }}
                onClick={ShopID ? () => {
                  Activity({activityType: 'click',activityName: 'home',activityArgument: 'Category',activityArgumentID: category.CategoryID})
                  navigate(`shop/${ShopID}?category=${encodeURIComponent(category.categoryName)}`)} : null}
              >
                <img
                  src={category.imageURL.replace("cdn.upgrader.club", "cdn.upgraderadmin.dk")}
                  height="300"
                  alt={category.categoryName}
                  style={{ width: "100%", objectFit: "cover" }}
                />
                <div
                  className={classes.categoryName}
                >
                 <Headline3 style={{color: styleGuide.color1}}>
                    {
                      !!category && !!category.translations && !!category.translations.length > 0
                      ? category.translations.find(
                              (translation) => translation.languageKey === langCode
                      ) 
                      ? category.translations.find(
                          (translation) => translation.languageKey === langCode
                      ).categoryName
                          : category.categoryName
                      :
                      category.categoryName
                    }
                  </Headline3> 
                </div>
              </div>
            </Grid>
           );
        })}
      </Grid>
      <div className={classes.seeMoreButton}>
          <ButtonMedium4
            style={{ margin: "auto" }}
            showIcon={false}
            translationKey="_HOME_CATEGORY_SEE_MORE_BUTTON_"
            onClick={ShopID ? () => {
              Activity({activityType: 'click',activityName: 'home',activityArgument: 'Shop',activityArgumentID: ShopID})
              navigate(`shop/${ShopID}`) }: null}
          >
            Se alle produkter
          </ButtonMedium4>
      </div>
    </div>
  );
};

const OthersLookingProductsSection = (props) => {
  const classes = jss();
  const navigate = useNavigate()
  const { brandData, accessibleShops, langCode } = useContext(AppContext);
  const ShopID = accessibleShops && accessibleShops.length > 0 ? accessibleShops[0].ShopID : null  
  const suggestedProductsStyle = {
    position: "relative",
    marginTop: 100,
    marginBottom: 100
  };
  const  productReaction = async (product) =>{
    const ReactionID = product.reaction ?  product.reaction.ReactionID: null
    let reaction = await callAPI("/product/reaction", "POST",{body: {ReactionTargetID:product.ProductID,reactionData:JSON.stringify({ShopID:product.ShopID}),...ReactionID && {ReactionID}}});
    if(props.refreshOthersLookingProducts){
      props.refreshOthersLookingProducts();
    }
  }
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  return (
    <div id="OthersLookingProductsSection" style={suggestedProductsStyle}>
        <HorizontalScrollBlock caption={translate("_HOME_OTHERS_WATCHING_BLOCK_CAPTION_", "Andre kigger på")}>
          {props.othersLookingProducts.map((item,index) => (
            <ProductNormal
              key={index}
              productID={item.ProductID}
              showTag={item.enableDiscount ? true : false}
              tagOptions={{
                  fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${pointsConvertor(brandData,item.productPointComputed - item.discountPointComputed, pointSuffix)}`,
                  showClose: false,
              }}
              tagStyles={{borderRadius: 8}}
              points={(item.enableMulti && item.minCount > 0) ? `${item.discountPointComputed ? item.minCount * item.discountPointComputed : item.minCount * item.productPointComputed}` : `${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
              liked={item.reaction && item.reaction.ReactionID ? true : false }
              className="productlarge item"
              style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
              text2={!!item.translations &&
                !!item.translations.length
                ? item.translations.find(
                    (translation) => translation.languageKey === langCode
                  ) ? item.translations.find(
                    (translation) => translation.languageKey === langCode
                  ).elementTitle
                : item.elementTitle
            : item.elementTitle + (!!item.enableMulti ? ', ' + item.minCount + ' stk' : '')}
              text1={item.manufacturer}
              wishlistCallback={() => productReaction(item)}
              imageSrc={`url(${item.imageSrc})`}
              activityName='home'
            />
          ))}
        </HorizontalScrollBlock>
      <div className={classes.seeMoreButton}>
        <ButtonMedium4
          style={{ margin: "auto" }}
          showIcon={false}
          translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
          onClick={ShopID ? () => {
            Activity({activityType: 'click',activityName: 'home',activityArgument: 'Shop',activityArgumentID: ShopID})
            navigate(`shop/${ShopID}`)} : null}
        >
          Se alle produkter
        </ButtonMedium4>
      </div>
    </div>
  );
};

