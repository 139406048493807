
import { AppContext } from "contexts/AppContext";
import React, { useContext, useState } from "react";
import Headline3 from "components/elements/Headline3";
import ContentNormal from "components/elements/ContentNormal";
import _ from 'lodash'
import { translate } from "utils/Translate";
import { InputLabel, Switch } from "@mui/material";
import { DialogBox } from "modules/profile/ActivationSales";

const ActivationDetails = (props) => {
    const { brandData, langCode, userData } = useContext(AppContext);
    const [activate,setActivate]=useState(userData.enableAutoWalletActivation === 1 ? true : false );
    const [showDialog, setShowDialog] = useState(false)

    const autoActivateWalletActivationMoreInfoTitle = brandData?.features && brandData?.features?.autoActivateWalletActivationMoreInfoTitle &&
        brandData?.features?.autoActivateWalletActivationMoreInfoTitle[langCode] ? brandData?.features?.autoActivateWalletActivationMoreInfoTitle[langCode] : brandData?.features?.autoActivateWalletActivationMoreInfoTitle ? brandData?.features?.autoActivateWalletActivationMoreInfoTitle : ''
    const autoActivateWalletActivationMoreInfoContent = brandData?.features && brandData?.features?.autoActivateWalletActivationMoreInfoContent &&
        brandData?.features?.autoActivateWalletActivationMoreInfoContent[langCode] ? brandData?.features?.autoActivateWalletActivationMoreInfoContent[langCode] : brandData?.features?.autoActivateWalletActivationMoreInfoContent ? brandData?.features?.autoActivateWalletActivationMoreInfoContent : ''
    const activationPointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.activationPointsSectionLabels
        ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.activationPointsSectionLabels : null

    const htmlDecode = (input) => {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    }

    const handleActivation = (e) =>{
        setActivate(e.target.checked)
        setShowDialog(true)
    }

    return (
        <>
            <div style={{ marginTop: 30 }}>
                {autoActivateWalletActivationMoreInfoTitle && <Headline3 style={{ fontSize: 40, wordBreak: 'break-word' }} translationKey="_ACTIVATION_WALLET_MORE_INFO_TITLE_" >{autoActivateWalletActivationMoreInfoTitle}</Headline3>}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                    {autoActivateWalletActivationMoreInfoContent && <div className="activation-wallet-content" style={{ display: 'flex' }}>
                        <ContentNormal>
                            <div
                                style={{ wordBreak: 'break-word' }}
                                dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_ACTIVATION_WALLET_MORE_INFO_CONTENT_', autoActivateWalletActivationMoreInfoContent)) }}
                            >
                            </div>
                        </ContentNormal>
                    </div>}
                </div>
                {showDialog && <DialogBox
                    activate={activate}
                    setActivate={setActivate}
                    setCheck={setShowDialog}
                    Check={showDialog} />}
                {!brandData?.features?.disableAutoActivateFeature && 
                <div style={{ display: 'flex',alignItems:'center', marginTop: '16px' }}>
                    <InputLabel htmlFor='activate_checkbox'>
                    {activationPointsSectionLabels?.automaticActivationRemarkText ? activationPointsSectionLabels.automaticActivationRemarkText : translate('_ACTIVATE_POINTS_AUTOMATIC_CHECKBOX_', 'Aktivér altid point automatisk:')}</InputLabel>           
                    <Switch
                    id = 'activate_checkbox'
                    checked = {activate}
                    onClick={(e) => handleActivation(e)}
                    />
                </div>}
            </div>
        </>
    );
};

export default ActivationDetails;

