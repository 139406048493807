import { ExpandLess, ExpandMore } from "@material-ui/icons"
import ContentSmall from "components/elements/ContentSmall"
import Headline6 from "components/elements/Headline6"
import { useState } from "react"

export default function FreightInfo({freightIntervals, width, showAdditionalInfo=false, showDeliveryEstimate=false, showLoadMore=false, showLoadFreight=false, onExpandChange}){
    const [expand, setExpand] = useState(false)

    const toggleExpand = () => {
        setExpand(prevExpand => {
            const newExpand = !prevExpand;
            onExpandChange(newExpand);
            return newExpand;
        });
    };

    return(
        <div style={{ display: 'flex', flexDirection: 'column', gap: 3, maxWidth: width }}>
            {showLoadFreight && <div style={{ display: 'flex', flexDirection: 'row', maxWidth: 120, padding: 2, gap: 8, borderRadius: 8, border: '0.1px solid rgb(191, 191, 191)', marginBottom: 16 }} 
                onClick={() => toggleExpand()}>
                {expand ? <ExpandLess strokeWidth='0.5' fill='#222222'/> : <ExpandMore strokeWidth="0.5px" fill='#222222'/>}
                <ContentSmall style={{marginTop: 2}} translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_LOAD_FREIGHT_" fallbackText={`Leveringsinfo`} />
            </div>}
            {(expand || showLoadMore) && <Headline6 translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_LABEL_" fallbackText="Leveringspriser" />}
            {showLoadMore && <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }} onClick={() => setExpand(!expand)}>
                {expand ? <ExpandLess strokeWidth='0.5' fill='#222222'/> : <ExpandMore strokeWidth="0.5px" fill='#222222'/>}
                {!expand ? <ContentSmall style={{marginTop: 2}} translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_LOAD_MORE_" fallbackText={`Læs mere`} /> :  <ContentSmall style={{marginTop: 2}} translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_LOAD_LESS_" fallbackText={`Læs mindre`} /> }
            </div>}
            {expand && <div style={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 8 }}>
                <ContentSmall translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_DESCRIPTION_" fallbackText={`Prisen på levering afhænger af vægten på din samlede ordre.`} />
                <table style={{maxWidth: 200, marginTop: 8}}>
                    <thead>
                        <th><ContentSmall style={{fontWeight: 600}} translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_HEADER_1_" fallbackText={'Vægt'}></ContentSmall></th>
                        <th style={{textAlign: 'right'}}><ContentSmall style={{fontWeight: 600}} translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_HEADER_2_" fallbackText={'Pris på levering'}></ContentSmall></th>
                    </thead>
                    <tbody>
                        {freightIntervals.map(interval => {
                            return <tr key={interval.BrandFreightIntervalID}>
                                <td><ContentSmall>{interval.title}</ContentSmall></td>
                                <td style={{textAlign: 'right'}}><ContentSmall>{interval.freightPoints} point</ContentSmall></td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {showAdditionalInfo && <ContentSmall style={{marginTop: 8}} translationKey="_BASKET_OVERVIEW_FREIGHT_INTERVALS_PRICE_TEXT_" fallbackText={`Du finder din præcise leveringspris i 'Din kurv' under 'Dit ordreoverblik'`} />}
                {showDeliveryEstimate && <div style={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 12 }}>
                    <Headline6 translationKey="_BASKET_OVERVIEW_DELIVERY_TIME_CAPTION" fallbackText="Leveringstid" />
                    <ContentSmall translationKey="_BASKET_OVERVIEW_DELIVERY_TIME_TEXT_" fallbackText={`Lagervarer afsendes inden for 3-4 hverdage.`} />
                </div>}
            </div>}
        </div>
    )
}