
const Tag = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg style={props.style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.528 7.664L8.328 0.464C8.04 0.176 7.64 0 7.2 0H1.6C0.72 0 0 0.72 0 1.6V7.2C0 7.64 0.176 8.04 0.472 8.336L7.672 15.536C7.96 15.824 8.36 16 8.8 16C9.24 16 9.64 15.824 9.928 15.528L15.528 9.928C15.824 9.64 16 9.24 16 8.8C16 8.36 15.816 7.952 15.528 7.664ZM2.8 4C2.136 4 1.6 3.464 1.6 2.8C1.6 2.136 2.136 1.6 2.8 1.6C3.464 1.6 4 2.136 4 2.8C4 3.464 3.464 4 2.8 4Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    )
}
export default Tag

