import { useContext } from "react";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import ContentLarge from "components/elements/ContentLarge";
import Headline2 from "components/elements/Headline2";
import Headline4 from "components/elements/Headline2";
import { AppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { newLineToBreakTag } from "utils/general";
import "./HOFBlock.css";
import Image from "components/elements/Image";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import Headline5 from "components/elements/Headline5";

const HOFBlock = (props) => {
  const navigate = useNavigate();
  const { styleGuide, brandData, langCode } = useContext(AppContext);
  const { hof } = props
  const translationObj = hof && hof.translations?.length ? hof.translations.find(t => t.languageKey === langCode) : null
  const goToCollection = () => {
    if (!hof || !hof.HalloffameID)
      return;

    return navigate(`halloffame/${hof.HalloffameID}`);
  };

  return !brandData || !hof ? null : (
    <div className={props.className}>
      <Image
        className="hof"
        src={`url(${hof.bannerImageURL || "https://cdn.upgrader.club/f9e58318-dfe9-470d-8286-841881819c04-default.jpeg"})`}
        style={{
          width: "100%",
          objectFit: "cover",
          position: "relative"
        }}
      >
        <div style={{position: "absolute", zIndex: 10, bottom: 100, left: 50}}>
          <Headline4
                translationKey="_HOF_BANNER_TOP3_" 
                fallbackText="Top 3"
                style={{color: styleGuide.color1,}} />
          <div style={{display: "flex", flexDirection: "column", marginTop: 12}}>
            {
              hof.elements.slice(0, 3).map((item, index) => {
                return <Headline5
                translationKey="_HOF_BANNER_TOP3_USER_" 
                style={{color: styleGuide.color1,}}
                fallbackText={`${index + 1}. ${item.elementTitle}`} />
              })
            }
          </div>
                
        </div>
        <div className="hofText">
          {!!hof && (
            <div>
              <Headline2
                translationKey="_HOF_BANNER_TITLE_"
                style={{
                  color: hof.bannerTextColor === "black"
                    ? styleGuide.color18
                    : styleGuide.color1,
                  fontSize: styleGuide.headline1FontSize,
                  fontFamily: styleGuide.fontFamily
                }}
                fallbackText={ translationObj?.bannerTitle || hof.bannerTitle || hof.moduleName }
              />
            </div>
          )}
          {!!hof && (
            <ContentLarge
              translationKey="_HOF_BANNER_CONTENT_"
              style={{
                color: hof.bannerTextColor === "black"
                    ? styleGuide.color18
                    : styleGuide.color1,
                fontSize: styleGuide.headline5FontSize,
                fontFamily: styleGuide.fontFamily
              }}
              fallbackText={newLineToBreakTag(translationObj?.bannerDescription || hof.bannerDescription || hof.moduleDescription)}
            />
          )}
          {!!hof?.HalloffameID && (
            <div>
              <div className="hofButtonM">
                <ButtonMedium1
                  onMedium1
                  showIcon={false}
                  style={{ maxWidth: "max-content" }}
                  translationKey="__TAX_CAMPAIGN_HIGHLIGHT_BLOCK_BUTTON_CAPTION_"
                  onClick={goToCollection}
                >
                  {hof &&
                  !!hof.translations &&
                  !!hof.translations.length
                  ? !!hof.translations.find(
                      (translation) => translation.languageKey === langCode
                    ) ? hof.translations.find(
                      (translation) => translation.languageKey === langCode
                    ).bannerButtonText
                    : hof.bannerButtonText 
                    : 'Læs mere'}
                </ButtonMedium1>
              </div>
              <div className="hofButtonL">
                <ButtonLarge1
                  style={{ maxWidth: "max-content" }}
                  translationKey="__TAX_CAMPAIGN_HIGHLIGHT_BLOCK_BUTTON_CAPTION_"
                  onClick={goToCollection}
                >
                  {hof &&
                  !!hof.translations &&
                  !!hof.translations.length
                  ? !!hof.translations.find(
                      (translation) => translation.languageKey === langCode
                    ) ? hof.translations.find(
                      (translation) => translation.languageKey === langCode
                    ).bannerButtonText
                    : hof.bannerButtonText 
                    : 'Læs mere'}
                </ButtonLarge1>
              </div>
            </div>
          )}
        </div>
      </Image>
    </div>
  );
};

export default HOFBlock;
