import React,{ useContext, useEffect, useState } from "react"
import PropTypes from "prop-types";
import { callAPI } from "utils/API";
import ButtonLarge4 from "components/elements/ButtonLarge4";
import { AppContext } from "contexts/AppContext";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { createUseStyles } from 'react-jss'
import './SendPointPopUp.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Alert,
  FormControl,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { translate } from "utils/Translate";

const jss = createUseStyles({
  GodkendButton: {
    backgroundColor: (styleGuide) => styleGuide.color16,
    color:'#ffffff'
  }
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function SendPointPopUp({ teamData }) {
  const { styleGuide, brandData } = useContext(AppContext);
  const classes = jss(styleGuide)
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [antalpoint, setAntalPoint] = useState('')
  const [walletnote, setWalletNote] = useState('')
  const [data, setData] = useState({})
  const [budgetPointsBalance, setBudgetPointsBalance] = useState(0)
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false)
  const [walletNoteError, setWalletNoteError] = useState(false)
  const [infoMessage,setInfoMessage]=useState(true)
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsAwaitingResponse(false)
    setEmail('')
    setAntalPoint('')
    setWalletNote('')
    setData({})
    setOpen(false);
  };

  const getBudgetPointsBalance = async() => {
    let budgetpoints = await callAPI('/wallets/getBudgetPointsBalance', "GET");
    let result = budgetpoints.budgetPointsBalance
    setBudgetPointsBalance(result)
  }

  const handleEmailOrPhoneChange = e => {
    setIsAwaitingResponse(false)
    if (errors) {
      setErrors(false)
    }
    if (success) {
      setSuccess(false)
    }
  
    let useremail = e.target.value.userEmail
    let val = e.target.value
    setEmail(useremail)
    setData({
      ...data,
      selectedUserForSendPoints: val,
      userEmail: useremail,
      sendPointsResponse: null
})
  }
  
  const blockInvalidChar = e => 
    ["+","-","."].includes(e.key) && e.preventDefault();

  const handlePointChange = e => {
    setIsAwaitingResponse(false)
    let valueAsNumber = e.target.value;
    if (valueAsNumber == "") {
      valueAsNumber=""
    }else if (valueAsNumber <=0 || valueAsNumber > budgetPointsBalance) {
      valueAsNumber=antalpoint
    }
    setAntalPoint(valueAsNumber)
    if (!valueAsNumber) return
    setData({
      ...data,
      amount: valueAsNumber,
      sendPointsResponse: null
    })
  }
  
  const handleWalletNote = e => {
    setIsAwaitingResponse(false)
    let wallet_note = e.target.value
    setWalletNote(wallet_note)
    setData({
      ...data,
      walletNote: wallet_note,
      sendPointsResponse: null
    })
    if (!wallet_note) {
      setWalletNoteError(true)
    }
    else {
      setWalletNoteError(false)
    }
  }

  const handleConfirmClick = async (e) => {
    setIsAwaitingResponse(true)
    if (!walletnote) {
      setWalletNoteError(true)
      return
    }
    const response = await callAPI(`/wallets/sendBudgetPoints`, "POST", {
      body: { email, antalpoint, walletnote },
    });
    if(response === 'SUCCESS'){
      setSuccess(true)
      setData({
        ...data,
        sendPointsResponse:response
      })
    }
    else if(response && response.error && response.error.message) {
      setErrorMessage(response.error.message)
      setErrors(true)
      setData({
        ...data,
        sendPointsResponse:response.error.message
      })
    }
    else {
      setErrorMessage(response.error)
      setErrors(true)
      setData({
        ...data,
        sendPointsResponse:response.error
      })
    }
    setIsAwaitingResponse(false)
  }

  const shouldDisableButton = () => {
    const isValidForm = () => {
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const phoneReg = /^[\d +]{8,13}$/
      const acceptableAmount = antalpoint !== '' && parseInt(antalpoint) > 0 && antalpoint <= budgetPointsBalance
      return acceptableAmount && (phoneReg.test(String(email).toLowerCase()) || emailReg.test(String(email).toLowerCase()))
    }
    return isAwaitingResponse || data.sendPointsResponse !== null || !isValidForm()
  }

  const resetForm = () => {
    setAntalPoint('')
    setWalletNote('')
  }
  
  useEffect(() => {
    getBudgetPointsBalance()
  },[])

  return (
    <div>
      <ButtonLarge4
          style={{ float: 'right',marginTop:8}}
          showIcon={false}
          translationKey="_PROFILE_PAGE_SEND_POINT_BUTTON_TEXT_"
          fallbackText="Send/lån point"
          onClick={handleClickOpen}
        />
      <BootstrapDialog
        style={{height:'680px',marginTop:'-45px'}}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{scale:'95%'}}>
        <BootstrapDialogTitle
          style={{width:'800px'}}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Send Point
        </BootstrapDialogTitle>
          <DialogContent dividers>
          {infoMessage && <Alert severity="" sx={{ bgcolor: '#d1d1d1', color: '#6f7284' }} onClose={() => setInfoMessage(false)}>
            {translate('_SEND_POINTS_FORM_CAPTION_', 'Udfyld formularen nedenfor for at sende point')}
            </Alert>}
          {errors && <Alert severity="error" onClose={() => {setErrors(false); resetForm()}}>{errorMessage?translate(errorMessage,'Point kan ikke overføres til denne email'):translate('_ERROR_', 'Fejl')}</Alert>}
          {success && <Alert severity="success" onClose={() => {setSuccess(false);resetForm()}}>{translate('_SEND_POINTS_SUCCESS_', 'Overførslen er gennemført')}{' '}</Alert>}
          <Typography gutterBottom style={{ marginBottom: '15px',marginTop:'10px' }}>{translate('_SEND_POINTS_BALANCE_TEXT_', 'Nuværende saldo på budget')}: {budgetPointsBalance}</Typography>
          <Typography style={{fontWeight:'bold'}}>{translate('_SEND_POINTS_USER_FIELD_', 'Modtagers navn eller e-mail')}:</Typography>
          <FormControl variant="filled" sx={{ m: 1, minWidth: "100%" }}>
              <Select
              style={{marginLeft:'-8px'}}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
                onClick={() => {
                  if (data?.selectedUserForSendPoints && data?.userEmail) {
                    setIsAwaitingResponse(false)
                  setData({
                    ...data,
                    selectedUserForSendPoints: data.selectedUserForSendPoints,
                    userEmail: data.userEmail,
                    sendPointsResponse: null
              })
                  }
                  
                }}
              onChange={handleEmailOrPhoneChange}
              defaultValue=''
            >
                {teamData?.length && teamData.map((e) => {
                  return (<MenuItem name={e.userName} value={e}>{e.userName}</MenuItem>)
                })}
            </Select>
          </FormControl>

          <Typography gutterBottom>{translate('_SEND_POINTS_AMOUNT_', 'Antal point')}:</Typography>
            <TextField
            type='number'
            name='Antal point'
            value={antalpoint}
            id="outlined-basic"
            label="f.eks. 1523"
              variant="outlined"
              inputProps={{ min: 1, max: budgetPointsBalance }}
            style={{ width: "100%", height: "25px", marginBottom: "50px" }}
            onKeyDown={blockInvalidChar}
            onChange={handlePointChange}
          />
          <Typography gutterBottom>Note:</Typography>
          <TextField
            id="outlined-basic"
            label="Note"
            value={walletnote}
            variant="outlined"
            style={{ width: "100%", height: "25px", marginBottom: "30px" }}
            onChange={handleWalletNote}
            />
            {walletNoteError && <Alert severity="error">{translate('_SEND_POINTS_WALLETNOTE_ERROR_', 'Please Enter Wallet Note')}</Alert>}
        </DialogContent>
        <DialogActions>
        <button
              className={`save_sendpoint ${classes.GodkendButton}`}
              style={{ padding: '5px 10px 5px 10px', height: '35px', border: 'none', borderRadius: '5px' }}
              onClick={(e) => handleConfirmClick()}
              disabled={shouldDisableButton()}
            >
              {isAwaitingResponse ? `${translate('_SEND_POINTS_IN_PROGRESS_','Sender')}...` : translate('_ACCEPT_', 'Godkend')}
            </button>
            <button
              style={{ padding: '5px 10px 5px 10px', backgroundColor: styleGuide.color16, height: '35px', border: 'none', borderRadius: '5px', color: '#ffffff' }}
              onClick={()=>handleClose()}
            >
              {translate('_CANCEL_', 'Afvis')}
            </button>
        </DialogActions>
        </div>
      </BootstrapDialog>
    </div>
  );
}
