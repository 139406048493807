import Translate from "utils/Translate";
import { upgColors, fontFamily } from "styles/styles";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const Headline5 = (props) => {
  const { styleGuide } = useContext(AppContext)

    const headline5Style = {
      fontFamily: styleGuide.fontFamily,
      fontWeight: styleGuide.headline5FontWeight,
      fontSize: styleGuide.headline5FontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.headline5LineHeight,
      color: styleGuide.color7
    }

  
    return (
      <span onClick={props.onClick} style={{...headline5Style, ...props.style}} className={props.className}>
        <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>
      </span>
    )
}

export default Headline5;