import Translate from "utils/Translate";
import React, { useContext } from 'react'
import ArrowRightAlt from '../icons/ArrowRightAlt';
import { AppContext } from 'contexts/AppContext';
import {createUseStyles} from 'react-jss'

const jss = createUseStyles({
    buttonMedium4: {
        backgroundColor: (styleGuide) => styleGuide.color1,
        color: (styleGuide) => styleGuide.color7,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonMediumHeight,
        fontSize: (styleGuide) => styleGuide.buttonMediumFontSize,
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        lineHeight: (styleGuide) => styleGuide.buttonMediumLineHeight,
        fontWeight: '600',
        padding: '9px 20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: (styleGuide) => `1px solid ${styleGuide.color4}`,
        '& path': {
            fill: (styleGuide) => styleGuide.color7,
        },
        '&:hover':{
            border: (styleGuide) => `1px solid ${styleGuide.color16}`,
            color: (styleGuide) => styleGuide.color16,
            '& path': {
                fill: (styleGuide) => styleGuide.color16,
            }
        },
        
        '&:disabled':{
            backgroundColor: (styleGuide) => styleGuide.color1,
            opacity: 0.7,
            cursor: 'not-allowed'
        }
    }
})

const ButtonMedium4 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const showIcon = (typeof props.showIcon === 'boolean') ? props.showIcon : true

    return (
        <button
            title={props.title || ""}
            onClick={props.onClick}
            style={{...props.style}}
            disabled={props.disabled}
            className={classes.buttonMedium4}
        >
            {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
            {showIcon 
                ?   props.icon 
                        ? props.icon
                        : <ArrowRightAlt style={{ marginLeft:'34px' }} />
                :   null
            }
        </button>
    )
}

export default ButtonMedium4;