import React, { useContext } from 'react'
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, Checkbox, ListItemText } from '@mui/material';
import './css/InputStyles.css'
import { AppContext } from 'contexts/AppContext';
import {createUseStyles} from 'react-jss'
import { useMediaQuery } from '@mui/material';
import CaretDown from 'components/icons/CaretDown';
import RoundIconButton1 from './RoundIconButton1';

const jss = createUseStyles({
    labelStyle : {
        color: (styleGuide) => styleGuide.color6,
        "&.Mui-focused":{
            color: (styleGuide) => styleGuide.color6
        }
    }
})


const CustomSelectHeader = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const isMobile = useMediaQuery('(max-width:900px)'); 
    const defaultUnderlineColor = props.value ? styleGuide.color7 : styleGuide.color4
    let customWidth = '200px'

    if (isMobile) {
        customWidth = '100px'
    }
    const selectStyle = {
        backgroundColor: 'transparent',
        color: 'white',
        marginLeft: '15px',
        marginRight: '15px',
        width: customWidth || '200px', // Fixed width for the Select input
        "&:hover:before": {
            borderBottom : `2pt solid white !important`,
        },
        "&:hover:after": {
            borderBottom : `2pt solid white !important`,
        },
        "&:before": {
            borderBottom : `2pt solid white !important`,
        },
        "&:after": {
            borderBottom : `2pt solid white !important`,
        },
        "& :focus":{
            backgroundColor: 'transparent'
        },
        ".MuiSelect-icon": {
        color: "white"
        }
    }

    const menuItemStyle = {
        ".MuiPaper-root": {
            borderRadius : `${styleGuide.selectDropdownBorderRadius}`,
        },
        "ul": {
            padding: '0 0 0 0'
        },
        "li:hover": {
            backgroundColor: `${styleGuide.color16} !important`,
            color: `white`
        },
        "svg": {
            color: `white`
        }
    }

    const labelStyle = {
        zIndex: props.disabled ? 1 : `inherit`,
        marginLeft: '15px',
        color: styleGuide.color6,
        "&.Mui-focused":{
            color: "white"
        }
    }

    const helpTextStyle = {
        marginLeft: '15px',
        backgroundColor: 'white'
    }
    const helpText = props.helpObj && props.helpObj.helpText ? props.helpObj.helpText : ''
    const errorText = props.helpObj && props.helpObj.errorText ? props.helpObj.errorText : ''
    
    return (
        <div style={props.wrapperStyle}>
            
        <FormControl fullWidth variant="standard" error={props.error} hiddenLabel={false}>
            <InputLabel className={classes.labelStyle} style={{...labelStyle,  ...props.custlabelStyle}} id="select-label"></InputLabel>
            <Select 
                variant='standard'
                labelId="select-label"
                style={props.style}
                value={props.value}
                className={props.className}
                onChange={props.onChange}
                sx={selectStyle}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            ...menuItemStyle,
                            minWidth: 'auto',
                            width: 'auto' 
                        }
                    },
                    variant: 'menu',
                    getContentAnchorEl: null, 
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }
                }}
            >   
                {props.emptyValue 
                    ? <MenuItem style={{padding: 10}} value={null}>{props.emptyValue.text || ''} </MenuItem> 
                    : null
                }

                {(props.options && props.options.length)
                    ? props.options.map(elem => {
                            return (
                                <MenuItem key={elem.value} value={elem.value} style={props.customMenuStyle}>
                                    { props.multiple 
                                        ?   <>
                                                <Checkbox checked={!!props.value && props.value.indexOf(elem.value) > -1} />
                                                <ListItemText primary={elem.label} />
                                            </>
                                        : elem.label}
                                </MenuItem>
                            )
                        })
                    : null 
                }
            </Select>
            {!props.disabled && <FormHelperText id="helper-text" style={helpTextStyle}>{ props.error ? errorText : helpText}</FormHelperText>}
        </FormControl>
            
        </div>
    )
}

export default CustomSelectHeader;
