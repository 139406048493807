import Headline6 from "components/elements/Headline6";
import { AppContext } from "contexts/AppContext";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { callAPI } from "utils/API";
import ContentSmall from "components/elements/ContentSmall";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import CustomInput from "components/elements/CustomInput";
import { translate } from "utils/Translate";
import CaptionText from "components/elements/CaptionText";
import CustomCheckbox from "components/elements/CustomCheckbox";
import Close from "components/icons/Close";
import { setupUserData } from "utils/setup";

export const SendPointsModal = ({showSendPointsModal, setShowSendPointsModal, canLoanPoints, sendPointsAndLoanLabels, wallets, fetchWalletActivation, setActivateComplete}) => {
    const { styleGuide } = useContext(AppContext);
    const [userIdentifier, setUserIdentifier] = useState(null)
    const [loan, setLoan] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pointsBalance, setPointsBalance] = useState(0)
    const contextState = useContext(AppContext);
    const amount = wallets && wallets.length ? wallets.reduce((sum, a) => sum + a.walletAmountPoints, 0) : 0

    const sendPoints = async() =>{
        setError(null)
        setSuccess(null)
        setLoading(true)
        const response = await callAPI(`walletactivation/sendPoints`, "POST", {
            body: { userIdentifier, amount, loan, WalletActivationIDs: wallets.map(w => w.WalletActivationID) },
        });
        setLoading(false)
        if(response?.status === 'SUCCESS'){
            setSuccess(response)
            setActivateComplete({pointBox: true, pointOverview: true, activationOverview: true, activationGraph: true})
            setupUserData(contextState, setLoading)
            fetchWalletActivation()
        }else{
            setError(response.message || response)
            if(response.pointsBalance){
                setPointsBalance(response.pointsBalance)
            }
        }
    }

    return (
    <Modal
      show={showSendPointsModal}
      onHide={() => setShowSendPointsModal(false)}
      style={{ margin: "auto" }}
      centered
    >
      <Modal.Header
        className="pb-0 border-0"
        closeButton
        style={{ fontSize: 9 }}
      >
        <Headline6
          configuredLabel={sendPointsAndLoanLabels?.popupTitleText}
          translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_CAPTION_"
          fallbackText="Send eller udlån point"
        />
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            {amount > 0 ? <ContentSmall
                configuredLabel={sendPointsAndLoanLabels?.popupContentText}
                translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_TEXT_"
                fallbackText="Brug formularen til at sende eller låne point til en kollega. Marker &quot;Send point som lån&quot; for at din kollega skal betale pointene tilbage."
            /> : 
            <ContentSmall style={{fontWeight: 600, fontStyle: 'italic'}}
                configuredLabel={sendPointsAndLoanLabels?.popupNoAmountText}
                translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_NO_AMOUNT_TEXT_"
                fallbackText="Please select one or more walletactivation to send."/>
            }
            <CustomInput
                disabled={!amount}
                wrapperStyle={{ marginBottom: 10 }}
                label={sendPointsAndLoanLabels?.popupFieldOneText ? sendPointsAndLoanLabels.popupFieldOneText : translate("_PROFILE_PAGE_SEND_POINT_POPUP_EMAIL_FIELD_", "Modtagers navn eller e-mail")}
                value={userIdentifier}
                onChange={(e) => setUserIdentifier(e.target.value)}
            />
            <CustomInput
                wrapperStyle={{ marginBottom: 10 }}
                label={sendPointsAndLoanLabels?.popupFieldTwoText ? sendPointsAndLoanLabels.popupFieldTwoText : translate("_PROFILE_PAGE_SEND_POINT_POPUP_AMOUNT_FIELD_", "Antal point")}
                value={amount}
                disabled
                // onChange={(e) => setAmount(e.target.value)}
            />
            {canLoanPoints && 
            <>
              <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginLeft: 16 }}>
                  <CustomCheckbox checked={loan} onChange={() => setLoan(!loan)} style={{ marginLeft: 16, marginTop: 0 }}/>
                  <ContentSmall configuredLabel={sendPointsAndLoanLabels?.popupCheckboxText} translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_LOAN_FIELD_" fallbackText="Send point som lån"
                      style={{ color: styleGuide.color16, paddingTop: 12 }}
                  />
              </div>
              <ContentSmall configuredLabel={sendPointsAndLoanLabels?.popupLoanHelperText}
                  translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_LOAN_HELPTEXT_" fallbackText="Sender du point som et lån, vil pointene blive ført tilbage til din konto så snart der er dækning på modtagerens konto. Såfremt at du ikke vælger Send som lån, anses dette som en gave."
                  style={{ color: styleGuide.color6, marginLeft: 8, marginTop: -8 }}
              /></>}            
        </div>
        {!!success || !!error ? <div
          style={{
            marginTop: 16,
            marginLeft: 40,
            marginRight:40,
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 24,
            paddingRight: 24,
            border: '1px solid',
            borderColor: success ? styleGuide.color23 : styleGuide.color20,
            backgroundColor: success ? styleGuide.color24 : styleGuide.color21,                
          }}
        >
          {!!success && (
            <><CaptionText
              style={{ color: styleGuide.color25}}
              translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_SUCCESS_TEXT_"
              configuredLabel={`Du har sendt ${success.amount} point til ${userIdentifier}`}
              fallbackText={'Overførslen er gennemført'}
            />
            <Close fill={styleGuide.color7} style={{ width: 8, height: 8, cursor: 'pointer', marginRight: -20, marginTop: -4 }} onClick={() => setSuccess(null)} />
            </>
          )}
          {!!error && (
            <>
            {error === 'RECEIVER_INVALID' ? <CaptionText
              style={{ color: styleGuide.color22 }}
              translationKey="RECEIVER_INVALID"
              fallbackText="Ukendt bruger"
            /> : error === 'INSUFFICIENT_FUNDS' ? 
            <CaptionText
              style={{ color: styleGuide.color22 }}
              translationKey="INSUFFICIENT_FUNDS"
              configuredLabel={`Du har ikke point nok til at overføre. Du har ${pointsBalance} point tilgængelig på din konto.`}
              fallbackText={`Du har ikke point nok til at overføre. Du har ${pointsBalance} point tilgængelig på din konto.`}
            /> : error === "SEND_POINTS_NOT_UNIQUE_USER" ?
            <CaptionText
              style={{ color: styleGuide.color22 }}
              translationKey="SEND_POINTS_NOT_UNIQUE_USER"
              fallbackText="Point kan ikke overføres til denne email"
            /> :
            <CaptionText
              style={{ color: styleGuide.color22 }}
              translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_ERROR_TEXT_"
              fallbackText="Error"
            />}
            
            <Close fill={styleGuide.color7} style={{ width: 8, height: 8, cursor: 'pointer', marginRight: -20, marginTop: -4 }} onClick={() => setError(null)} />
            </>
          )}

        </div> : null}
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <ButtonMedium1
        configuredLabel={sendPointsAndLoanLabels?.popupAcceptButtonText}
          disabled={loading || !amount}
          showIcon={false}
          translationKey="_BUTTON_CAPTION_SAVE_"
          fallbackText="Godkend"
          onClick={() => sendPoints()}
        />
        <ButtonMedium4
          configuredLabel={sendPointsAndLoanLabels?.popupCloseButtonText}
          showIcon={false}
          translationKey="_BUTTON_CAPTION_CLOSE_"
          fallbackText="Luk"
          onClick={() => setShowSendPointsModal(false)}
        />
      </Modal.Footer>
    </Modal>
)};