import { AppContext } from "contexts/AppContext"
import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";

const CheckAuth = ({ children }) => {
    const { userData, brandData, isSuperAdmin } = useContext(AppContext);
    const location = useLocation();
    const isDownForMaintenance = brandData && brandData.features && brandData.features.isDownForMaintenance;
    const shutDownAllBrands = brandData && brandData.features && brandData.features.shutDownAllBrands;
    if (!userData || !userData.UserID) {
        // Redirect user to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send user
        // along to that page after they login, which is a nicer user experience
        // than dropping user off on the home page.
        if (brandData.BrandID === 87){
            return <Navigate to="/sso" state={{ fromPage: location }}/>
        } 
        return <Navigate to="/login" state={{ fromPage: location }} />;
    }
    if ((shutDownAllBrands || isDownForMaintenance) && !isSuperAdmin) {
        return <Navigate to="/maintenance" state={{ fromPage: location }} />; 
    }
    
    return children;
}

export default CheckAuth