import React, { useContext, useRef, useState, useEffect } from "react";
import '../TransactionTable/TransactionTable.css'
import { translate } from "utils/Translate";
import CustomCheckbox from "components/elements/CustomCheckbox";
import moment from "moment";
import Headline4 from 'components/elements/Headline4';
import { AppContext } from "contexts/AppContext";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import ContentLarge from 'components/elements/ContentLarge';
// import { IconButton, Popover } from "@mui/material";
// import { InfoOutlined } from "@material-ui/icons";
// import ContentSmall from "components/elements/ContentSmall";
import { SendPointsModal } from "../SendPointsModal/SendPointsModal";
import ButtonMedium2 from "components/elements/ButtonMedium2";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from "@mui/material";

const Sales = ({ setActivateComplete, sales, setSales, refreshData, setShowModal }) => {
  const {langCode, brandData, styleGuide} = useContext(AppContext)
  
  // const [anchorEl, setAnchorEl] = useState(null);
  const [showSendPointsModal, setShowSendPointsModal] = useState(false);

  const activationPointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.activationPointsSectionLabels
     ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.activationPointsSectionLabels : null
  const sendPointsAndLoanLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.sendPointsAndLoanLabels
     ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.sendPointsAndLoanLabels : null

  const getPointsBalance = () => {
    return sales.filter(s => s.status === 'WAITING' || s.status === 'COMPENSATION').reduce((sum,s) => sum + s.walletAmountPoints, 0)
  }


  const NoSalesLine = () => (
    <div
      style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: 40,
      }}
    >
        <Headline4
          configuredLabel={activationPointsSectionLabels?.noPointsTitleText}
          translationKey="_PROFILE_PAGE_NO_SALES_CAPTION_"
          fallbackText="Ingen point endnu"
        />
        <ContentLarge
          configuredLabel={activationPointsSectionLabels?.noPointsContentText}
          translationKey="_PROFILE_PAGE_NO_SALES_TEXT_"
          fallbackText="Når der sker en transaktion, vil du se det her. "
        />
    </div>
  );

  
  // const handlePopoverClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };
  // const openPopover = Boolean(anchorEl);
  // const popoverId = openPopover ? 'simple-popover' : undefined;
  

  return (
    <>
      {!sales ? null : !!sales && !sales.length ? 
        <NoSalesLine />
        : 
        <div className="transaction-table">
          <div className="table-head">
            <div className="th tcol-4">
              <div style={{flexDirection: "column"}}>
                {showSendPointsModal && <SendPointsModal setShowSendPointsModal={setShowSendPointsModal} wallets={sales.filter(s => !!s.checked && s.walletAmountPoints > 0)} 
              showSendPointsModal={showSendPointsModal} sendPointsAndLoanLabels={sendPointsAndLoanLabels} fetchWalletActivation={refreshData} setActivateComplete={setActivateComplete}/>}
                <div style={{display: "flex", flexDirection: "row"}}>
                  <ButtonMedium4 showIcon={false} 
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowModal(true)
                    }}
                    disabled={sales.filter(s => s.checked === true).length ? false: true}
                    style={{marginRight: 8, backgroundColor: sales.filter(s => s.checked === true).length ? styleGuide.color16: styleGuide.color1, color: sales.filter(s => s.checked === true).length ? styleGuide.color1: styleGuide.color18}}
                    translationKey="_PROFILE_MULTIPLE_ACTIVATE_BUTTON_"
                    fallbackText="Aktivér"
                  ></ButtonMedium4>
                  {!!brandData?.features?.sendPoints?.enableSendPointsWalletActivation && 
                  <ButtonMedium2
                    style={{backgroundColor: styleGuide.color26}}
                    disabled={sales.filter(s => s.checked === true).length ? false: true}
                    configuredLabel={sendPointsAndLoanLabels?.sendPointsButtonText}
                    showIcon={false}
                    translationKey="_PROFILE_PAGE_SEND_POINT_BUTTON_TEXT_"
                    fallbackText="Send point"
                    onClick={() => setShowSendPointsModal(true)}
                  />
                  // <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginTop: -12}}>
                  //   <IconButton onClick={handlePopoverClick}>
                  //     <InfoOutlined style={{marginRight: -24, marginBottom: -16}}/>
                  //   </IconButton>
                  //   <ButtonMedium2
                  //     style={{backgroundColor: styleGuide.color26}}
                  //     disabled={sales.filter(s => s.checked === true).length ? false: true}
                  //     configuredLabel={sendPointsAndLoanLabels?.sendPointsButtonText}
                  //     showIcon={false}
                  //     translationKey="_PROFILE_PAGE_SEND_POINT_BUTTON_TEXT_"
                  //     fallbackText="Send point"
                  //     onClick={() => setShowSendPointsModal(true)}
                  //   />
                  //   <Popover
                  //     id={popoverId}
                  //     open={openPopover}
                  //     anchorEl={anchorEl}
                  //     onClose={handlePopoverClose}
                  //     anchorOrigin={{
                  //       vertical: 'top',
                  //       horizontal: 'right',
                  //     }}
                  //   >
                  //     <ContentSmall configuredLabel={sendPointsAndLoanLabels?.sendPointsInfoText} translationKey='_PROFILE_PAGE_SEND_POINT_INFO_TEXT_'>Wallet activation send point info</ContentSmall>
                  //   </Popover>
                  // </div>
                  }
                </div>
              </div>
            </div>
            <div className="th tcol-4">{activationPointsSectionLabels?.columnOneLabel ? activationPointsSectionLabels.columnOneLabel : 'Note'}</div>
            <div className="th tcol-2">{activationPointsSectionLabels?.columnTwoLabel ? activationPointsSectionLabels.columnTwoLabel : 'Point'}</div>
            <div className="th tcol-4">{activationPointsSectionLabels?.columnFourLabel ? activationPointsSectionLabels.columnFourLabel : translate('_DATE_', 'Dato')}</div>
            {!brandData?.features?.disableActivateWalletFeature && <div className="th tcol-2">{activationPointsSectionLabels?.columnFiveLabel ? activationPointsSectionLabels.columnFiveLabel : 'Status'}</div>}
          </div>
          <div className="table-body">
            {sales.map((sale, idx) => <Sale sale={sale} sales={sales} setSales={setSales} setActivateComplete={setActivateComplete} idx={idx} key={idx} getPointsBalance={getPointsBalance} refreshData={refreshData}/>)}
          </div>
        </div>}
    </>
    );
};


const Sale = ({sale, idx, sales, setSales}) => {
    const te = useRef(null)
    const { styleGuide, langCode, brandData } = useContext(AppContext);
    
    const activationPointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.activationPointsSectionLabels
     ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.activationPointsSectionLabels : null

    return <div ref={te} className="tr" key={idx} style={{alignItems: "center", height: 52}}>
    {!brandData?.features?.disableActivateWalletFeature && <div className="td tcol-4">
        {
            sale.status === "WAITING" && sale.walletAmountPoints > 0 &&
            <CustomCheckbox
            style={{marginTop: 0, marginLeft: 16}}
                checked={sale.checked ? true : false}
                onChange={(e) => {
                    setSales(sales.map((sale,index) => {
                      if(index === idx){
                        sale.checked = e.target.checked
                      }
                      return sale
                    }))
                }}
            />
        }
      </div>}
      <div className="td tcol-4">{sale.walletNote}</div>
      <div className="td tcol-2">{sale.walletAmountPoints}</div>
      <div className="td tcol-4">{moment(sale.timeInsert).format('DD/MM/YYYY')}</div>
      {!brandData?.features?.disableActivateWalletFeature && 
      <div className="td tcol-2" style={{ color: sale.status === "WAITING" ? styleGuide.color7 : sale.status === "ACTIVATED" ? styleGuide.color8 : sale.status === 'COMPENSATION' ? "#0077ff" : "#ff0000" }}>
        <span>{sale.status === "WAITING" ? activationPointsSectionLabels?.statusWaitingText || translate("_ACTIVATE_POINTS_","Aktivér point") : 
        sale.status === 'ACTIVATED' ? activationPointsSectionLabels?.statusActivatedText || translate("_ACTIVATED_","Aktiveret") : 
        sale.status === 'COMPENSATION' ? activationPointsSectionLabels?.statusCompensationText || translate("_COMPENSATION_","Udligning") :
        sale.status === 'PENDING' ? activationPointsSectionLabels?.statusPendingText || translate("_COMPENSATION_PENDING_","Afventer") :
        sale.status === 'TRANSFERRED' ? activationPointsSectionLabels?.statusCompensationText || translate("_TRANSFERRED_","Point overført") :
        activationPointsSectionLabels?.statusExpiredText || translate("_EXPIRED_","Udløbet")}</span>
        {!!sale.compensationNote && <Tooltip title={sale.compensationNote}><HelpOutlineIcon style={{marginLeft: 4, fontSize: 16}}/></Tooltip>}
      </div>}
    </div>
}

export default Sales;
