import Translate, { translate } from "utils/Translate";
import Image from "components/elements/Image";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import "./HomePageBanner2.css"
import ButtonLarge1 from "components/elements/ButtonLarge1";
import ButtonMedium4 from 'components/elements/ButtonMedium4'
import { useNavigate } from "react-router-dom";
import { createUseStyles } from 'react-jss'
import RoundIconButton2 from 'components/elements/RoundIconButton2'
import ArrowLeft from 'components/icons/ArrowLeft'
import ArrowRight from 'components/icons/ArrowRight'
import Carousel from 'react-bootstrap/Carousel'
import { callAPI } from "utils/API";
import { truncateWithEllipses } from "utils/general";


const jss = createUseStyles({
    ProductWrapper: {
        height: (styleGuide) => styleGuide.productLargeHeightMobile,
        width: (styleGuide) => styleGuide.productLargeWidthMobile,
        flexDirection: 'row'
    },
    ProductCarouselImage: {
        width: '150%',
    },
    bannerTitle: {
        fontWeight: (styleGuide) => styleGuide.headline1FontWeight,
        fontSize: (styleGuide) => styleGuide.headline1FontSize,
        lineHeight: (styleGuide) => styleGuide.headline1LineHeight
    },
    headingTitle: {
        fontWeight: (styleGuide) => styleGuide.headline2FontWeight,
        fontSize: (styleGuide) => styleGuide.headline2FontSize,
        lineHeight: (styleGuide) => styleGuide.headline2LineHeight
    },
    bannerContent: {
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        fontWeight: (styleGuide) => styleGuide.contentNormalFontWeight,
        fontSize: (styleGuide) => styleGuide.contentNormalFontSize,
        lineHeight: (styleGuide) => styleGuide.contentNormalLineHeight,
    },
    "@media screen and (min-width: 700px) and (max-width:1023px)": {
    bannerTitle: {
        fontWeight: (styleGuide) => styleGuide.headline2FontWeight,
        fontSize: (styleGuide) => styleGuide.headline2FontSize,
        lineHeight: (styleGuide) => styleGuide.headline2LineHeight
    }
    },
    "@media screen and (max-width:700px)": {
    bannerTitle: {
        fontWeight: (styleGuide) => styleGuide.headline3FontWeight,
        fontSize: (styleGuide) => styleGuide.headline3FontSize,
        lineHeight: (styleGuide) => styleGuide.headline3LineHeight
        },
    headingTitle: {
        fontWeight: (styleGuide) => styleGuide.headline3FontWeight,
        fontSize: (styleGuide) => styleGuide.headline3FontSize,
        lineHeight: (styleGuide) => styleGuide.headline3LineHeight
    }
    },
    '@media screen and (min-width: 768px)': {
    ProductWrapper: {
        height: (styleGuide) => styleGuide.productLargeHeightTab,
        width: (styleGuide) => styleGuide.productLargeWidthTab
    },
    },
    '@media screen and (min-width: 1280px)': {
    ProductWrapper: {
        height: (styleGuide) => styleGuide.productLargeHeight,
        width: (styleGuide) => styleGuide.productLargeWidth,
    },
    },
    '@media screen and (max-width: 1023px)': {
    ProductWrapper: {
        flexDirection: 'column-reverse',
        height: '90vw !important',
    },
    ProductCarouselImage: {
        width: '100%'
    }
    },
    '@media screen and (min-width: 500px) and (max-width: 600px)': {
    ProductWrapper: {
        height: '100vw !important',
    }
    },
    '@media screen and (max-width: 500px)': {
    ProductWrapper: {
        height: '120vw !important',
    }
    },
    '@media screen and (max-width: 400px)': {
    ProductWrapper: {
        height: '130vw !important',
    }
    },
    '@media screen and (max-width: 300px)': {
    ProductWrapper: {
        height: '150vw !important',
    }
    },
})

const htmlDecode = (input) => {
    let e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
}

const Products = (props) => {
const { styleGuide } = useContext(AppContext)
const navigate = useNavigate()
const classes = jss(styleGuide)
const imageHeight = `100%`
const {
    productID,
    titleOnclick,
    buttonOnclick,
    imageOnclick,
    headingText,
    contentText,
    hideReadMore,
    textColor,
    imageSrc,
    imageStyles,
} = props
    
return (
    <div
        id="HomepageBanner"
        className={`${classes.ProductWrapper} ${props.className ? props.className : ''}`}
        style={{
            display: 'flex',
            position: 'relative',
            cursor: 'pointer',
            ...props.style
        }}
        onClick={() => productID ? navigate(`/product/${productID}`) : null}
    >
    <Image
        className={classes.ProductCarouselImage}
        src={imageSrc}
        onClick={imageOnclick}
        style={{
            position: 'relative',
            top: 0,
            left: 0,
            zIndex: 0,
            height: imageHeight,
            backgroundSize: "100% 100%",
            backgroundColor: styleGuide.productInfoBackground,
            ...imageStyles
        }}
    >
    <div className="banner2-div">
        <div className="banner2-heading" onClick={titleOnclick}>
            <span
            style={{
                fontFamily: styleGuide.fontFamily,
                fontStyle: 'normal',
                color: textColor,
                justifyContent: 'center'
            }}
            className={classes.headingTitle}
            dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_HEADING_TEXT_', headingText))}}
        >
        </span>
        </div>
        <div className="banner2-paragraph">
            <span
            style={{
                fontStyle: 'normal',
                color: textColor,
                whiteSpace: "pre-line"
            }}
            className={classes.bannerContent}
            dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_BANNER_CONTENT_', truncateWithEllipses(contentText, 200)))}}
            >
        </span>
        </div>
        {
            !hideReadMore 
            ? 
            <div className="banner2-button">
            <ButtonMedium4
                style={{
                    backgroundColor: styleGuide.color1,
                    color: styleGuide.color18,
                    width: '98%',
                    height:"40px",
                    justifyContent:'center'
                    }}
                fallbackText={'Læs mere'} showIcon={false} onClick={buttonOnclick} 
            />
            </div> 
            : 
            null
        }
        </div>
    </Image>
    </div>
)
}

const CustomCarouselProduct = (props) => {
const { styleGuide } = useContext(AppContext)
const classes = jss(styleGuide)
const [slideNumber, setslideNumber] = useState(null)
const [indexProp, setindexProp] = useState({})

const {children, style, className, onClick, indicators, controls, nextIcon, prevIcon, wrap, interval, id, activeIndex} = props

useEffect(() => {
    if(activeIndex && activeIndex > -1 && !interval) {
        setslideNumber(activeIndex)
    } else {
        setslideNumber(null)
    }
}, [interval, activeIndex])

useEffect(() => {
    if(!slideNumber || isNaN(slideNumber) || slideNumber < 0) {
        setindexProp({})
    } else {
        setindexProp({activeIndex: slideNumber})
    }
},[slideNumber])

return (
    <div style={style} className={props.productPosts.length > 1 ? `custom_carousel ${className}` : ''} onClick={onClick} >
    <Carousel
            bsPrefix='custom'
            id={id || ''}
            wrap={wrap === true || wrap === false ? wrap : true}
            controls={controls === true || controls === false ? controls : true}
            indicators={indicators || false}
            interval={interval} 
            fade={true}
            nextIcon={props.productPosts.length > 1 ? nextIcon ? nextIcon : <RoundIconButton2 style={{background: '#221e1e4f',width:'55px',height:'55px',marginTop:0}} icon={<ArrowRight fill={styleGuide.color1} />} /> : null}
            prevIcon={props.productPosts.length > 1 ? prevIcon ? prevIcon : <RoundIconButton2 style={{background: '#221e1e4f',width:'55px',height:'55px',marginTop:0}} icon={<ArrowLeft fill={styleGuide.color1} />} /> : null}
            {...indexProp}
        >
            { children.map((item, idx) => (
                <Carousel.Item key={idx}>
                    {item}
                </Carousel.Item>
            )) }
            
        </Carousel>
    </div>
)
}

const HomePageBanner2 = (props) => {
    const { styleGuide, accessibleBlogs } = useContext(AppContext);
    const classes = jss(styleGuide);
    const { title, text, textColor, buttonText, buttonAction} = props
    const navigate = useNavigate()
    const [productPosts, setproductPosts] = useState([]);
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const fetchProductPosts = async () => {
        if (!accessibleBlogs.length) return []
        const BlogIDs = accessibleBlogs
            .filter(item => item.blogType === 'PRODUCT')
            .map((item) => item.BlogID);
        const sortBy = "poolOrder";
        const sortOrder = "ASC";
        const productItems = await callAPI(
            "/post/getPostsOfMultipleBlogs",
            "GET",
            { query: { BlogIDs, sortBy, sortOrder } }
        );
        if (productItems && productItems.length > 0) {
            setproductPosts(productItems);
        }
    }

    useEffect(async () => {
    fetchProductPosts()
}, [accessibleBlogs]);

    return <>
        <div className="flexDiv-banner2 banner-container-banner2" style={{ display: 'flex'}}>
            <div className="banner-div1" style={{ backgroundColor: styleGuide.color14, display: 'flex' }}>
                <div className="theme2-banner-text">
                <span
                    style={{
                        fontFamily: styleGuide.fontFamily,
                        fontStyle: 'normal',
                        color: textColor,
                        maxWidth: '99%' 
                    }}
                    className={classes.bannerTitle}
                    dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_BANNER_HEADLINE_', title))}}
                    >
                </span>
                <span
                    style={{
                        fontStyle: 'normal',
                        color: textColor
                    }}
                    className={classes.bannerContent}
                    dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_BANNER_CONTENT_', text))}}
                    >
                </span>
                    <ButtonLarge1 style={{ width:"276px", height:"60px", backgroundColor: styleGuide.color16, maxWidth: 'fit-content' }} fallbackText={buttonText} onClick={buttonAction} />
                </div>
            </div>
        <div className="banner-div2">
        <CustomCarouselProduct productPosts={productPosts} interval={3000} style={{boxShadow: `0px 0px 100px rgba(34, 34, 34, 0.15)`}}>
            {productPosts.map((item, idx) =>
            <Products
                key={idx}
                productID={item.ProductID}
                tagStyles={{ borderRadius: 8 }}
                textColor={textColor}
                hideReadMore={item.hideReadMore}
                imageOnclick={() => item.hideReadMore && item.link ? openInNewTab(item.link) : false}
                titleOnclick={() => item.hideReadMore && item.link ? openInNewTab(item.link) : navigate(`post/${item.PostID}`)}
                buttonOnclick={() => navigate(`post/${item.PostID}`)}
                style={{ width: '100%', height: 850 }}
                headingText={item.elementTitle || ''}
                contentText={item.elementContent || ''}
                imageSrc={`linear-gradient(157.95deg, rgba(34, 34, 34, 0) 14.41%, rgba(34, 34, 34, 0.6) 100%), url(
                    ${ !!item.homeBannerImage ? item.homeBannerImage : !!item.attachments ? item.attachments[0]?.imageOriginSource : '' }
                )`}
            />)}
        </CustomCarouselProduct>
        </div>
    </div>
</>
}

export default HomePageBanner2