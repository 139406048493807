import { forwardRef, useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import RoundIconButton1 from "./RoundIconButton1";
import CaretUp from "components/icons/CaretUp";
import { AppContext } from "contexts/AppContext";
import CaretUpDouble from "components/icons/CaretUpDouble";
import CaretDownDouble from "components/icons/CaretDownDouble";
import CaretDown2 from "components/icons/CaretDown2";
import { isInViewport } from "utils/general";
import RoundIconButton3 from "./RoundIconButton3";
import RoundIconButton4 from "./RoundIconButton4";

const ScrollButton = forwardRef((props) => {
  const { scrollTargets = [] } = props
  const { styleGuide } = useContext(AppContext)
  const [targetElements, setTargetElements] = useState([]);
  const [showButtonToTop, setShowButtonToTop] = useState(false);
  const [showButtonToBottom, setShowButtonToBottom] = useState(true);
  const [showButtonToPreviousSection, setShowButtonToPreviousSection] = useState(false);
  const [showButtonToNextSection, setShowButtonToNextSection] = useState(true);

  const toggleButtonToTop = () => setShowButtonToTop(document.documentElement.scrollTop >= 500 ? true : false)
  const toggleButtonToBottom = () => setShowButtonToBottom(!isInViewport("#footer") && document.documentElement.scrollTop <= document.documentElement.scrollHeight - 700 ? true : false)
  const toggleButtonToPreviousSection = () => setShowButtonToPreviousSection(document.documentElement.scrollTop >= 500 ? true : false)
  const toggleButtonToNextSection = () => setShowButtonToNextSection(!isInViewport("#footer") && document.documentElement.scrollTop <= document.documentElement.scrollHeight - 700 ? true : false)

  const scrollToPosition = (position = 0) => {
    window.scrollTo({ top: position, behavior: "smooth" })
  }

  const toTop = () => scrollToPosition(0)

  const toBottom = () => scrollToPosition(document.documentElement.scrollHeight)

  const toPreviousSection = () => {
    const currentPosition = document.documentElement.scrollTop - 10 
    const previousPosition = targetElements.filter((v, i, a) => i < a.length - 1).filter(i => i.position < currentPosition)
    if(!previousPosition || !previousPosition.length) return
    const previousSection = document.getElementById(previousPosition[previousPosition.length - 1].id)
    if(!previousSection) return
    previousSection.scrollIntoView(true)
    // scrollToPosition(previousSection.scrollHeight)
  }

  const toNextSection = () => {
    const currentPosition = document.documentElement.scrollTop + 10
    const nextPosition = targetElements.filter((v, i, a) => i > 0).filter(i => i.position > currentPosition)
    if(!nextPosition || !nextPosition.length) return
    const nextSection = document.getElementById(nextPosition[0].id)
    if(!nextSection) return
    nextSection.scrollIntoView(true)
    // scrollToPosition(nextSection.scrollHeight)
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleButtonToTop);
    window.addEventListener("scroll", toggleButtonToBottom);
    window.addEventListener("scroll", toggleButtonToPreviousSection);
    window.addEventListener("scroll", toggleButtonToNextSection);

    return () => {
      window.removeEventListener("scroll", toggleButtonToTop);
      window.removeEventListener("scroll", toggleButtonToBottom);
      window.removeEventListener("scroll", toggleButtonToPreviousSection);
      window.removeEventListener("scroll", toggleButtonToNextSection);
    }
  }, []);

  useEffect(() => {
    if (!scrollTargets || !scrollTargets.length) return
    const elementDetails = []
    scrollTargets.forEach(id => {
      const element = document.getElementById(id)
      if(!element) return
      elementDetails.push({
        id,
        element,
        position: element?.offsetTop || 0
      })
    });
    elementDetails.sort((a,b) => a.position - b.position)
    setTargetElements(elementDetails)
  }, [scrollTargets, document.documentElement.offsetHeight])

  return (
    <>
      <div
        className="large-screen-only"
        style={{
          position: "fixed",
          transform: "translateY(50%)",
          bottom: "50%",
          right: "0.5rem",
          cursor: "pointer",
          zIndex: 99,
          flexDirection: "column",
          gap: 10
        }}
      >
        {
          showButtonToTop &&
          <Col sm={12}>
            <span onClick={toTop}>
              <RoundIconButton4 style={{ cursor: "pointer" }} icon={<CaretUpDouble fill={styleGuide.color1} />} />
            </span>
          </Col>
        }
        {
          showButtonToPreviousSection &&
          <Col sm={12}>
            <span onClick={toPreviousSection}>
              <RoundIconButton4 style={{ cursor: "pointer" }} icon={<CaretUp fill={styleGuide.color1} />} />
            </span>
          </Col>
        }
        {
          showButtonToNextSection &&
          <Col sm={12}>
            <span onClick={toNextSection}>
              <RoundIconButton4 style={{ cursor: "pointer" }} icon={<CaretDown2 fill={styleGuide.color1} />} />
            </span>
          </Col>
        }
        {
          showButtonToBottom &&
          <Col sm={12}>
            <span onClick={toBottom}>
              <RoundIconButton4 style={{ cursor: "pointer" }} icon={<CaretDownDouble fill={styleGuide.color1} />} />
            </span>
          </Col>
        }
      </div>
    </>
  );
});

export default ScrollButton


