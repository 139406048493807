const Cart1 = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg style={props.style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6357 8.8C12.2355 8.8 12.7633 8.472 13.0352 7.976L15.8981 2.784C16.194 2.256 15.8101 1.6 15.2024 1.6H3.36675L2.61503 0H0V1.6H1.59941L4.47834 7.672L3.39874 9.624C2.81495 10.696 3.58267 12 4.79822 12H14.3947V10.4H4.79822L5.67789 8.8H11.6357ZM4.12647 3.2H13.8429L11.6357 7.2H6.02176L4.12647 3.2ZM4.79822 12.8C3.91854 12.8 3.20681 13.52 3.20681 14.4C3.20681 15.28 3.91854 16 4.79822 16C5.67789 16 6.39762 15.28 6.39762 14.4C6.39762 13.52 5.67789 12.8 4.79822 12.8ZM12.7952 12.8C11.9156 12.8 11.2038 13.52 11.2038 14.4C11.2038 15.28 11.9156 16 12.7952 16C13.6749 16 14.3947 15.28 14.3947 14.4C14.3947 13.52 13.6749 12.8 12.7952 12.8Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    )
}
export default Cart1
