import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import { AppContext } from 'contexts/AppContext';
import {createUseStyles} from 'react-jss'

const jss = createUseStyles({
    inactiveButton: {
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius, 
        fontWeight: '600',
        border: 'none',
        fontSize: 14,
        padding: '13px'
    }
   
})

const ButtonInactive = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)

    return (
        <Button
            style={{...props.style, color: '#6d757a',backgroundColor: '#eeeeee',}}
            disabled={props.disabled}
            className={classes.inactiveButton}>
            <span>{props.buttonText}</span>
        </Button>
    )
}


export default ButtonInactive;