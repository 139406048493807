const Dislike = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.81786 0.166016H3.36331C2.86028 0.166016 2.42998 0.457682 2.24816 0.877682L0.417856 4.99018C0.363311 5.12435 0.333008 5.26435 0.333008 5.41602V6.58268C0.333008 7.22435 0.878462 7.74935 1.54513 7.74935H5.36937L4.79361 10.4152L4.77543 10.6018C4.77543 10.841 4.87846 11.0627 5.0421 11.2202L5.68452 11.8327L9.67846 7.98852C9.89664 7.77852 10.03 7.48685 10.03 7.16602V1.33268C10.03 0.691016 9.48452 0.166016 8.81786 0.166016ZM11.2421 0.166016V7.16602H13.6663V0.166016H11.2421Z" fill={pathFill}/>
        </svg>
        

        )
}
export default Dislike