const Video = (props) => {
    const {
        url,
        style,
        className,
        width, 
        height,
    } = props
    
    if(!url) return null

    const embeddedVideo = getVideoType(url)

    return (embeddedVideo)
        ?   <EmbeddedVideo videoType={embeddedVideo.type} embedId={embeddedVideo.embedId} className={className} style={style} width={width} height={height} />
        :   <video className={className} style={style} src={url} width={width} height={height} controls />
}
  
  
export default Video;

export const EmbeddedVideo = ({style, className, videoType, embedId, width, height}) => {
    switch (videoType) {
        case 'youtube':
            return <YoutubeEmbed embedId={embedId} className={className} style={style} width={width} height={height} />
        case 'vimeo':
            return <VimeoEmbed embedId={embedId} className={className} style={style} width={width} height={height} />
        default:
            return null
    }
}

export const YoutubeEmbed = ({ embedId, width, height, style, className }) => {
    if(!embedId) return null
    return (
        <div className={className} style={style}>
            <iframe
                width={width || `100%`}
                height={height || `100%`}
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    )
}
  
export const VimeoEmbed = ({ embedId, style, className, width, height }) => {
    if(!embedId) return null
    return (
        <div className={ className } style={ style } >
            <iframe 
                width={ width || `100%` } 
                height={ height || `100%` } 
                src={ '//player.vimeo.com/video/' + embedId } 
                allowFullScreen 
                frameBorder="0"
            />
        </div>
    )
}

// exported video util functions

export const getVideoType = (url) => {
    if(!url) return null
    
    const parsed = new URL(url)

    if(!parsed || !parsed.hostname) return null

    let embedId = null
    switch (parsed.hostname) {
        case 'youtu.be':
        case 'youtube.com':
        case 'youtube-nocookie.com':
        case 'm.youtube.com':
        case 'www.youtube.com':
            embedId = youtubeVideoID(url);
            return { type: 'youtube', embedId: embedId, url: url }
        case 'vimeo.com':
            embedId = vimeoVideoID(url);
            return { type: 'vimeo', embedId: embedId, url: url }
        default:
            return null
    }
}

export const youtubeVideoID = (url) => {
    if(!url) return null

    let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    return url.match(p) ? RegExp.$1 : null
}

export const vimeoVideoID = (url) => {
    if(!url) return null

    const parsed = new URL(url)
    let videoID = parsed.pathname.split('/')
    videoID = videoID && videoID.length ? videoID[videoID.length - 1] : null

    return videoID

}

