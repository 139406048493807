import Headline6 from "components/elements/Headline6";
import { AppContext } from "contexts/AppContext";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import "./Profile.css";
import { Modal } from "react-bootstrap";
import RoundIconButton1 from "components/elements/RoundIconButton1";
import { callAPI } from "utils/API";
import Headline2 from "components/elements/Headline2";
import ButtonLarge4 from "components/elements/ButtonLarge4";
import Headline4 from "components/elements/Headline4";
import ContentLarge from "components/elements/ContentLarge";
import ContentSmall from "components/elements/ContentSmall";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import CustomInput from "components/elements/CustomInput";
import { translate } from "utils/Translate";
import CaptionText from "components/elements/CaptionText";
import moment from "moment";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import CaretDown from "components/icons/CaretDown";
import TransactionTable from "components/blocks/TransactionTable/TransactionTable";
import CustomCheckbox from "components/elements/CustomCheckbox";
import { useLocation } from "react-router-dom";
import Close from "components/icons/Close";
import SendPointPopUp from 'components/blocks/sendPointPopUp/SendPointPopUp'
import { pointsConvertor } from "utils/general";


const StarkOrderType = {
  0: 'Tildelt af STARK',
  1: 'Optjent ved køb',
  2: 'Brugte point',
  3: 'Udløbet point'
};

export const PointOverview = (props) => {
  const [transactions, setTransactions] = useState(null);
  const { styleGuide, langCode, brandData, pointStatus, setPointstatus, userData } = useContext(AppContext);
  const [userInConfiguredGroups, setUserInConfiguredGroups] = useState(false);
  const location = useLocation();
  const [showSendPointsModal, setShowSendPointsModal] = useState(false);
  const [teamAccess, setTeamAccess] = useState();
  const [teamData, setTeamData] = useState([])
  const { activateComplete, setActivateComplete } = props
  const pointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.pointsSectionLabels
  ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.pointsSectionLabels : null
  const sendPointsAndLoanLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.sendPointsAndLoanLabels
  ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.sendPointsAndLoanLabels : null

  
  const getTeamData = async()=> {
    let getAllSubordinateUsersOfBrand = await callAPI('/supervisors/getAllSubordinateUsersOfBrand', "GET");
      setTeamData(getAllSubordinateUsersOfBrand)
  }

  const getType = (order) => {
    const validFromDate = moment(order.validFromDate);
    if (validFromDate.isAfter(moment())) {
      return "Kommende point";
    }
    return StarkOrderType[order.type];
  };

  const getTeamAccess = async () => {
    if (brandData?.features?.groupsHavingTeamMenuAccess?.length) {
      let groupNames = brandData.features.groupsHavingTeamMenuAccess
      let getAccess = await callAPI('/group/checkAccess', "GET", { query: { groupNames } });
      setTeamAccess(getAccess)
    }
  }

  const fetchWallet = async () => {
    const userWallet = [];
    if (brandData.BrandID === 87) {
      const pointsHistory = await callAPI('/stark/getPointsHistory', 'GET', {}, null, userData.selectedCustomerNumber)
      const userWalletLines = pointsHistory.transactions;
      if (!userWalletLines || !userWalletLines.length) return setTransactions([]);

      for (const walletLine of userWalletLines) {
        let receiverUserDetails = {
          username: userData.userName,
          userEmail: userData.userEmail,
          UID: userData.userID
        }
        const walletObj = {
          date: moment(walletLine.date).format("DD/MM/YYYY"),
          walletAmountPoints: walletLine.points,
          walletNote:
            walletLine.text,
          sum: 0,
          WalletID: 0,
          Navn: receiverUserDetails?.userName ? receiverUserDetails.userName : "-" ,
          Email: receiverUserDetails?.userEmail ? receiverUserDetails.userEmail : "-" ,
          UID: receiverUserDetails?.userUID ? receiverUserDetails.userUID : "-" ,
          expirationDate: walletLine.expirationDate ? moment(walletLine.expirationDate).format("DD/MM/YYYY") : "",
          validFrom: walletLine.validFromDate ? moment(walletLine.validFromDate).format("DD/MM/YYYY") : "",
          type: getType(walletLine)
        }
        userWallet.push(walletObj);

        userWallet.sort((a, b) => {
          return moment(b.date, "DD/MM/YYYY").toDate() - moment(a.date, "DD/MM/YYYY").toDate();
        });
      }
    } else {
      const userWalletLines = await callAPI(`/user/walletLines`);
      setActivateComplete({...activateComplete, pointOverview: false})
      if (!userWalletLines || !userWalletLines.length) return setTransactions([]);
      
      for (const walletLine of userWalletLines) {
        let receiverUserDetails = walletLine.receiverUserDetails ? walletLine.receiverUserDetails : null
          const walletObj = {
            date: moment(walletLine.timeInsert).format("DD/MM/YYYY"),
            walletAmountPoints: walletLine.walletAmountPoints,
            walletNote:
              walletLine.walletNote === " " || "" ? "-" : walletLine.walletNote,
            sum: walletLine.sum,
            WalletID: walletLine.WalletID,
            Navn: receiverUserDetails?.userName ? receiverUserDetails.userName : "-" ,
            Email: receiverUserDetails?.userEmail ? receiverUserDetails.userEmail : "-" ,
            UID: receiverUserDetails?.userUID ? receiverUserDetails.userUID : "-" ,
          };
          userWallet.push(walletObj);
        }
    }
    
    return setTransactions(userWallet);
  };
  
  const checkAccess = async () => {
    if (!brandData?.features?.sendBudgetPointsGroup)
      return setUserInConfiguredGroups(false);

    const access = await callAPI(`group/checkAccess`, "GET", {
      query: { groupNames: brandData.features.sendBudgetPointsGroup },
    });
    setUserInConfiguredGroups(access === true ? true : false);
  };
  
  useEffect(() => {
    getTeamAccess()
    getTeamData()
  }, [])
  
  useEffect(() => {
    if (pointStatus) {
      var div_scroll = document.getElementById("show_points_section");
      window.scrollTo(div_scroll.offsetLeft, div_scroll.offsetTop);
    }
    setPointstatus(false);
  }, [pointStatus]);
  
  useEffect(() => {
    fetchWallet();
    checkAccess();
  }, []);

  useEffect(() => {
    activateComplete && activateComplete.pointOverview && fetchWallet() 
  }, [activateComplete]);
  
    const NoTransactionsLine = () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          padding: 40,
        }}
      >
        <Headline4
          configuredLabel={pointsSectionLabels?.noPointsTitleText}
          translationKey="_PROFILE_PAGE_NO_WALLET_CAPTION_"
          fallbackText="Ingen transaktion i din pung endnu"
        />
        <ContentLarge
          configuredLabel={pointsSectionLabels?.noPointsContentText}
          translationKey="_PROFILE_PAGE_NO_WALLET_TEXT_"
          fallbackText="Når der sker en transaktion, vil du se det her. "
        />
      </div>
    );
  
    const canSendPoints = !!(
      brandData?.features?.sendPoints?.enableSendPoints
    );

    const canLoanPoints = !!(
      brandData?.features?.sendPoints?.enableSendPoints && brandData?.features?.sendPoints?.enableLoanPoints
    );

    const canSendBudgetPoints = !!(
        brandData?.features?.sendBudgetPoints && userInConfiguredGroups
    );
    const SendPointsModal = ({showSendPointsModal, setShowSendPointsModal, canLoanPoints, sendPointsAndLoanLabels}) => {
        const [amount, setAmount] = useState(null)
        const [userIdentifier, setUserIdentifier] = useState(null)
        const [loan, setLoan] = useState(false)
        const [error, setError] = useState(null)
        const [success, setSuccess] = useState(null)
        const [loading, setLoading] = useState(false)

        const sendPoints = async() =>{
            setError(null)
            setSuccess(null)
            setLoading(true)
            const response = await callAPI(`wallet/sendPoints`, "POST", {
                body: { userIdentifier, amount, loan },
            });
            setLoading(false)
            if(response === 'SUCCESS'){
                setSuccess(response)
            }else{
                setError(response)
            }
        }

        return (
        <Modal
          show={showSendPointsModal}
          onHide={() => setShowSendPointsModal(false)}
          style={{ margin: "auto" }}
          centered
        >
          <Modal.Header
            className="pb-0 border-0"
            closeButton
            style={{ fontSize: 9 }}
          >
            <Headline6
              configuredLabel={sendPointsAndLoanLabels?.popupTitleText}
              translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_CAPTION_"
              fallbackText="Send eller udlån point"
            />
          </Modal.Header>
          <Modal.Body className="pt-4 pb-4">
            <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                <ContentSmall
                    configuredLabel={sendPointsAndLoanLabels?.popupContentText}
                    translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_TEXT_"
                    fallbackText="Brug formularen til at sende eller låne point til en kollega. Marker &quot;Send point som lån&quot; for at din kollega skal betale pointene tilbage."
                />
                <CustomInput
                    wrapperStyle={{ marginBottom: 10 }}
                    label={sendPointsAndLoanLabels?.popupFieldOneText ? sendPointsAndLoanLabels.popupFieldOneText : translate("_PROFILE_PAGE_SEND_POINT_POPUP_EMAIL_FIELD_", "Modtagers navn eller e-mail")}
                    value={userIdentifier}
                    onChange={(e) => setUserIdentifier(e.target.value)}
                />
                <CustomInput
                    wrapperStyle={{ marginBottom: 10 }}
                    label={sendPointsAndLoanLabels?.popupFieldTwoText ? sendPointsAndLoanLabels.popupFieldTwoText : translate("_PROFILE_PAGE_SEND_POINT_POPUP_AMOUNT_FIELD_", "Antal point")}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                {canLoanPoints && 
                <>
                  <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginLeft: 16 }}>
                      <CustomCheckbox checked={loan} onChange={() => setLoan(!loan)} style={{ marginLeft: 16, marginTop: 0 }}/>
                      <ContentSmall configuredLabel={sendPointsAndLoanLabels?.popupCheckboxText} translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_LOAN_FIELD_" fallbackText="Send point som lån"
                          style={{ color: styleGuide.color16, paddingTop: 12 }}
                      />
                  </div>
                  <ContentSmall configuredLabel={sendPointsAndLoanLabels?.popupLoanHelperText}
                      translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_LOAN_HELPTEXT_" fallbackText="Sender du point som et lån, vil pointene blive ført tilbage til din konto så snart der er dækning på modtagerens konto. Såfremt at du ikke vælger Send som lån, anses dette som en gave."
                      style={{ color: styleGuide.color6, marginLeft: 8, marginTop: -8 }}
                  /></>}            
            </div>
            {!!success || !!error ? <div
              style={{
                marginTop: 16,
                marginLeft: 40,
                marginRight:40,
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 24,
                paddingRight: 24,
                border: '1px solid',
                borderColor: success ? styleGuide.color23 : styleGuide.color20,
                backgroundColor: success ? styleGuide.color24 : styleGuide.color21,                
              }}
            >
              {!!success && (
                <><CaptionText
                  style={{ color: styleGuide.color25}}
                  translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_SUCCESS_TEXT_"
                  fallbackText={'Overførslen er gennemført'}
                />
                <Close fill={styleGuide.color7} style={{ width: 8, height: 8, cursor: 'pointer', marginRight: -20, marginTop: -4 }} onClick={() => setSuccess(null)} />
                </>
              )}
              {!!error && (
                <>
                {error === 'RECEIVER_INVALID' ? <CaptionText
                  style={{ color: styleGuide.color22 }}
                  translationKey="RECEIVER_INVALID"
                  fallbackText="Ukendt bruger"
                /> : error === 'INSUFFICIENT_FUNDS' ? 
                <CaptionText
                  style={{ color: styleGuide.color22 }}
                  translationKey="INSUFFICIENT_FUNDS"
                  fallbackText="Du har ikke point nok"
                /> : error === "SEND_POINTS_NOT_UNIQUE_USER" ?
                <CaptionText
                  style={{ color: styleGuide.color22 }}
                  translationKey="SEND_POINTS_NOT_UNIQUE_USER"
                  fallbackText="Point kan ikke overføres til denne email"
                /> :
                <CaptionText
                  style={{ color: styleGuide.color22 }}
                  translationKey="_PROFILE_PAGE_SEND_POINT_POPUP_ERROR_TEXT_"
                  fallbackText="Error"
                />}
                
                <Close fill={styleGuide.color7} style={{ width: 8, height: 8, cursor: 'pointer', marginRight: -20, marginTop: -4 }} onClick={() => setError(null)} />
                </>
              )}

            </div> : null}
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <ButtonMedium1
            configuredLabel={sendPointsAndLoanLabels?.popupAcceptButtonText}
              disabled={loading}
              showIcon={false}
              translationKey="_BUTTON_CAPTION_SAVE_"
              fallbackText="Godkend"
              onClick={() => sendPoints()}
            />
            <ButtonMedium4
              configuredLabel={sendPointsAndLoanLabels?.popupCloseButtonText}
              showIcon={false}
              translationKey="_BUTTON_CAPTION_CLOSE_"
              fallbackText="Luk"
              onClick={() => setShowSendPointsModal(false)}
            />
          </Modal.Footer>
        </Modal>
    )};
  
    return (
      <>
        {showSendPointsModal && <SendPointsModal setShowSendPointsModal={setShowSendPointsModal} showSendPointsModal={showSendPointsModal} canLoanPoints={canLoanPoints} sendPointsAndLoanLabels={sendPointsAndLoanLabels}/>}
        <div
          ref={props.pointRef}
          id={props?.id || ""}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: 100,
            // marginBottom: 100,
          }}
        >
          
          <div
          
            style={{
              display: "flex",
              justifyContent: "space-between",
         }}
          >
           
            <Headline2
              style={{maxWidth: '50%'}}
              configuredLabel={pointsSectionLabels?.titleText}
              translationKey="_PROFILE_PAGE_POINTS_"
              fallbackText="Dine point"
            />
            
          {teamAccess && <div>
          <SendPointPopUp teamData={teamData} />
          </div>}

          {canSendPoints && (
            
              <ButtonLarge4
                configuredLabel={sendPointsAndLoanLabels?.sendPointsButtonText}
                showIcon={false}
                translationKey="_PROFILE_PAGE_SEND_POINT_BUTTON_TEXT_"
                fallbackText="Send/lån point"
                onClick={() => setShowSendPointsModal(true)}
              />
            )}
        </div>
        <div id="show_points_section">
          <Accordion
            style={{
              borderRadius: "14px",
              overflow: "hidden",
              boxShadow:
                "0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            }}
          >
            <AccordionSummary
              expandIcon={<RoundIconButton1 icon={<CaretDown fill="white" />} />}
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                padding: "16px",
              }}
            >
              {!brandData?.features?.hidePointsExpiry ? (
                <>
                  <PointStatus
                    circleColor={"rgb(79, 158, 94)"}
                    points={-4}
                    expireMonthText={"Udløber om mere end 6 måneder"}
                  />
                  <PointStatus
                    circleColor={"rgb(239, 224, 123)"}
                    points={0}
                    expireMonthText={"Udløber om 6 måneder"}
                  />
                  <PointStatus
                    circleColor={"rgb(199, 61, 61)"}
                    points={0}
                    expireMonthText={"Udløber om 3 måneder"}
                  />
                  <PointStatus
                    circleColor={"rgb(7,7,7)"}
                    points={0}
                    expireMonthText={"Udløber i denne måned"}
                  />
                </>
              ) : (
                <Headline4
                  configuredLabel={pointsSectionLabels?.sectionBoxTitleText}
                  translationKey="_PROFILE_PAGE_POINT_OVERVIEW_CAPTION_"
                  fallbackText="Point oversigt"
                  style={{ padding: "30px 20px" }}
                />
              )}
            </AccordionSummary>
            <AccordionDetails
              style={{
                padding: "30px",
                borderTop: `1px solid ${styleGuide.color4}`,
                overflow: 'scroll'
              }}
            >
              {!transactions ? null : !!transactions && !transactions.length ? (
                <NoTransactionsLine />
              ) : (
                <TransactionTable transactions={transactions} canSendBudgetPoints={canSendBudgetPoints} />
              )}
            </AccordionDetails>
            </Accordion>
            </div>
        </div>
      </>
    );
  };
  
  export const PointStatus = (props) => {
    const {brandData} = useContext(AppContext)
    const { circleColor, points, expireMonthText } = props;
    return (
      <>
        <div className="point-col-lg">
          <div
            style={{
              padding: "10px",
              height: "35px",
              width: "35px",
              borderRadius: "50px",
              backgroundColor: circleColor,
              float: "left",
              marginRight: "15px",
            }}
          >
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50px",
                backgroundColor: "white",
              }}
            >
              &nbsp;
            </div>
          </div>
          <div>
            <div>{pointsConvertor(brandData,points, translate("_POINTS_"))}</div>
            <div>{expireMonthText}</div>
          </div>
        </div>
      </>
    );
  };