const { useContext, useEffect } = require("react");
const { useNavigate, useParams } = require("react-router-dom");
const { AppContext } = require("contexts/AppContext")

const Alias = () => {
    const { brandData } = useContext(AppContext)
    const { alias } = useParams();
    const navigate = useNavigate()
    const aliasMap = brandData?.features?.aliasMap || []

    useEffect(()=>{
        let navigateTo = ""
        for(const item of aliasMap) {
            if(item?.alias?.includes(alias)) 
                navigateTo = item.path
        }
        if(!navigateTo) return 
        navigate(navigateTo)
    },[alias])
    return <></>
}

export default Alias