import CircularIndeterminate from "components/blocks/progressBar/progressBar";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import CustomSelect2 from "components/elements/CustomSelect2";
import ExplainerText from "components/elements/ExplainerText";
import Headline2 from "components/elements/Headline2";
import { AppContext } from "contexts/AppContext";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { callAPI } from "utils/API";
import { monthsSelect, translate } from "utils/Translate";
import {styled,Paper,Table,TableBody,TableRow,TableContainer,TableCell,TableHead,tableCellClasses} from '@mui/material'
import ExportToXlsx from "components/elements/ExportToXlsx";
import Headline5 from "components/elements/Headline5";

const RADIAN = Math.PI / 180;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      border:'none'
    },
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight:'bolder'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function KAMDashboard(props){
    const [loading, setLoading] = useState(false)
    const {userData, brandData, styleGuide} = useContext(AppContext)
    const showKAMDashboard = brandData?.BrandID === 63 && !!userData?.userDepartmentUID
    const [piData, setPiData] = useState(null)
    const [barData, setBarData] = useState(null)
    const [year, setYear] = useState(moment().year())
    const [month, setMonth] = useState(null)
    const [report, setReport] = useState([])
    const [showReportModal, setShowReportModal] = useState(false)
    const [showOrderReportModal, setShowOrderReportModal] = useState(false)
    const [deptLeader, setDeptLeader] = useState(false)
    const [kams, setKams] = useState([])
    const [KAMUserID, setKAMUserID] = useState(null)
    const [accessFilter, setAccessFilter] = useState(null)
    const [showOverviewModal, setShowOverviewModal] = useState(false)
    const [overview, setOverview] = useState([])
    const [modalLoading, setModalLoading] = useState(false)
    const numberFormatter = new Intl.NumberFormat('da-dk',{})
    const [orderReport, setOrderReport] = useState([])

    const fetchGraphData = async () => {
        setLoading(true)
        const response = await callAPI(`/user/kamDashboard`,'GET',{query: {year, month, KAMUserID, userAccess: accessFilter}})
        setLoading(false)
        if(response.piData){
            if(month > 0){
                const match = response.barData.find(bar => bar.month === `${month}/${year}`)
                if(match){
                    setPiData([{name: 'Forbrug', value: match.Forbrug},{name: 'Resterende', value: match.Resterende}])
                }else{
                    setPiData(response.piData)
                }
            }else{
                setPiData(response.piData)
            }
        }
        if(response.barData){
            setBarData(response.barData)
            if(!kams?.length){
                setKams(response.barData)
            }
        }else{
            setBarData([])
        }
        setDeptLeader(!!response.isDeptLeader)
    }

    const fetchReportData = async () => {
        setShowReportModal(true)
        setModalLoading(true)
        const response = await callAPI(`/user/kamDashboardReport`,'GET',{query: {year, month, KAMUserID, userAccess: accessFilter}})
        setModalLoading(false)
        if(response.data){
            setReport(response.data)            
        }
    }

    const fetchOrderReportData = async () => {
        setShowOrderReportModal(true)
        setModalLoading(true)
        const response = await callAPI(`/user/kamOrderReport`,'GET',{query: {year, month, KAMUserID, userAccess: accessFilter}})
        setModalLoading(false)
        if(response.data){
            setOrderReport(response.data)            
        }
    }
    
    const fetchKAMOverview = async() => {
        setShowOverviewModal(true)
        setModalLoading(true)
        const response = await callAPI(`/user/kamOverviewReport`,'GET')
        setModalLoading(false)
        if(response.data){
            setOverview(response.data)
        }
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${piData[index].value} (${(percent * 100).toFixed(0)}%)`}
            </text>
        );
    };

    useEffect(()=>{
        if(!loading){
            fetchGraphData()
        }
    }, [month, KAMUserID, accessFilter])

    return (
        !showKAMDashboard ? null :
        <div id={props?.id || ""} ref={props.kamdashboardRef} style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 160}}>
            <Headline2 translationKey='_PROFILE_PAGE_KAM_DASHBOARD_' fallbackText='KAM Dashboard' style={{ marginTop: 16 }} />
            {!!loading ? <CircularIndeterminate style={{alignSelf: 'center'}}/> :
            <>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16}}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 0}}>
                            <ExplainerText translationKey="_PROFILE_PAGE_KAM_DASHBOARD_SELECT_YEAR_" fallbackText='År'></ExplainerText>
                            <CustomSelect2
                                wrapperStyle={{marginBottom: 50}}
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                options={[{value: 2024, label: 2024}]}
                                style={{width: 100,  border: `1px solid ${styleGuide.color18}`, padding: 10}}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 0}}>
                            <ExplainerText translationKey="_PROFILE_PAGE_KAM_DASHBOARD_SELECT_MONTH_" fallbackText='Måned'></ExplainerText>
                            <CustomSelect2
                                wrapperStyle={{marginBottom: 50}}
                                value={month || 0}
                                onChange={(e) => setMonth(e.target.value)}
                                options={[{value: 0, label: 'Alle'},...monthsSelect]}
                                style={{width: 100,  border: `1px solid ${styleGuide.color18}`, padding: 10}}
                            />
                        </div>
                        {!!deptLeader && <div style={{ display: 'flex', flexDirection: 'column', gap: 0}}>
                            <ExplainerText translationKey="_PROFILE_PAGE_KAM_DASHBOARD_SELECT_KAM_" fallbackText='KAM'></ExplainerText>
                            <CustomSelect2
                                wrapperStyle={{marginBottom: 50}}
                                value={KAMUserID || 0}
                                onChange={(e) => setKAMUserID(e.target.value)}
                                options={[{value: 0, label: 'None'},...kams.map(kam => { return {value: kam.UserID, label: kam.userName}})]}
                                style={{width: 240,  border: `1px solid ${styleGuide.color18}`, padding: 10}}
                            />
                        </div>}
                        {!!deptLeader && <div style={{ display: 'flex', flexDirection: 'column', gap: 0}}>
                            <ExplainerText translationKey="_PROFILE_PAGE_KAM_DASHBOARD_SELECT_ACCESS_" fallbackText='Adgang'></ExplainerText>
                            <CustomSelect2
                                wrapperStyle={{marginBottom: 50}}
                                value={accessFilter || 0}
                                onChange={(e) => setAccessFilter(e.target.value)}
                                options={[{value: 0, label: 'Alle'},{value: 'APPROVED', label: 'Approved'},{value: 'DECLINED', label: 'Declined'}]}
                                style={{width: 120,  border: `1px solid ${styleGuide.color18}`, padding: 10}}
                            />
                        </div>}
                    </div>
                    <div style={{display:'flex', flexDirection: 'row', gap: 8}}>
                        {!!deptLeader && <div style={{alignSelf: 'center'}}>
                            <ButtonMedium1 showIcon={false} translationKey="_PROFILE_PAGE_KAM_DASHBOARD_OVERVIEW_BUTTON_" fallbackText="Oversigt" 
                            onClick={fetchKAMOverview}></ButtonMedium1>
                        </div>}
                        <div style={{alignSelf: 'center'}}>
                            <ButtonMedium1 showIcon={false} translationKey="_PROFILE_PAGE_KAM_DASHBOARD_REPORT_BUTTON_" fallbackText="Rapport" 
                            onClick={fetchReportData}></ButtonMedium1>
                        </div>
                        <div style={{alignSelf: 'center'}}>
                            <ButtonMedium1 showIcon={false} translationKey="_PROFILE_PAGE_KAM_DASHBOARD_ORDER_REPORT_BUTTON_" fallbackText="Ordre" 
                            onClick={fetchOrderReportData}></ButtonMedium1>
                        </div>
                    </div>
                </div>
                <Modal size="xl" show={showOrderReportModal} onHide={() => setShowOrderReportModal(false)}>
                    <Modal.Header closeButton>
                        <Headline2 style={{marginRight: 16}} fallbackText={`Oversigt over Ordre - ${!!month ? month+'/'+year : year}`}></Headline2>
                        {!!orderReport.length && <ExportToXlsx
                            excelData={orderReport.map(r => {
                                return !!deptLeader ? {
                                    Point: numberFormatter.format(r.totalPoints),
                                    Adresse: `${r.orderConsigneeName}, ${r.orderConsigneeAddress1}, ${r.orderConsigneeZipcode}, ${r.orderConsigneeCity}`,
                                    Bestillingsdato: moment(r.orderDate, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm"),
                                    Shipped: moment(r.shippedDate, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm"),
                                    Status: r.orderFlowStatus,
                                    Navn: r.userName,
                                    UID: r.userUID,
                                    Department: r.userDepartmentUID
                                }:{
                                    Point: numberFormatter.format(r.totalPoints),
                                    Adresse: `${r.orderConsigneeName}, ${r.orderConsigneeAddress1}, ${r.orderConsigneeZipcode}, ${r.orderConsigneeCity}`,
                                    Bestillingsdato: moment(r.orderDate, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm"),
                                    Shipped: moment(r.shippedDate, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm"),
                                    Status: r.orderFlowStatus
                                }
                            })}
                            fileName={`${year}${month > 0 ? "_"+month : ''}_order_export`}
                            buttonText={translate("_DOWNLOAD_TAX_INFO_EXPORT_BUTTON_TEXT_", "Export")}
                            fields={!!deptLeader ? ["Point", "Adresse", "Bestillingsdato","Shipped", "Status", "Navn","UID", "Department"] : ["Point", "Adresse", "Bestillingsdato","Shipped", "Status"]}
                        />}
                    </Modal.Header>
                    <Modal.Body>
                        {!!modalLoading ? <CircularIndeterminate style={{alignSelf: 'center'}}/> :
                         !!orderReport.length ? <TableContainer sx={{boxShadow:'none'}} component={Paper}>                            
                            <Table sx={{ minWidth: 700}} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Point</StyledTableCell>
                                        <StyledTableCell align="left">Adresse</StyledTableCell>
                                        <StyledTableCell align="left">Bestillingsdato</StyledTableCell>
                                        <StyledTableCell align="left">Shipped Dato</StyledTableCell>
                                        <StyledTableCell align="left">Status</StyledTableCell>
                                        {!!deptLeader && <StyledTableCell align="left">Navn</StyledTableCell>}
                                        {!!deptLeader && <StyledTableCell align="left">UID</StyledTableCell>}
                                        {!!deptLeader && <StyledTableCell align="left">Department</StyledTableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderReport?.length ? orderReport.map((data,idx) => (
                                        <StyledTableRow hover key={idx}>
                                            <StyledTableCell align="left">{numberFormatter.format(data.totalPoints)}</StyledTableCell>
                                            <StyledTableCell align="left">{data.orderConsigneeName}, {data.orderConsigneeAddress1}, {data.orderConsigneeZipcode}, {data.orderConsigneeCity}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(data.orderDate, "YYYY-MM-DD HH:mm:ss").format('DD-MM-YYYY HH:mm')}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(data.shippedDate, "YYYY-MM-DD HH:mm:ss").format('DD-MM-YYYY HH:mm')}</StyledTableCell>
                                            <StyledTableCell align="left">{data.orderFlowStatus}</StyledTableCell>
                                            {!!deptLeader && <StyledTableCell align="left">{data.userName}</StyledTableCell>}
                                            {!!deptLeader && <StyledTableCell align="left">{data.userUID}</StyledTableCell>}
                                            {!!deptLeader && <StyledTableCell align="left">{data.userDepartmentUID}</StyledTableCell>}
                                        </StyledTableRow>
                                    )):null}
                                </TableBody>
                            </Table>
                        </TableContainer> : <div style={{textAlign: 'center'}}><Headline5 fallbackText='No Data'/></div>}
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={showReportModal} onHide={() => setShowReportModal(false)}>
                    <Modal.Header closeButton>
                        <Headline2 style={{marginRight: 16}} fallbackText={`Oversigt over forbrug - ${!!month ? month+'/'+year : year}`}></Headline2>
                        {!!report.length && <ExportToXlsx
                            excelData={report.map(r => {
                                return !!deptLeader ? {
                                    Point: numberFormatter.format(r.walletAmountPoints),
                                    Note: r.walletNote,
                                    Dato: moment(r.timeInsert).format("DD-MM-YYYY HH:mm"),
                                    Navn: r.userName,
                                    Type: r.walletType
                                }:{
                                    Point: numberFormatter.format(r.walletAmountPoints),
                                    Note: r.walletNote,
                                    Dato: moment(r.timeInsert).format("DD-MM-YYYY HH:mm")
                                }
                            })}
                            fileName={`${year}${month > 0 ? "_"+month : ''}_point_export`}
                            buttonText={translate("_DOWNLOAD_TAX_INFO_EXPORT_BUTTON_TEXT_", "Export")}
                            fields={!!deptLeader ? ["Point", "Note", "Dato", "Navn","Type"] : ["Point", "Note", "Dato"]}
                        />}
                    </Modal.Header>
                    <Modal.Body>
                        {!!modalLoading ? <CircularIndeterminate style={{alignSelf: 'center'}}/> :
                         !!report.length ? <TableContainer sx={{boxShadow:'none'}} component={Paper}>                            
                            <Table sx={{ minWidth: 700}} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Point</StyledTableCell>
                                        <StyledTableCell align="left">Note</StyledTableCell>
                                        <StyledTableCell align="left">Dato</StyledTableCell>
                                        {!!deptLeader && <StyledTableCell align="left">Navn</StyledTableCell>}
                                        {!!deptLeader && <StyledTableCell align="left">Type</StyledTableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {report?.length ? report.map((data,idx) => (
                                        <StyledTableRow hover key={idx}>
                                            <StyledTableCell align="left">{numberFormatter.format(data.walletAmountPoints)}</StyledTableCell>
                                            <StyledTableCell align="left">{data.walletNote}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(data.timeInsert).format('DD-MM-YYYY hh:mm')}</StyledTableCell>
                                            {!!deptLeader && <StyledTableCell align="left">{data.userName}</StyledTableCell>}
                                            {!!deptLeader && <StyledTableCell align="left">{data.walletType}</StyledTableCell>}
                                        </StyledTableRow>
                                    )):null}
                                </TableBody>
                            </Table>
                        </TableContainer> : <div style={{textAlign: 'center'}}><Headline5 fallbackText='No Data'/></div>}
                    </Modal.Body>
                </Modal>
                {!!deptLeader && <Modal size="xl" show={showOverviewModal} onHide={() => setShowOverviewModal(false)}>
                    <Modal.Header closeButton>
                        <Headline2 style={{marginRight: 16}} fallbackText={`Oversigt`}></Headline2>
                        {!!overview.length && <ExportToXlsx
                            excelData={overview.map(r => {
                                return {
                                    UID: r.userUID,
                                    KAM: r.userName,
                                    Budget: numberFormatter.format(r.budget),
                                    "Forbrug denne måned": numberFormatter.format(r.spentThisMonth),
                                    "Forbrug sidste måned": numberFormatter.format(r.spentLastMonth),
                                    "Forbrug i år": numberFormatter.format(r.spentCurrentYear),
                                    "Resterende budget": numberFormatter.format(r.remainingBudget)
                                }
                            })}
                            fileName={`${year}_kam_oversigt`}
                            buttonText={translate("_DOWNLOAD_TAX_INFO_EXPORT_BUTTON_TEXT_", "Export")}
                            fields={["KAM", "UID", "Budget", "Forbrug denne måned","Forbrug sidste måned","Forbrug i år","Resterende budget"]}
                        />}
                    </Modal.Header>
                    <Modal.Body>
                        {!!modalLoading ? <CircularIndeterminate style={{alignSelf: 'center'}}/> : 
                        !!overview.length ? <TableContainer sx={{boxShadow:'none'}} component={Paper}>
                            <Table sx={{ minWidth: 700}} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">KAM</StyledTableCell>
                                        <StyledTableCell align="left">UID</StyledTableCell>
                                        <StyledTableCell align="left">Budget</StyledTableCell>
                                        <StyledTableCell align="left">Forbrug denne måned</StyledTableCell>
                                        <StyledTableCell align="left">Forbrug sidste måned</StyledTableCell>
                                        <StyledTableCell align="left">Forbrug i år</StyledTableCell>
                                        <StyledTableCell align="left">Resterende budget</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {overview?.length ? overview.map((data,idx) => (
                                        <StyledTableRow hover key={idx}>
                                            <StyledTableCell align="left">{data.userName}</StyledTableCell>
                                            <StyledTableCell align="left">{data.userUID}</StyledTableCell>
                                            <StyledTableCell align="left">{numberFormatter.format(data.budget)}</StyledTableCell>
                                            <StyledTableCell align="left">{numberFormatter.format(data.spentThisMonth)}</StyledTableCell>
                                            <StyledTableCell align="left">{numberFormatter.format(data.spentLastMonth)}</StyledTableCell>
                                            <StyledTableCell align="left">{numberFormatter.format(data.spentCurrentYear)}</StyledTableCell>
                                            <StyledTableCell align="left">{numberFormatter.format(data.remainingBudget)}</StyledTableCell>
                                        </StyledTableRow>
                                    )):null}
                                </TableBody>
                            </Table>
                        </TableContainer> : <div style={{textAlign: 'center'}}><Headline5 fallbackText='No Data'/></div>}
                    </Modal.Body>
                </Modal>}
                <Row>
                    <Col md={!!deptLeader ? 12 : 5}>
                        {!!piData?.length ? <ResponsiveContainer width="100%" height={400}>
                            <PieChart width={400} height={300}>
                                <Pie data={piData} dataKey='value' nameKey='name'>
                                    {piData.map(data => {
                                        return <Cell key={data.name} fill={data.name === 'Forbrug' ? '#eb0001' : '#67a15f'}></Cell>
                                    })}
                                </Pie>
                                <Tooltip formatter={(value) => numberFormatter.format(value)}/>
                                <Legend  verticalAlign={!!deptLeader ? "middle" : "bottom"} align={!!deptLeader ? "right" : "center"}/>
                            </PieChart>
                        </ResponsiveContainer> : <div style={{textAlign: 'center'}}><Headline5 fallbackText='No Data'/></div>}
                    </Col>
                    <Col md={!!deptLeader ? 12 : 7} style={{marginTop: !!deptLeader ? 32 : 0}}>
                        {!!barData?.length ? <ResponsiveContainer width={'100%'} height={400}>
                            <BarChart
                            width={600}
                            height={400}
                            data={barData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 20,
                            }}>
                                <XAxis tick={{fontSize: 12}} dataKey={!!deptLeader && !KAMUserID ? 'userName' : 'month'}></XAxis>
                                <YAxis/>
                                <Tooltip formatter={(value) => numberFormatter.format(value)}/>
                                <Legend/>
                                <Bar dataKey={'Total'} fill='#1b416b'></Bar>
                                <Bar dataKey={'Forbrug'} fill='#eb0001'></Bar>
                                <Bar dataKey={'Resterende'} fill='#67a15f'></Bar>                                
                            </BarChart>
                        </ResponsiveContainer> : <div style={{textAlign: 'center'}}><Headline5 fallbackText='No Data'/></div>}
                    </Col>
                </Row>
            </>}
        </div>
    )
}