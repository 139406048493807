import React, { useContext } from 'react'
import { AppContext } from 'contexts/AppContext';
import { Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import CaretDown from 'components/icons/CaretDown';
import RoundIconButton1 from 'components/elements/RoundIconButton1';
import "./Message.css"
import ExplainerText from 'components/elements/ExplainerText';
import Headline6 from 'components/elements/Headline6';
import DateClock from '../../icons/DateClock.svg'
import ContentNormal from 'components/elements/ContentNormal';
import Headline5 from 'components/elements/Headline5';
import { translate } from 'utils/Translate';

const Message = (props) => {
    const { styleGuide } = useContext(AppContext)
    const {message} = props
    return !message ? null : (
        <Accordion id='message-block'>
            <AccordionSummary expandIcon={<RoundIconButton1 icon={<CaretDown fill="white" />} />} >
            <div className='message-summary'>
                <div className='message-summary-one'>
                    <Headline5 fallbackText={`${message.messageSubject}`} />
                    <div>
                        <img style={{width: "11px",height: "11px", marginRight: "5px" }} src={DateClock} alt="_DATE_CLOCK_" />
                        <ExplainerText fallbackText={message.date} />
                    </div>
                </div>
            </div>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, borderTop: `1px solid ${styleGuide.color4}` }} >
                <div className='message-detail-container'>
                    <ContentNormal style={{fontSize: "14px", fontWeight: "normal"}} ><div dangerouslySetInnerHTML={{__html: message.messagePlainText}}></div></ContentNormal>
                    <ContentNormal style={{fontSize: "14px", fontWeight: "normal"}} fallbackText={`${translate('_MESSAGE_RECIPIENT_', 'Modtager')}: ${message.messageRecipient} (${message.communicationChannel})`} />
                    <ContentNormal style={{fontSize: "14px", fontWeight: "normal"}} fallbackText={`${translate('_MESSAGE_SENDER', 'Afsender')}: ${message.messageSender}`} />
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default Message