import React from 'react'
import Success from 'components/icons/Success.js'

export default function RegisterCreated() {
  return (
    <div className='register-form__success' data-cy='register.sucess'>
     <div style={{margin:'auto',marginTop:'2%',alignSelf:'center',width:'100px'}}><Success/></div>
      <h4 className='mt-5 mb-3 text-center'>Tak. Din anmodning er nu sendt</h4>
      <p className='text-center'>Du modtager en email med login informationer</p>
    </div>
  )
}
