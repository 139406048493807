import React, { useContext, useEffect, useState} from "react";
import Product1 from "./Product1"
import Product2 from "./Product2"
import Product3 from "./Product3"
import { AppContext } from "contexts/AppContext";
import { Modal } from "react-bootstrap";
import ContentNormal from "components/elements/ContentNormal";
import { callAPI } from "utils/API";

function Product() {
    const { defaultTheme, brandData, basket, userData } = useContext(AppContext);
    const {enableOnePointUsers = false} = brandData?.features
    const [showBasketError, setShowBasketError] = useState(false)
    const [freightInfo, setFreightInfo] = useState(null)
    

    const checkBasketForOnePointUser = () => {
      let allow = false
      if(!enableOnePointUsers) allow = true
      if(!basket?.length && !!userData?.walletAmountPoints) allow = true
      if(!!enableOnePointUsers && !!basket?.length) {
        allow = (userData?.walletAmountPoints || 0) > (basket?.length || 0)
      }
      setShowBasketError(!allow)
      return allow
    }

    const selectProduct = {
      "ThemeONE": <Product1 checkBasketForOnePointUser={checkBasketForOnePointUser} freightInfo={freightInfo}/>,
      "ThemeTWO": <Product2 checkBasketForOnePointUser={checkBasketForOnePointUser}  freightInfo={freightInfo}/>,
      "ThemeTHREE": <Product3 checkBasketForOnePointUser={checkBasketForOnePointUser}  freightInfo={freightInfo}/>
    }

    const fetchFreightIntervals = async () => {
      const response = await callAPI('/brand/freightIntervals')
      if(response && Object.keys(response).length){
          setFreightInfo({
              enableDropshipFreightPrice: response.enableDropshipFreightPrice,
              enableFreightPriceInterval: response.enableFreightPriceInterval,
              freightIntervals: response.freightIntervals
          })
      }
    }

    useEffect(() => {
      fetchFreightIntervals()
    },[])

    return <>
      {selectProduct[defaultTheme]}
      <Modal show={showBasketError} onHide={() => setShowBasketError(false)} style={{ margin: 'auto' }} centered>
          <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }} />
          <Modal.Body className="pt-0">
              <div className='product-variations' style={{ marginTop: 20, marginBottom: 48 }}>
                <ContentNormal translationKey="_ERROR_ONEPOINT_USER_INSUFFICIENT_BALANCE_" fallbackText="You have already added items to your cart for the points you had. 
                  Please remove items from the cart to add this item." />
              </div>
          </Modal.Body>
      </Modal>
    </>
  }
  
export default Product;

