import Headline3 from "components/elements/Headline3"

const NoBrandData = (props) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
        }}>
            <Headline3
                translationKey={'_PAGE_NOT_FOUND_ERROR_MESSAGE_'}
                fallbackText={'Page not found - 404!'}
            />
        </div>
    )
}

export default NoBrandData

