export const CheckboxEmpty = (props) => {
    const pathFill = props.fill || 'white';
    const pathStroke = props.stroke || 'BFBFBF';
    const strokeWidth = props.strokeWidth || '1px';

    return <>
        <svg style={props.style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="29" height="29" rx="9.5" fill="white" />
            <rect x="0.5" y="0.5" width="29" height="29" rx="9.5" stroke="#BFBFBF" />
        </svg>
    </>
}


export const CheckboxFilled = (props) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return <>
        <svg style={props.style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="10" fill={pathFill} />
            <path d="M12.5007 18.5001L9.00065 15.0001L7.83398 16.1667L12.5007 20.8334L22.5007 10.8334L21.334 9.66675L12.5007 18.5001Z" fill="white" />
        </svg>
    </>
}