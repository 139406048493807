import Headline3 from "components/elements/Headline3";
import Image from "components/elements/Image";
import { AppContext } from "contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import "./TermsOfTrade.css";
import Headline1 from "components/elements/Headline1";
import ContentLarge from "components/elements/ContentLarge";
import { Col, Container, Row } from "react-bootstrap";
import Headline4 from "components/elements/Headline4";

import ContentNormal from "components/elements/ContentNormal";
import ContentSmall from "components/elements/ContentSmall";
import { callAPI } from "utils/API";

const TermsOfTrade = (props) => {
  const { accessibleBlogs } = useContext(AppContext);
  const [tradePosts, settradePosts] = useState([]);

  const fetchTradePosts = async () => {
    if (!accessibleBlogs.length) return []
    const BlogIDs = accessibleBlogs
      .filter(item => item.blogType === 'TRADE')
      .map((item) => item.BlogID);
    const sortBy = "poolOrder";
    const sortOrder = "ASC";
    const tradeItems = await callAPI(
      "/post/getPostsOfMultipleBlogs",
      "GET",
      { query: { BlogIDs, sortBy, sortOrder } }
    );

    if (tradeItems && tradeItems.length > 0) {
      settradePosts(tradeItems);
    }
  }

  useEffect(async () => {
    fetchTradePosts()
  }, [accessibleBlogs]);

  return (
    <>
      <div className="mt-75">
        <Col>
          <Headline1
            className="termsOfTrade-title"
            translationKey="_TERMS_OF_TRADE_"
          >
            Handelsbetingelser
          </Headline1>
        </Col>
        <Col xs={12} lg={7} className="mt-15 mb-55">
          <ContentLarge translationKey="_TERMS_OF_TRADE_CAPTION_TEXT_">
            Når du har gennemført din bestilling, håndteres din ordre af MS Branding A/S,
            som står for alle leveringer.
          </ContentLarge>
        </Col>
        {tradePosts.map((post) => {
          return (
            <Col xs={12} lg={7} className="mt-40">
              <Row>
                <Headline4>{post.elementTitle}</Headline4>
              </Row>
              <Row xs={12} lg={7} className="mt-15">
                <ContentNormal style={{ whiteSpace: "pre-line" }}>
                  {post.elementContent}
                </ContentNormal>
              </Row>
            </Col>
          );
        })}
      </div>
    </>
  );
};

export default TermsOfTrade;
