import Translate from "utils/Translate";
import { upgColors, fontFamily } from "styles/styles";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const Headline4 = (props) => {
  const { styleGuide } = useContext(AppContext)

    const headline4Style = {
      fontFamily: styleGuide.fontFamily,
      fontWeight: styleGuide.headline4FontWeight,
      fontSize: styleGuide.headline4FontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.headline4LineHeight,
      color: styleGuide.color7
    }

  
    return (
      <span onClick={props.onClick} style={{...headline4Style, ...props.style}} className={props.className} >
       { props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
      </span>
    )
}

export default Headline4;