/*
DEPRECATED - 

please use theme/styles.js and place default values inside the theme variants

*/


export const upgColors = {
    basicColor1: '#FFFFFF',
    basicColor2: '#F0F0F0',
    basicColor3: '#E8E8E8',
    basicColor4: '#BFBFBF',
    basicColor5: '#999999',
    basicColor6: '#545454',
    basicColor7: '#222222',
    systemColor1: '#28C262',
    systemColor2: '#1D8F48',
    systemColor3: '#F2D835',
    systemColor4: '#D9C130',
    systemColor5: '#FF4747',
    systemColor6: '#CC3939',
    dir1Color1: '#383693',
    dir1Color2: '#4A48C4',
    dir1Color3: '#E56B2E',
    dir1Color4: '#B25324',
    
    color1: '#FFFFFF',
    color2: '#F0F0F0',
    color3: '#E8E8E8',
    color4: '#BFBFBF',
    color5: '#999999',
    color6: '#545454',
    color7: '#222222',
    color8: '#28C262',
    color9: '#1D8F48',
    color10: '#F2D835',
    color11: '#D9C130',
    color12: '#FF4747',
    color13: '#CC3939',
    color14: '#383693',
    color15: '#4A48C4',
    color16: '#E56B2E',
    color17: '#B25324',

    white: '#FFFFFF',
    black: '#000000',
    grayLightest: '#F0F0F0',
    grayLighter: '#E8E8E8',
    grayLight: '#BFBFBF',
    gray: '#999999',
    grayDark: '#545454',
    grayDarkest: '#222222',
    green1: '#28C262',
    green2: '#1D8F48',
    yellow1: '#F2D835',
    yellow2: '#D9C130',
    red1: '#FF4747',
    red2: '#CC3939',
    violet1: '#383693',
    violet2: '#4A48C4',
    orange1: '#E56B2E',
    orange2: '#B25324',
    orange3: '#E66B2E'
};

export const fontFamily = 'Poppins';

export const textStyles = {
    headline1: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 50,
        // lineHeight: 74,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    headline2: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 40,
        // lineHeight: 64,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    headline3: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 20,
        // lineHeight: 54,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    headline4: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 16,
        // lineHeight: 44,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    headline5: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 14,
        // lineHeight: 36,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    headline6: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 12,
        // lineHeight: 28,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    caption: {
        fontFamily: fontFamily,
        fontWeight: '600',
        fontSize: 10,
        // lineHeight: 20,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    explainer: {
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 10,
        // lineHeight: 20,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    explainerTiny: {
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 9,
        // lineHeight: 18,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    contentLarge:{
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 16,
        // lineHeight: 36,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    contentNormal:{
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 14,
        // lineHeight: 28,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    contentSmall:{
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 10,
        // lineHeight: 24,
        fontStyle: 'normal',
        color: upgColors.basicColor7
    },
    quoteLarge: {
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 24,
        // lineHeight: 36,
        fontStyle: 'italic',
        color: upgColors.basicColor7
    },
    quoteNormal: {
        fontFamily: fontFamily,
        fontWeight: '400',
        fontSize: 18,
        // lineHeight: 28,
        fontStyle: 'italic',
        color: upgColors.basicColor7
    },
};