import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import { AppContext } from "contexts/AppContext";
import Headline5 from "components/elements/Headline5";
import { useNavigate } from "react-router-dom";
import './Footer.css'
import Activity from "utils/Activity";
import ContentNormal from "components/elements/ContentNormal";

export const getPersonalShopperAliases = (aliasMap) => {
  const path = "/personalShopper"
  if(!aliasMap?.length) return path

  const psAlias = aliasMap?.filter(am => am.path === path).map(am => am?.alias)
  if(!psAlias?.length) return path

  if(psAlias[0]?.length > 1) {
    return "/"+psAlias[0][0]
  } 
  return "/"+psAlias[0]
}

const PersonalShopper = () => {
  const { styleGuide,brandData } = useContext(AppContext);
  const navigate = useNavigate()
  const textColor = brandData?.features?.personalShopperTextColorBlack ? '#000' : styleGuide.color1
  const {aliasMap} = brandData?.features

  return (
    <>
      <Row
        style={{
          backgroundColor:styleGuide.color14,
          color: styleGuide.color1,
        }}
        className="footerTop"
      >
        <Col md={6}>
          <Row
            style={{
              marginBottom: 20,
            }}
          >
            <Headline5 style={{ color: textColor }} translationKey="_PERSONAL_SHOPPER_CAPTION_">
              Få hjælp af vores Personal Shopper
            </Headline5>
          </Row>
          <Row>
            <ContentNormal
              translationKey="_PERSONAL_SHOPPER_TEXT_"
              style={{ color: textColor }}
            >
              Hvis du søger et bestemt produkt, eller der er noget du ikke kan
              finde, kan vi bestille det til dig
            </ContentNormal>
          </Row>
        </Col>
        <Col
          md={6}
          style={{
            display: "flex",
            justifyContent : "flex-end",
            alignItems: "flex-end"
          }}
          className="footerBtnCol"
        >
          <ButtonLarge1
            style={{ fontFamily: styleGuide.secondaryFontFamily }}
            onClick={() => {
              Activity({activityType: 'click',activityName: 'footer',activityArgument: 'PersonalShopper',activityArgumentID: ''})
              navigate(getPersonalShopperAliases(aliasMap))}}
            fallbackText="Kontakt os"
            translationKey="_CONTACT_US_"
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalShopper;
