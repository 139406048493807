import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { callAPI } from 'utils/API';
import { AppContext } from 'contexts/AppContext';
import CircularIndeterminate from 'components/blocks/progressBar/progressBar';

export default function ShadowUser (){

    const location = useLocation();
    const { setUserData } = useContext(AppContext);
    const token = location.search ? new URLSearchParams(location.search).get('token') : null
    const [user, setUser] = useState(null)
    const [redirectLogin, setRedirectLogin] = useState(false)    

    const validateSession = useCallback(async (idToken) => {
        try {
            const sessionResponse = await callAPI(`/sessions/validate`, 'POST', {
                body: {
                    token,
                    deviceTimezone: Date().toString().match(/\((.*)\)/).pop(),
                    deviceLanguage: window.navigator.language,
                }
            });

            if (sessionResponse && sessionResponse.accessToken) {
                localStorage.clear();
                localStorage.setItem('accessToken', sessionResponse.accessToken);
                setUserData(sessionResponse.user);
                setUser(sessionResponse.user);
            } else {
                localStorage.clear();
                setUserData(null);
                setRedirectLogin(true);
            }
        } catch (error) {
            console.error('Session validation failed:', error);
            setRedirectLogin(true);
        }
    }, [token, setUserData])

    useEffect(() => {
        validateSession()
    }, [validateSession])

    return redirectLogin ? (
        <Navigate to={'/login'} replace={true} />
    ) : user && user.UserID ? (
        <Navigate to={'/'} replace={true} />
    ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3rem' }}>
            <CircularIndeterminate size={100} />
        </div>
    )
}