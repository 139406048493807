import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "contexts/AppContext";
import Image from "components/elements/Image";
import Headline3 from "components/elements/Headline3";
import "./ChristmasCalendarDetail.css";
import { callAPI } from "utils/API";
import { useParams } from "react-router-dom";
import { parseLinks } from "utils/general";
import ContentNormal from "components/elements/ContentNormal";
import ChristmasCalendarDate from "./ChristmasCalendarDate";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import 'moment/min/locales'

const ChristmasCalendarDetail = () => {
  const contextState = useContext(AppContext);
  const { styleGuide, defaultTheme } = contextState;
  const { CompetitionID } = useParams();
  const [productSlice, setProductSlice] = useState(2);
  const [christmasCalendarDetails, setChristmasCalendarDetails] = useState({});
  const [winners, setWinners] = useState([])

  useEffect(() => {
    fetchCalendarDetails();
  }, []);

  const fetchCalendarDetails = async () => {
    const details = await callAPI(`/competition/${CompetitionID}`);
    addCords(details);
  };
  
  const getWinners = async(christmasDays) => {
    const winners = await callAPI(`/christmasCalendar/getWinners`, 'GET', {query: {CompetitionID}})

    let tempWinners = !!winners && !!winners.length && winners.map((winner) => {
      !!christmasDays &&
      !!christmasDays.length &&
      christmasDays.forEach(element => {
        if(element.dayNumber === winner.dayNumber) {
          winner.date = element.date
        }
      });
      return winner
    })
    setWinners(tempWinners)
  }

  let daysCoords = [
    { coords: { left: 22, top: 50 } },
    { coords: { right: 10, top: 20 } },
    { coords: { right: 25, bottom: 25 } },
    { coords: { left: 8, bottom: 12 } },
    { coords: { right: 40, top: 35 } },
    { coords: { left: 15, top: 29 } },
    { coords: { left: 25, bottom: 6 } },
    { coords: { right: 46, bottom: 24 } },
    { coords: { right: 6, bottom: 20 } },
    { coords: { right: 18, bottom: 6 } },
    { coords: { right: 24, top: 8 } },
    { coords: { left: 40, top: 16 } },
    { coords: { right: 16, top: 45 } },
    { coords: { left: 40, bottom: 6 } },
    { coords: { left: 25, top: 8 } },
    { coords: { left: 8, top: 50 } },
    { coords: { left: 29, top: 32 } },
    { coords: { right: 35, bottom: 10 } },
    { coords: { left: 5, top: 8 } },
    { coords: { left: 35, bottom: 30 } },
    { coords: { right: 5, top: 39 } },
    { coords: { left: 42, top: 40 } },
    { coords: { right: 37, top: 10 } },
    { coords: { right: 28, top: 45 } },
  ];

  const addCords = (christmasCalendarDetails) => {
    let startDay = Number(
      moment(christmasCalendarDetails.startDate).format("D")
    );
    let month = Number(moment(christmasCalendarDetails.startDate).format("MM"));
    let year = Number(
      moment(christmasCalendarDetails.startDate).format("YYYY")
    );
    let tempDays =
      !!christmasCalendarDetails &&
      !!christmasCalendarDetails.christmasDays &&
      !!christmasCalendarDetails.christmasDays.length &&
      christmasCalendarDetails.christmasDays.map((day, inx) => {

        let tempDay = { ...day, ...daysCoords[inx], date: `${month}-${startDay}-${year}`};
        
        if (month === 4 || month === 6 || month === 9 || month === 11) {
          if (startDay === 30) {
            startDay = 1;
            month++;
          } else {
            startDay++;
          }
        } else if (month === 2) {
          if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
            if (startDay === 29) {
              startDay = 1;
              month++;
            } else {
              startDay++;
            }
          } else {
            if (startDay === 28) {
              startDay = 1;
              month++;
            } else {
              startDay++;
            }
          }
        } else if (month === 12) {
          if (startDay === 31) {
            startDay = 1;
            month = 1;
            year++;
          } else {
            startDay++;
          }
        } else {
          if (startDay === 31) {
            startDay = 1;
            month++;
          } else {
            startDay++;
          }
        }
        return tempDay;
      });
    setChristmasCalendarDetails({
      ...christmasCalendarDetails,
      christmasDays: tempDays,
    });
    getWinners(tempDays)
  };

  let [active, setActive] = useState(null);

  const getCompetitionDetailDivHeight = (element) => {
    if (element && element.clientHeight > 900) {
      const NoOfProducts = Number(Math.round(element.clientHeight / 400));
      setProductSlice(NoOfProducts > 4 ? 4 : NoOfProducts);
    }
  };

  const bannerHeight = 700;
  const christmasCalendarBannerImage =
    (christmasCalendarDetails && christmasCalendarDetails.imgUrl) ||
    "https://i.imgur.com/NPB3IhS.png";
  const christmasCalendarTitle = (christmasCalendarDetails && christmasCalendarDetails.title) || "";
  const christmasCalendarDescription =
    christmasCalendarDetails.detailDescription ||
    "Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium, illum doloribus. Corrupti aut sint praesentium mollitia, nihil aperiam alias fugiat inventore.";
  const christmasCalendarTextColor =
    christmasCalendarDetails.imageTextColor === "black"
      ? styleGuide.color18
      : styleGuide.color1;

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={`${defaultTheme}christmas_calendar_wrapper`}>
          {!!christmasCalendarBannerImage ? (
              <Image
                src={`url(${christmasCalendarBannerImage})`}
                style={{ backgroundSize: "100% 100%", minHeight: bannerHeight }}
              ></Image>
          ) : null}
        </div>
        {christmasCalendarDetails && christmasCalendarDetails.title ? (
          <Headline3
            style={{ fontSize: 40, lineHeight: "60px" }}
            className={`${defaultTheme}christmas_calendar_title`}
            translationKey="_CHRISTMAS_CALENDAR_TITILE_"
            fallbackText={christmasCalendarTitle}
          />
        ) : null}
        <div style={{ display: "flex", flexDirection: "row", marginTop: 40 }}>
          <div className="christmas_calendar-content" style={{ display: "flex", display: "block" }}>
            <ContentNormal>
              <div ref={getCompetitionDetailDivHeight}>
                {parseLinks(christmasCalendarDescription)}
              </div>
            </ContentNormal>
          </div>
        </div>
        <Image
          src={`url(${christmasCalendarDetails.christmasImgUrl})`}
          style={{ width: "100%", objectFit: "cover", position: "relative", marginTop: 60, marginBottom: 100}}
          className="calendarImage"
        >
          {!!christmasCalendarDetails.christmasImgUrl &&
            !!christmasCalendarDetails.christmasDays &&
            !!christmasCalendarDetails.christmasDays.length &&
            christmasCalendarDetails.christmasDays.map((day, inx) => {
              return (
                <ChristmasCalendarDate key={inx} day={day} active={active} setActive={setActive} christmasCalendarTextColor={christmasCalendarTextColor} />
              );
            })}
        </Image>
        {!!winners && !!winners.length && <Headline3
          translationKey="_CHRISTMAS_CALENDAR_WINNERS_HEADING_"
          fallbackText="Vindere"
        />}
        {
          !!winners && !!winners.length &&  winners.map((winner, index) => {
            return <WinnerElement key={index} index={index} data={winner} />
          })
        }
      </div>
    </>
  );
};

export const WinnerElement = ({data, index}) => {
  const { userName, date, userCompany } = data;
  const { styleGuide } = useContext(AppContext);

  return (
      <Row className="wg-row bg-col" style={{ padding: "15px", backgroundColor: index % 2 === 1 ? styleGuide.color2 : styleGuide.color1 }}>
          <Col md="2" className="text-right align-middle pt-3">
              {date && moment(date).locale("da").format("D MMMM YYYY")}
          </Col>
          <Col className="text-left pt-3">{`${userName}${userCompany && ','} ${userCompany}`}</Col>
      </Row>
  );
};


export default ChristmasCalendarDetail
