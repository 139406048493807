import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'contexts/AppContext';
import { createUseStyles } from 'react-jss';
import { FormHelperText } from '@mui/material';
import CaretDown from 'components/icons/CaretDown';
import './css/InputStyles.css'

const jss = createUseStyles({
    labelStyle : {
        color: (styleGuide) => styleGuide.color6,
        "&.Mui-focused":{
            color: (styleGuide) => styleGuide.color6
        }
    },
    wrapperStyle  : {
        "&.Mui-focused":{
            backgroundColor: 'white'
        },
        "#select-multiple-checkbox":{
            "&:focus":{
                backgroundColor: 'white !important'
            },
        }
    }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultipleSelectCheckmarks = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const defaultUnderlineColor = props.value && props.value.length > 0 ? styleGuide.color7 : styleGuide.color4
    const selectStyle = {
        backgroundColor: props.disabled ? styleGuide.color3 : `transparent`,
        "&:hover:before": {
            borderBottom : `2pt solid ${defaultUnderlineColor} !important`,
        },
        "&:hover:after": {
            borderBottom : `2pt solid ${styleGuide.color16} !important`,
        },
        "&:before": {
            borderBottom : `2pt solid ${defaultUnderlineColor} !important`,
        },
        "&:after": {
            borderBottom : `2pt solid ${styleGuide.color16} !important`,
        },
    }

    const menuItemStyle = {
        ".MuiPaper-root": {
            borderRadius : `${styleGuide.selectDropdownBorderRadius}`
        },
        "ul":{
            borderRadius: 0,
            padding: '0 0 0 0'
        },
        "li:hover":{
            backgroundColor: `${styleGuide.color16} !important`,
            color: `${styleGuide.color1}`,
            ".MuiCheckbox-root":{
                background: styleGuide.color16,
                color: 'white'
            }
        },
    }

    const labelStyle = {
        zIndex: props.disabled ? 1 : `inherit`,
        color: styleGuide.color6,
        "&.Mui-focused":{
            color: styleGuide.color7
        }
    }

    const helpTextStyle = {
        marginLeft: '15px',
        backgroundColor: 'white'
    }
    const helpText = props.helpObj && props.helpObj.helpText ? props.helpObj.helpText : ''
    const errorText = props.helpObj && props.helpObj.errorText ? props.helpObj.errorText : ''
    
    const options = props.options || []
    const [currentValue, setCurrentValue] = useState(props.value);

    useEffect(() => {
        setCurrentValue(props.value)
    }, [props.value])
    const emptyRenderValue = () => ''
    return (
        <div style={props.wrapperStyle} className={classes.wrapperStyle} >
            <FormControl sx={{ m: 0, width: '100%' }} hiddenLabel={false} >
                <InputLabel className={classes.labelStyle} style={labelStyle} id="select-label">{props.label || ''}</InputLabel>
                <Select
                variant='standard'
                labelId="select-label"
                id="select-multiple-checkbox"
                multiple
                value={currentValue}
                onChange={props.onChange}
                style={props.style}
                className={props.className}
                sx={selectStyle}
                renderValue={props.renderValue || emptyRenderValue}
                MenuProps={
                    {
                        PaperProps: {
                            sx: {...menuItemStyle,
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                    scrollbarWidth: 'thin',
                                    '&::-webkit-scrollbar': {
                                        display:'inline-block',
                                        width: '0.4vw',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#F0F0F0',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        borderRadius: 3,
                                        backgroundColor: '#A9A9A9',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#C1C1C1',
                                    },
                                },
                            },
                        variant: 'menu',
                }}
            >
                    {props.emptyValue 
                        ? <MenuItem disabled={true} value={''}>{props.emptyValue.text || ''} </MenuItem> 
                        : null
                    }
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox 
                                checked={currentValue.indexOf(option) > -1} 
                                sx={{
                                    color: styleGuide.color5,
                                    '&.Mui-checked': {
                                        '&:hover':{
                                            background: styleGuide.color16,
                                            color: 'white'
                                        },
                                    color: styleGuide.color17,
                                    },
                                }}
                            />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
                {!props.disabled && <FormHelperText id="helper-text" style={helpTextStyle}>{ props.error ? errorText : helpText}</FormHelperText>}
            </FormControl>
        </div>
    );
}

export default MultipleSelectCheckmarks;