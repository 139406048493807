import Headline2 from 'components/elements/Headline2';
import { AppContext } from 'contexts/AppContext';
import React, { useContext } from 'react'

function StatusSection(props) {
  const { themeConfig } = useContext(AppContext);
  return (
    <div id={props?.id || ""} ref={props.statusRef} style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 100, marginBottom: themeConfig.disableWishlist ? 350 : 100, minHeight: 600 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', zIndex: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Headline2 translationKey='_PROFILE_PAGE_MY_STATUS_' fallbackText='Din status' style={{ marginTop: 16 }} />
            </div>
            <div style={{ display: 'flex', padding: 8, flexDirection: 'row' }}>    
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-around', width: "100%", marginTop: 32, position: 'relative' }}>
        </div>
    </div>
  )
}

export default StatusSection