
const Lock = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';
    return (
        <svg style={props.style} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 5.33333H9.75V3.80952C9.75 1.70667 8.07 0 6 0C3.93 0 2.25 1.70667 2.25 3.80952V5.33333H1.5C0.675 5.33333 0 6.01905 0 6.85714V14.4762C0 15.3143 0.675 16 1.5 16H10.5C11.325 16 12 15.3143 12 14.4762V6.85714C12 6.01905 11.325 5.33333 10.5 5.33333ZM6 12.1905C5.175 12.1905 4.5 11.5048 4.5 10.6667C4.5 9.82857 5.175 9.14286 6 9.14286C6.825 9.14286 7.5 9.82857 7.5 10.6667C7.5 11.5048 6.825 12.1905 6 12.1905ZM8.325 5.33333H3.675V3.80952C3.675 2.50667 4.7175 1.44762 6 1.44762C7.2825 1.44762 8.325 2.50667 8.325 3.80952V5.33333Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    )
}
export default Lock

