const FavouriteBorder = (props) => {
  const pathFill = props.fill || "black";
  const pathStroke = props.stroke || "inherit";
  const strokeWidth = props.strokeWidth || "1px";

  return (
    <svg
      style={props.style}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 6.25C17.76 6.25 16.09 7.02248 15 8.24319C13.91 7.02248 12.24 6.25 10.5 6.25C7.42 6.25 5 8.5579 5 11.4952C5 15.1001 8.4 18.0375 13.55 22.5007L15 23.75L16.45 22.4911C21.6 18.0375 25 15.1001 25 11.4952C25 8.5579 22.58 6.25 19.5 6.25ZM15.1 21.0797L15 21.1751L14.9 21.0797C10.14 16.9693 7 14.2514 7 11.4952C7 9.58787 8.5 8.15736 10.5 8.15736C12.04 8.15736 13.54 9.1015 14.07 10.408H15.94C16.46 9.1015 17.96 8.15736 19.5 8.15736C21.5 8.15736 23 9.58787 23 11.4952C23 14.2514 19.86 16.9693 15.1 21.0797Z"
        fill={pathFill}
        stroke={pathStroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
export default FavouriteBorder;
