import { AppContext } from 'contexts/AppContext'
import React, { useContext, useEffect, useState } from 'react'
import Image from "components/elements/Image";
import { useParams } from 'react-router-dom'
import { callAPI } from 'utils/API'
import './competitionDetails.css'
import ContentNormal from 'components/elements/ContentNormal';
import { parseLinks } from 'utils/general';
import Headline4 from 'components/elements/Headline4';
import ProductNormal from 'components/elements/ProductNormal';
import Translate, { translate } from 'utils/Translate';
import Headline3 from 'components/elements/Headline3';
import ButtonLarge1 from 'components/elements/ButtonLarge1';
import { Modal } from 'react-bootstrap';
import Headline6 from 'components/elements/Headline6';
import ButtonMedium1 from 'components/elements/ButtonMedium1';
import ExplainerText from 'components/elements/ExplainerText';

const CompetitionDetails = (props) => {
  const { CompetitionID } = useParams()
  const [competitionDetails, setCompetitionDetails] = useState({})
  const { styleGuide, defaultTheme} = useContext(AppContext);
  const [productSlice,setProductSlice] = useState(2);
  const [showError, setShowError] = useState(false);
  const [showThankModal, setShowThankModal] = useState(false);
  const [showGdprText, setShowGdprText] = useState(false)
  const [consent, setConsent] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);
  
  useEffect(async ()=>{
    const details = await callAPI(`/competition/${CompetitionID}`)
    setCompetitionDetails(details)
  },[])

  useEffect(() => {
    if (!!errorMessage) return setShowError(true)
    setShowError(false)
  }, [errorMessage])
  
  const getCompetitionDetailDivHeight = (element) => {
    if (element && element.clientHeight > 900) {
      const NoOfProducts = Number(Math.round(element.clientHeight/400))
      setProductSlice(NoOfProducts> 4 ? 4 : NoOfProducts);
    }
  };
  
  const participate = async () => {
    setShowGdprText(false)
    const clickParticipate = await callAPI(`/competitions/${CompetitionID}/participate`, "POST")
    if(clickParticipate.error === "MAX_ENTRIES_REACHED") {
        return seterrorMessage(<Translate translationKey='MAX_ENTRIES_REACHED'>Du kan ikke deltage flere gange</Translate>)
    } else if(clickParticipate && clickParticipate.protocol41) {
      setShowThankModal(true)
    }
  }
   
  const gdprModal = () => {
    setShowGdprText(true)
  }
  
  const updateConsent = async (e) => {
    if (e.target.checked) {
      participate()
    }
    setConsent(e.target.checked);
  }


  const imageUrl = competitionDetails && competitionDetails.imgUrl
  const competitionTitle = competitionDetails && competitionDetails.title || ""
  const bannerHeight = 700;
  const competitionContent = competitionDetails && competitionDetails.detailDescription || ""
  const productDetails = competitionDetails && competitionDetails.productDetail || ""

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={`${defaultTheme}competition_wrapper`}>
          { 
          (!!imageUrl)
            ? <>
              <Image src={`url(${imageUrl})`} style={{
                backgroundSize: "100% 100%",
                minHeight:bannerHeight,
              }}
              >
              </Image>
            </>
            : null
          }
        </div>
        { competitionDetails && competitionDetails.title ? <Headline3 style={{ fontSize: 40, lineHeight: '60px' }} className={`${defaultTheme}competition_title`} translationKey="_COMPETITION_TITILE_" fallbackText={competitionTitle }/> : null} 
        <div style={{display:'flex', flexDirection: 'row', marginTop: 40}}>
          <div className="competition-content" style={{display:'flex', display: 'block',}}>
            <ContentNormal><div ref={getCompetitionDetailDivHeight}>{parseLinks(competitionContent)}</div></ContentNormal> 
            <ButtonLarge1 style={{maxWidth: 'max-content', marginTop: 50}} translationKey="_PARTICIPATE_COMPETITION_" showIcon={false} onClick={gdprModal} fallbackText="Deltag i konkurrencen"/>
          </div>
          {!!productDetails && (
            <>
              <div className="prize-products" style={{flexDirection: 'column', gap:40, flex:"0.5 1 0%", alignItems:"flex-end" }}>
                <Headline4 translationKey="_PRIZE_PRODUCTS_" fallbackText="Præmie produkt" />
                <ProductNormal             
                  productID={productDetails.ProductID}
                  tagStyles={{borderRadius: 8}}
                  liked={false}
                  className="productlarge"
                  style={{ boxShadow: `0px 0px 100px rgba(34, 34, 34, 0.15)` }}
                  text2={productDetails.elementTitle + (!!productDetails.enableMulti ? ', ' + productDetails.minCount + ' stk' : '')}
                  text1={productDetails.manufacturer}
                  wishlistCallback={() => alert("you LIKED this Product!")}
                  imageSrc={`url(${productDetails.imgUrl})`}
                  activityName='competition'
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Modal show={showError} onHide={() => setShowError(false)} style={{ margin: 'auto' }} centered>
          <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
              <Headline6 style={{ color: styleGuide.color13 }} translationKey="_ERROR_">Fejl</Headline6>
          </Modal.Header>
          <Modal.Body className="pt-4 pb-4" style={{justifyContent: "center", display: "flex"}}>
              <ExplainerText fallbackText={errorMessage} translationKey="_MAX_LIMIT_ERROR_TEXT_"></ExplainerText>
          </Modal.Body>
      </Modal>
      {competitionDetails && competitionDetails.gdprText && <Modal show={showGdprText} onHide={() => setShowGdprText(false)} style={{ margin: 'auto' }} centered>
          <Modal.Body className="pt-4 pb-4" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <p style={{ textAlign: 'left',fontFamily: styleGuide.secondaryFontFamily,fontWeight: 400, fontSize: '15px' }} translationKey="_GDPR_TEXT_">
            <input
              type='checkbox'
              value={consent}
              onClick={(e) => updateConsent(e)}
              style={{ marginRight: '5px' }}/>
            {competitionDetails.gdprText}
          </p>
          </Modal.Body>
      </Modal>}
      <Modal show={showThankModal} onHide={() => setShowThankModal(false)} style={{ margin: 'auto' }} centered>
          <Modal.Body className="pt-4 pb-4" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <p style={{ textAlign: 'center' }} translationKey="_THANK_YOU_FOR_PARTICIPATE_TEXT_">Tak for din deltagelse i konkurrencen</p>
              <ButtonMedium1 style={{maxWidth: 'max-content'}} showIcon={false} onClick={()=> setShowThankModal(false)}>{translate("_CLOSE_THANK_YOU_MODAL_", "Luk")}</ButtonMedium1>
          </Modal.Body>
      </Modal>
    </>
  )
}

export default CompetitionDetails