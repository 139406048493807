import { AppContext } from 'contexts/AppContext';
import React, { useContext } from 'react'
import ReactCountryFlag from 'react-country-flag'

function LanguageSelector() {
  const { langCode, brandData, styleGuide, setLangCode } = useContext(AppContext);
  const clickHandler = (langCode) => {
    setLangCode(langCode)
    localStorage.setItem("langCode", langCode || "");
  }
  return (
    <div style={{display: 'flex', flexDirection: 'row', gap: 5, position: 'absolute', top: 10, right: 0, marginRight: 20}}>
        {!!brandData && !!brandData.brandLanguages.length && brandData.brandLanguages.length > 1 &&
        brandData.brandLanguages.map((language) => {
            return <>
                <div onClick={() => clickHandler(language?.languageKey)} style={{padding: 5, borderBottom: 'solid 2px', borderBottomColor: langCode === language?.languageKey ? styleGuide.color16 : styleGuide.color1, cursor: 'pointer'}}>
                    <ReactCountryFlag
                        countryCode={language?.languageKey === "en" ? "GB" : language?.languageKey}
                        svg
                        style={{
                            width: '30px',
                            height: '20px',
                        }}
                    />
                </div>
            </>
        })}
    </div>
  )
}

export default LanguageSelector