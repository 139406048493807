const ArrowLeft = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg style={props.style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    )
}
export default ArrowLeft
