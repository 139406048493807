import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const CaptionText = (props) => {
    const { styleGuide } = useContext(AppContext)
    const captionTextStyle = {
      fontFamily: styleGuide.secondaryFontFamily,
      fontWeight: styleGuide.captionFontWeight,
      fontSize: styleGuide.captionFontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.captionLineHeight,
      color: styleGuide.color7
    }
  
    return (
      <span title={ props.title } onClick={props.onClick} style={{...captionTextStyle, ...props.style}} className={props.className} >
        {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
      </span>
    )
}

export default CaptionText;