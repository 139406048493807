import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { translate } from "utils/Translate";
import Facebook from "../../icons/Facebook";
import X from "../../icons/X";
import Instagram from "../../icons/Instagram";
import LinkedIn from "../../icons/Linkedin";
import { AppContext } from "contexts/AppContext";
import PersonalShopper, { getPersonalShopperAliases } from "./personalShopper";
import BasketFaqBlock from "./BasketFaqBlock";
import ExplainerTextTiny from "components/elements/ExplainerTextTiny";
import Headline6 from "components/elements/Headline6";
import Activity from "utils/Activity";
import moment from "moment";

const Footer = () => {
  const { brandData, styleGuide, menuData, themeConfig, setPointstatus, langCode } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {aliasMap} = brandData?.features
  const FooterPosition = {
    display: "flex",
    flexDirection: "column",
    marginTop: '100px',
    padding: 0
  };
  
  const FooterBottom = {
    backgroundColor: brandData?.features?.footerBgColor || styleGuide.color7,
    color: brandData?.features?.footerTextColor || styleGuide.color1,
  };
  
  const FooterList = {
    listStyle: "none",
  };
  
  const FooterLink = {
    cursor: "pointer",
    color: brandData?.features?.footerTextColor || styleGuide.color1
  };

  const isStarkBrand = brandData && brandData.BrandID === 87;

  const Divider = () => <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch', height: 1, backgroundColor: styleGuide.color6 }} />

  return (
    // <footer style={FooterPosition}>
    <Container fluid style={FooterPosition} id="footer">
      {
        ['/basket'].includes(location.pathname)
        ? <BasketFaqBlock />
        : location.pathname === '/personalShopper' || location.pathname === getPersonalShopperAliases(aliasMap)
          ? null
          : brandData && brandData.features && brandData.features.personalshopper && <PersonalShopper />
      }
      <Divider />
      <Row style={FooterBottom} className="footerBottomMain">
        <div className="footerBottom">
          <div className="footermenulist">
            <div>
              <Headline6
                  style={{ ...FooterList, color: brandData?.features?.footerTextColor || styleGuide.color1 }}
                >
                {
                  menuData &&
                  !!menuData.length &&
                  menuData.map((e, i) => {
                    if((e.poolName && e.poolName.toLowerCase() === 'shop') && themeConfig.disableShop) return
                    if (e.menuTitle == 'FAQ') return
                    const decideLink = (e) => {
                      if(e.poolName && e.poolName.toLowerCase() === 'blog') return `/posts`
                      let url = `/${e.poolName.toLowerCase()}`;
                      if (!e.pool || e.pool.length === 0) return url;

                      const key = Object.keys(e.pool[0]).find(
                        (x) =>
                          x.toLowerCase() === `${e.poolName}ID`.toLowerCase()
                      );
                      let menuUrl = `${url}/${e.pool[0][key]}`;
                      return menuUrl;
                    };

                    return (
                      <li
                        key={i}
                        style={FooterLink}
                        onClick={() => {
                          navigate(`${decideLink(e)}`);
                        }}
                      >
                        {
                          !!e && !!e.translations && !!e.translations.length > 0
                          ? e.translations.find(
                                  (translation) => translation.languageKey === langCode
                          ) 
                          ? e.translations.find(
                              (translation) => translation.languageKey === langCode
                          ).menuTitle
                              : e.menuTitle
                          : e.menuTitle
                        }
                      </li>
                    );
                  })
                }
                <li style={FooterLink} onClick={() => {
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'FAQ',activityArgumentID: ''})
                  navigate('/help', {state:{scrollToFaq:true}})}}>
                  FAQ
                </li>
                <li style={FooterLink} onClick={() => {
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'Contact',activityArgumentID: ''})
                  navigate('/help', {state:{scrollToContact:true}})}}>
                  {translate('_CONTACT_', 'Kontakt')}
                </li>
              </Headline6>
            </div>
            <div>
              <Headline6
                style={{ ...FooterList, color: brandData?.features?.footerTextColor || styleGuide.color1 }}
              >
                {!isStarkBrand && brandData && brandData.features && brandData.features.points === false ? null : (!themeConfig.disablePoints && <li style={FooterLink} onClick={() => {
                  setPointstatus(true);
                  let state = isStarkBrand ? {scollToPoints:true} : {scollToPoints:true} 
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'Points',activityArgumentID: ''})
                  navigate('/profile',{state:state})
                  }}>
                  {
                    translate('_FOOTER_LINK_MY_POINTS_', 'Min Profil / Point')
                  }
                </li>)}
                {!!brandData?.features?.enableWalletActivation && <li style={FooterLink} onClick={() => {
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'ActivationSales',activityArgumentID: ''})
                  navigate('/profile',{state:{scrollToActivationSales:true}})}}>
                  {translate('_FOOTER_LINK_MY_ACTIVATION_SALES_', 'Aktivering')}
                </li>}
                {!themeConfig.disableWishlist && <li style={FooterLink} onClick={() => {
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'Wishlist',activityArgumentID: ''})
                  navigate('/wishlist', {state:{scrollTowishlist:true}})}}>
                  {translate('_FOOTER_LINK_MY_WISHLISTS_', 'Ønskeliste')}
                </li>}
                {themeConfig.enableProfileStatusBlock && <li style={FooterLink} onClick={() => {
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'Status',activityArgumentID: ''})
                  navigate('/profile', {state:{scrollToStatus:true}})}}>
                  {translate('_FOOTER_LINK_MY_STATUS_', 'Din status')}
                </li>}
                {isStarkBrand && <li style={FooterLink} onClick={() => {
                  Activity({activityType: 'click',activityName: 'footer',activityArgument: 'TermsAndCondition',activityArgumentID: ''})
                  window.open('https://www.stark.dk/Om-Stark/PointShop-MedlemsBetingelser', '_blank');
                  }}>
                  Vilkår og betingelser
                </li>}
              </Headline6>  
            </div>
          </div>
          <div className="socialiconfooter">
            <div style={{display: "flex", flexDirection: "row", gap: 20}} className="iconItem" >
              {brandData && brandData.features && brandData.features.facebookURL && <div
                  style={FooterLink}
                >
                <a href={brandData.features.facebookURL} target="_blank"><Facebook fill={brandData?.features?.footerTextColor || styleGuide.color1}/></a>
              </div>}
              {brandData && brandData.features && brandData.features.twitterURL &&<div
                style={FooterLink}
              >
                <a href={brandData.features.twitterURL} target="_blank"><X fill={brandData?.features?.footerTextColor || styleGuide.color1}/></a>
              </div>}
              {brandData && brandData.features && brandData.features.instagramURL &&<div
                style={FooterLink}
              >
                <a href={brandData.features.instagramURL} target="_blank"><Instagram fill={brandData?.features?.footerTextColor || styleGuide.color1}/></a>
              </div>}
              {brandData && brandData.features && brandData.features.linkedInURL &&
              <div
                style={FooterLink}
              >
                <a href={brandData.features.linkedInURL} target="_blank"><LinkedIn fill={brandData?.features?.footerTextColor || styleGuide.color1}/></a>
              </div>}
            </div>
            <div>
              <ExplainerTextTiny fallbackText={`© ${moment().format("YYYY")} Copyright: Upgrader`} style={{ color: brandData?.features?.footerTextColor || styleGuide.color1, marginTop: 20,}} />
            </div>
          </div>
        </div>
      </Row>
    </Container>
    // </footer>
  );
};

export default Footer;
