import React, { useContext} from "react";
import Warning from 'components/icons/Warning.js'
import { AppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";

export default function UnderMaintenance() {
  const { styleGuide, brandData } = useContext(AppContext);
  const navigate = useNavigate()
  const isDownForMaintenance = brandData && brandData.features && brandData.features.isDownForMaintenance;
  const shutDownAllBrands = brandData && brandData.features && brandData.features.shutDownAllBrands;
  if (!shutDownAllBrands && !isDownForMaintenance) {
    navigate('/login')
  }
  
  return (
    <div>
      <div style={{display: "flex", width: "130px", height: "45px",margin:'auto',marginTop:'7%',marginBottom:'1%',justifyContent:'center' }}>
          <img src={brandData && brandData.brandLogo ? brandData.brandLogo:''}/>
        </div>
     <div style={{margin:'auto',marginTop:'2%',alignSelf:'center',width:'100px'}}><Warning/></div>
      <h3 className='mt-5 mb-3 text-center' style={{fontFamily: styleGuide.secondaryFontFamily }}>UNDER VEDLIGEHOLDELSE</h3>
      <p className='text-center' style={{fontFamily: styleGuide.secondaryFontFamily }}> Klubben er midlertidigt lukket på grund af vedligeholdelse</p>
    </div>
  )
}
