import ButtonLarge1 from "components/elements/ButtonLarge1";
import Translate, { translate } from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { createUseStyles } from "react-jss";
import { useContext } from "react";
import CustomCarousel from "../customCarousel/CustomCarousel";
import "./HomePageBanner.css"
import ButtonLarge3 from "components/elements/ButtonLarge3";

const jss = createUseStyles({
    bannerTitle: {
        fontWeight: (styleGuide) => styleGuide.headline1FontWeight,
        fontSize: (styleGuide) => styleGuide.headline1FontSize,
        lineHeight: (styleGuide) => styleGuide.headline1LineHeight
    },
    bannerContent: {
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        fontWeight: (styleGuide) => styleGuide.contentNormalFontWeight,
        fontSize: (styleGuide) => styleGuide.contentNormalFontSize,
        lineHeight: (styleGuide) => styleGuide.contentNormalLineHeight,
    },
    "@media screen and (min-width: 700px) and (max-width:1023px)": {
    bannerTitle: {
        fontWeight: (styleGuide) => styleGuide.headline2FontWeight,
        fontSize: (styleGuide) => styleGuide.headline2FontSize,
        lineHeight: (styleGuide) => styleGuide.headline2LineHeight
    }
    },
    "@media screen and (max-width:700px)": {
    bannerTitle: {
        fontWeight: (styleGuide) => styleGuide.headline3FontWeight,
        fontSize: (styleGuide) => styleGuide.headline3FontSize,
        lineHeight: (styleGuide) => styleGuide.headline3LineHeight
    }
    }
});

const HomePageBanner1 = (props) => {
    const { styleGuide } = useContext(AppContext);
    const classes = jss(styleGuide);
    const { title, text, textColor, buttonText, buttonAction, carouselItems, backgroundColor, enableFAQButton, faqButtonText, faqButtonAction } = props
    
    const htmlDecode = (input) => {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    }

    return <>
        <div className="flexDiv banner-container" id="HomepageBanner">
            <div className="flexDiv banner-top" style={{ backgroundColor: styleGuide.color14}}>
                <div className="banner-top-1 flexDiv" >
                    <div> 
                    <span
                        style={{
                            fontFamily: styleGuide.fontFamily,
                            fontStyle: 'normal',
                            color: textColor
                        }}
                        className={classes.bannerTitle}
                        dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_BANNER_HEADLINE_', title))}}
                        >
                    </span>
                    </div>
                    <div>
                    <span
                        style={{
                            fontStyle: 'normal',
                            color: textColor,
                            whiteSpace: "pre-line"
                        }}
                        className={classes.bannerContent}
                        dangerouslySetInnerHTML={{ __html: htmlDecode(translate('_BANNER_CONTENT_', text))}}
                        >
                    </span>
                    </div>
                    <div> {!!buttonText && <ButtonLarge1 fallbackText={buttonText} onClick={buttonAction}/>} </div>
                    <div> {enableFAQButton && <ButtonLarge3 style={{width: 245}} fallbackText={faqButtonText} onClick={faqButtonAction}/>} </div>
                </div>
                <div className="banner-top-2" >
                    <CustomCarousel interval={3000} style={{boxShadow: `0px 0px 100px rgba(34, 34, 34, 0.15)`}}>
                        {carouselItems}
                    </CustomCarousel>
                </div>
            </div>
            <div className="flexDiv banner-bottom" style={{ backgroundColor: styleGuide.color1}}></div>
        </div>
    </>
}

export default HomePageBanner1