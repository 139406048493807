export const RadioEmpty = (props) => {
    const pathFill = props.fill || 'white';
    const pathStroke = props.stroke || 'BFBFBF';
    const strokeWidth = props.strokeWidth || '1px';

    return <>
        <svg style={props.style} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="24" height="24" rx="50" fill="white" />
            <rect x="0.5" y="0.5" width="24" height="24" rx="50" stroke="#BFBFBF" />
        </svg>
    </>
}


export const RadioFilled = (props) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return <>
        <svg style={props.style} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="26" height="26" rx="50" fill={pathFill} />
            <rect width="13" height="13" rx="50" x="6.5" y="6.5" fill={"white"} />
            {/* <path d="M12.5007 18.5001L9.00065 15.0001L7.83398 16.1667L12.5007 20.8334L22.5007 10.8334L21.334 9.66675L12.5007 18.5001Z" fill="white" /> */}
        </svg>
    </>
}