import ButtonMedium1 from "components/elements/ButtonMedium1";
import ContentLarge from "components/elements/ContentLarge";
import ContentNormal from "components/elements/ContentNormal";
import ContentSmall from "components/elements/ContentSmall";
import CustomCheckbox from "components/elements/CustomCheckbox";
import CustomRadio from "components/elements/CustomRadioButton";
import { AppContext } from "contexts/AppContext";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./ChristmasCalendarDetail.css";
import Image from "components/elements/Image";
import { useNavigate } from "react-router-dom";
import { callAPI } from "utils/API";
import 'moment/min/locales'
import ExplainerText from "components/elements/ExplainerText";
import { translate } from "utils/Translate";

const ChristmasCalendarDate = (props) => {
  const contextState = useContext(AppContext);
  const { styleGuide } = contextState;
  let { day, active, setActive, christmasCalendarTextColor } = props;
  let { coords, question, answers, date, dayNumber, ProductID, imageOptimizedSource, description, elementTitle, userParticipate} = day;
  const [userAnswer, setUserAnswer] = useState('');
  const [isStart, setIsStart] = useState(false);
  const [participate, setParticipate] = useState(userParticipate);
  const [radioGroup, setRadioGroup] = useState(null);
  const [agree, setAgree] = useState(false);

  const [screenSize, getDimension] = useState({ dynamicWidth: window.innerWidth, dynamicHeight: window.innerHeight });
  
  const setDimension = () => getDimension({ dynamicWidth: window.innerWidth, dynamicHeight: window.innerHeight })

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const navigate = useNavigate();

  const goToProduct = () => navigate(`/product/${ProductID}`);

  const participateAndSubmitAnswer = async () => {
    day.AnswerID = radioGroup
    const response = await callAPI(`/christmasCalendar/participate`, 'POST', {body: day})
    if(response) {
      setParticipate(true)
    }
  }

   if(!coords) {
    return null
  }

  let style = {
    zIndex: 3,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    borderRadius: "15%",
    position: "absolute",
    backgroundColor: styleGuide.color16,
  };

  let tooltipContentStyle = {};

  for (let coord in coords) {
    style[coord] = `${coords[coord]}%`;
    if (coord.toString() === "top" || coord.toString() === "bottom") {
      if (screenSize.dynamicWidth <= 425) {
        tooltipContentStyle[coord] = `${coords[coord] + 16}%`;
      } else if (screenSize.dynamicWidth >= 426 && screenSize.dynamicWidth <= 500) {
        tooltipContentStyle[coord] = `${coords[coord] + 15}%`;
      } else if (screenSize.dynamicWidth >= 501 && screenSize.dynamicWidth <= 700) {
        tooltipContentStyle[coord] = `${coords[coord] + 13.5}%`;
      } else if (screenSize.dynamicWidth >= 701 && screenSize.dynamicWidth <= 767) {
        tooltipContentStyle[coord] = `${coords[coord] + 12}%`;
      } else if (screenSize.dynamicWidth >= 768 && screenSize.dynamicWidth <= 850) {
        tooltipContentStyle[coord] = `${coords[coord] + 14}%`;
      } else if (screenSize.dynamicWidth >= 851 && screenSize.dynamicWidth <= 950) {
        tooltipContentStyle[coord] = `${coords[coord] + 12.5}%`;
      } else if (screenSize.dynamicWidth >= 951 && screenSize.dynamicWidth <= 1023) {
        tooltipContentStyle[coord] = `${coords[coord] + 11.5}%`;
      } else if (screenSize.dynamicWidth == 1024) {
        tooltipContentStyle[coord] = `${coords[coord] + 14}%`;
      } else if (screenSize.dynamicWidth >= 1025 && screenSize.dynamicWidth <= 1436) {
        tooltipContentStyle[coord] = `${coords[coord] + 12}%`;
      } else if (screenSize.dynamicWidth >= 1436 && screenSize.dynamicWidth <= 2560) {
        tooltipContentStyle[coord] = `${coords[coord] + 13.5}%`;
      } else if (screenSize.dynamicWidth >= 2561) {
        tooltipContentStyle[coord] = `${coords[coord] + 14}%`;
      }
    } else {
      if (screenSize.dynamicWidth <= 767) {
        // tooltipContentStyle[coord] = `0%`;
        if(coords[coord] <= 35) {
          tooltipContentStyle[coord] = `${coords[coord]}%`;
        } else {
          tooltipContentStyle[coord] = `${coords[coord] / 3}%`;
        }
        tooltipContentStyle['width'] = '85%'
        tooltipContentStyle['max-width'] = 'none'
      }  else {
        tooltipContentStyle[coord] = `${coords[coord]}%`;
      }
    }
  }

  if (date === moment().format("M-D-YYYY")) {
    style.opacity = 2;
  } else if (date > moment().format("M-D-YYYY")) {
    style.opacity = 0.6;
  } else if (date < moment().format("M-D-YYYY")) {
    style.opacity = 1;
  }

  return (
    <>
      <div 
        onMouseOver={() => setActive(dayNumber)}
        onMouseLeave={() => {
          if (
            moment().format("M-D-YYYY") > date ||
            moment().format("M-D-YYYY") < date
          ) {
            setActive(null);
          }
        }}
        className="tooltip"
        style={style}
      >
        <div style={{ color: christmasCalendarTextColor }} className="tooltip-text">{dayNumber}</div>
      </div>
      {active === dayNumber && (
        <div
          onMouseOver={() => setActive(dayNumber)}
          onMouseLeave={() => setTimeout(() => {setActive(null)}, 500)}
          className={`tooltip-content`}
          style={tooltipContentStyle}
        >
          <ContentNormal className="dateText" style={{ marginBottom: 10, fontWeight: "bold" }}>{moment(date).locale("da").format("D MMMM YYYY")}</ContentNormal>
          {(!participate && moment().format("M-D-YYYY") === date && isStart) ? (
            <>
              <ContentLarge>Q. {question && question}</ContentLarge>
              <CustomRadio
                checked={radioGroup === answers[0].ChristmasDayAnswerID && true}
                onChange={(e) => e.target.checked && setRadioGroup(answers[0].ChristmasDayAnswerID)}
                label={!!answers && !!answers.length && answers[0].answer}
              />
              <CustomRadio
                checked={radioGroup === answers[1].ChristmasDayAnswerID && true}
                onChange={(e) => e.target.checked && setRadioGroup(answers[1].ChristmasDayAnswerID)}
                label={!!answers && !!answers.length && answers[1].answer}
              />
              <CustomRadio
                checked={radioGroup === answers[2].ChristmasDayAnswerID && true}
                onChange={(e) => e.target.checked && setRadioGroup(answers[2].ChristmasDayAnswerID)}
                label={!!answers && !!answers.length && answers[2].answer}
              />
              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, gap: 0, }} >
                <CustomCheckbox
                  onChange={(e) => e.target.checked && setAgree((prev) => !prev)}
                  style={{ marginRight: 0 }}
                  checked={agree}
                />
                <ContentSmall style={{ marginTop: 20, marginBottom: 20 }} translationKey="_CHRISTMAS_CALENDAR_CONSENT_TEXT_">
                  Jeg afgiver hermed samtykke til at mit navn og firmanavn må
                  deles i klubben. Dette samtykke gælder indtil 3 måneder efter
                  konkurrencen er slut.
                </ContentSmall>
              </div>
              <ButtonMedium1
                showIcon={false}
                style={{ flex: "0.625", justifyContent: "center", fontSize: 14, fontFamily: styleGuide.secondaryFontFamily }}
                disabled={radioGroup && agree ? false : true}
                fallbackText="Indsend"
                translationKey="_SEND_BUTTON_SUBMIT_QUESTION_TEXT_"
                onClick={participateAndSubmitAnswer}
              />
            </>
          ) : (
            <div style={{ display: "flex", flexDirection: screenSize.dynamicWidth <= 550 ? "column" : "row", gap: 3, marginBottom: 10, }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!(moment().format("M-D-YYYY") < date) && <ContentNormal style={{ marginBottom: 30, fontSize: screenSize.dynamicWidth <= 640 ? 14 : 16, }}>{description}</ContentNormal>}
                {
                  moment().format("M-D-YYYY") > date ||
                  moment().format("M-D-YYYY") < date ||
                  (moment().format("M-D-YYYY") === date && participate) ? (
                  <ContentNormal style={{fontSize: screenSize.dynamicWidth <= 767 ? 20 : 22, marginBottom: screenSize.dynamicWidth <= 550 ? 20 : 0}}>
                    {participate
                      ? moment().format("M-D-YYYY") > date
                        ? `${translate("_CHRISTMAS_CALENDAR_ANSWER_SUBMITTED_", "Dit svar er indsendt")}`
                        : `${translate("_CHRISTMAS_CALENDAR_PARTICIPATION_THANKS_", "Tak for du deltager!")}`
                      : moment().format("M-D-YYYY") < date
                      ? `${translate("_CHRISTMAS_CALENDAR_COMING_SOON_", "Kommer snart")} 😊`
                      : `${translate("_CHRISTMAS_CALENDAR_MISSED_", "Du gik glip af denne!")} 😞`
                    }
                  </ContentNormal>
                  ) : (
                    <ButtonMedium1
                      style={{ justifyContent: "center", width: "max-content", fontSize: 14, fontFamily: styleGuide.secondaryFontFamily, marginBottom: screenSize.dynamicWidth <= 550 ? 20 : 0 }}
                      fallbackText="Deltag"
                      translationKey="_CHRISTIMAS_CALENDAR_PARTICIPATE_BUTTON_"
                      onClick={(e) => setIsStart(true)}
                    />
                  )
                }
              </div>
              {!(moment().format("M-D-YYYY") < date) && <div>
                <Image className="product-image" style={{ borderColor: styleGuide.color2, cursor: "pointer"}} src={`url(${imageOptimizedSource})`} onClick={goToProduct} >
                </Image>
                <ExplainerText style={{color: styleGuide.color7, display: 'block', marginTop: 5, lineHeight: 1.2}}>{elementTitle}</ExplainerText>
              </div>}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ChristmasCalendarDate;
