import Headline3 from "components/elements/Headline3"

const NoAccess = (props) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
        }}>
            <Headline3
                translationKey={'_ACCESS_DENIED_ERROR_MESSAGE_'}
                fallbackText={'Access Denied !'}
            />
        </div>
    )
}

export default NoAccess

