import Translate from "utils/Translate";
import React, { useContext } from 'react'
import { upgColors } from '../../styles/styles'
import ArrowRightAlt from '../icons/ArrowRightAlt';
import {createUseStyles} from 'react-jss'
import { AppContext } from 'contexts/AppContext';

const jss = createUseStyles({
    buttonMedium2: {
        backgroundColor: (styleGuide) => styleGuide.color18,
        color: (styleGuide) => styleGuide.color1,
        borderRadius: (styleGuide) => styleGuide.buttonBorderRadius,
        height: (styleGuide) => styleGuide.buttonMediumHeight,
        fontSize: (styleGuide) => styleGuide.buttonMediumFontSize,
        fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
        lineHeight: (styleGuide) => styleGuide.buttonMediumLineHeight,
        fontWeight: '600',
        border: 'none',
        padding: '9px 20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        
        '&:hover':{
            backgroundColor: (styleGuide) => styleGuide.color16,
        },
        
        '&:disabled':{
            backgroundColor: (styleGuide) => styleGuide.color18,
            opacity: 0.7,
            cursor: 'not-allowed'
        }
    }
})

const ButtonMedium2 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)
    const showIcon = (typeof props.showIcon === 'boolean') ? props.showIcon : true
    
    return (
        <button
            title={props.title || ""}
            onClick={props.onClick}
            style={{ ...props.style }}
            disabled={props.disabled}
            className={classes.buttonMedium2}
        >
            {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
            {showIcon 
                ?   props.icon 
                        ? props.icon
                        : <ArrowRightAlt style={{ marginLeft:'34px' }}  fill={styleGuide.color1} />
                :   null
            }
        </button>
    )
}

export default ButtonMedium2;