import React, { useContext, useEffect, useState } from 'react'
import { TextField } from '@mui/material';
import './css/InputStyles.css'
import EyeNotVisible from '../icons/EyeNotVisible';
import EyeVisible from '../icons/EyeVisible';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Lock from 'components/icons/Lock';
import { AppContext } from 'contexts/AppContext';

const CustomInput2 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const [showPassword, setShowPassword] = useState(false)
    const [showLock, setShowLock] = useState((typeof props.showLock === 'boolean') ? props.showLock : false)
    const [customIcon, setCustomIcon] = useState(props.customIcon || false)
    const [isPasswordField, setIsPasswordField] = useState(false)
    const [type, setType] = useState('text')

    useEffect(() => {
        if(props.type) {
            setType(props.type)
            if(props.type.toLowerCase() === 'password') {
                setIsPasswordField(true)
            }
        }
    },[])
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
        if(showPassword){
            setType('text')
        }else{
            setType('password')
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleClickShowLock = () => {
        setShowLock(!showLock)
        if(showLock){
            setType('text')
        }else{
            setType('lock')
        }
    };

    const handleMouseDownLock = (event) => {
        event.preventDefault();
    };
    
    const handleClickCustomIcon = () => {
        alert('icon clicked')
    };

    const handleMouseDownCustomIcon = (event) => {
        event.preventDefault();
    };
    
    const defaultUnderlineColor = props.value ? styleGuide.color2 : styleGuide.color4
    const wrapperStyle = {
        backgroundColor: props.disabled ? styleGuide.color3 : styleGuide.color1,
    }
    const inputStyle = {
        borderRadius: 0,
        paddingLeft: '15px',
        paddingRight: '15px',
        color: props?.style?.color || styleGuide.color1,
        "--placeholder-color": props.placeholderColor || 'inherit',
        "&:hover:before": {
            borderBottom : `2pt solid ${defaultUnderlineColor} !important`,
        },
        "&:hover:after": {
            borderBottom : `2pt solid ${styleGuide.color16} !important`,
        },
        "&:before": {
            borderBottom : `2pt solid ${defaultUnderlineColor} !important`,
        },
        "&:after": {
            borderBottom : `2pt solid ${styleGuide.color16} !important`,
        }
    }

    const labelStyle = {
        marginLeft: '15px',
        color: styleGuide.color1,
        "&.Mui-focused":{
            color: styleGuide.color1
        }
    }

    const helpTextStyle = {
        marginLeft: '15px',
    }
    const helpText = props.helpObj && props.helpObj.helpText ? props.helpObj.helpText : ''
    const errorText = props.helpObj && props.helpObj.errorText ? props.helpObj.errorText : ''
    return (
        <div style={props.wrapperStyle}>
        <TextField fullWidth variant={'standard'}
            style={props.style}
            value={props.value}
            // label={props.label}
            placeholder={props.placeholder}
            className={props.className}
            onChange={props.onChange}
            type={type}
            helperText = { props.error ? errorText : helpText}
            InputProps={{
                sx: inputStyle,
                autoComplete: 'off',
                endAdornment: isPasswordField 
                    ?   <InputAdornment position="end" style={{overflow: 'visible'}}>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword()}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <EyeVisible fill={styleGuide.color7} /> : <EyeNotVisible fill={styleGuide.color7} />}
                            </IconButton>
                        </InputAdornment>
                    :   showLock 
                        ?   <InputAdornment position="end" style={{overflow: 'visible'}}>
                                <IconButton
                                    aria-label="toggle lock"
                                    onClick={() => handleClickShowLock()}
                                    onMouseDown={handleMouseDownLock}
                                    edge="end"
                                >
                                    <Lock fill={styleGuide.color6}/>
                                </IconButton>
                            </InputAdornment>
                        :   customIcon
                            ?   <InputAdornment position="end" style={{overflow: 'visible'}}>
                                    <IconButton
                                        aria-label="toggle custom icon"
                                        onClick={() => handleClickCustomIcon()}
                                        onMouseDown={handleMouseDownCustomIcon}
                                        edge="end"
                                    >
                                        {customIcon}
                                    </IconButton>
                                </InputAdornment>
                            :   null
            }}
            InputLabelProps={{
                sx: labelStyle
            }}
            FormHelperTextProps={{
                sx: helpTextStyle
            }}
        />
        </div>
    )
}

export default CustomInput2;