import React, { useContext, useState, useEffect } from 'react'
import './Profile.css'
import { AppContext } from 'contexts/AppContext'
import { translate } from 'utils/Translate'
import Headline2 from 'components/elements/Headline2'
import ButtonLarge1 from 'components/elements/ButtonLarge1'
import { useNavigate } from 'react-router-dom'
import ArrowRight from "components/icons/ArrowRight";
import { callAPI } from 'utils/API'
import { numericRangeArray, pointsConvertor } from 'utils/general'
import IconButtonMedium from 'components/elements/IconButtonMedium'
import { Save } from '@material-ui/icons'
import ContentNormal from 'components/elements/ContentNormal'
import { Modal } from 'react-bootstrap'
import Headline4 from 'components/elements/Headline4'
import moment from 'moment'
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import RoundIconButton1 from 'components/elements/RoundIconButton1'
import CaretDown from 'components/icons/CaretDown'
import TransactionTable from 'components/blocks/TransactionTable/TransactionTable'
import ContentLarge from 'components/elements/ContentLarge'
import CustomSelect from 'components/elements/CustomSelect'
import ButtonMedium1 from 'components/elements/ButtonMedium1'
import ExportToXlsx from 'components/elements/ExportToXlsx'

function ActivationGridSection(props) {
    const { styleGuide, langCode, brandData, userData, setUserData } = useContext(AppContext)
    const { defaultShopID, activationSalesRef, activateComplete, setActivateComplete } = props
    const navigate = useNavigate()
    const [activatePoints, setActivatePoints] = useState({})
    const [showTaxDownload, setShowTaxDownload] = useState(false)
    const [percentage, setPercentage] = useState(userData.taxPercentage || null)
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState(null);
    const [yearWiseTransactions, setYearWiseTransactions] = useState(null)
    const [selectedYear, setSelectedYear] = useState("") // new Date().getFullYear - 1
    const [selectedYearData, setSelectedYearData] = useState([])
    const activationPointsGridLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.activationPointsGridLabels
        ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.activationPointsGridLabels : null
    const pointsSectionLabels = brandData?.features?.upgraderClubConfigurableLabels?.[langCode]?.profilePageLabels?.pointsSectionLabels || null
    const hideDownloadTaxInfoBasedOnYears = brandData?.features?.hideDownloadTaxInfoBasedOnYears
    const brandYear = new Date(brandData?.timeInsert).getFullYear()
    const currentYear = new Date().getFullYear()
    const activeYears = numericRangeArray(brandYear, currentYear, 1)

    const fetchActivePoints = async () => {
        let response = await callAPI("/user/getWalletActivationProfileStats", "GET");
        if (response) {
            setActivatePoints(response)
        }
        setActivateComplete({ ...activateComplete, activationOverview: false })
    }

    const saveTaxPercentage = async () => {
        setLoading(true)
        const response = await callAPI("/user/saveTaxPercentage", "POST", { body: { taxPercentage: percentage } })
        if (response) {
            setUserData({ ...userData, taxPercentage: percentage })
        }
        setLoading(false)
    }

    const fetchWallet = async () => {
        let userWalletLines = await callAPI(`/user/walletLines`);
        const userWallet = [];
        if (!userWalletLines || !userWalletLines.length) return setTransactions([]);
        // remove 0 value entries, personal shop & custom order entries and SHOP & USER type entries
        userWalletLines = userWalletLines
        .filter(w => !!w.walletAmountPoints && !w.walletNote.includes("Order #") && !["SHOP","USER"].includes(w.walletType.toUpperCase()))
        for (const walletLine of userWalletLines) {
            let receiverUserDetails = walletLine.receiverUserDetails ? walletLine.receiverUserDetails : null
            const DKK = walletLine.walletAmountPoints / (brandData?.brandPointRatio || 1)
            const walletObj = {
                year: new Date(walletLine.timeInsert).getFullYear(),
                date: moment(walletLine.timeInsert).format("DD/MM/YYYY"),
                Date: moment(walletLine.timeInsert).format("DD/MM/YYYY"),
                walletAmountPoints: walletLine.walletAmountPoints,
                Point: pointsConvertor(brandData,walletLine.walletAmountPoints, ' '),
                DKK: pointsConvertor(brandData,DKK.toFixed(2), ' '),
                walletNote: walletLine.walletNote === " " || "" ? "-" : walletLine.walletNote,
                Note: walletLine.walletNote === " " || "" ? "-" : walletLine.walletNote,
                sum: walletLine.sum,
                WalletID: walletLine.WalletID,
                Navn: receiverUserDetails?.userName ? receiverUserDetails.userName : "-",
                Email: receiverUserDetails?.userEmail ? receiverUserDetails.userEmail : "-",
                UID: receiverUserDetails?.userUID ? receiverUserDetails.userUID : "-",
            };
            userWallet.push(walletObj);
        }
        return setTransactions(userWallet);
    };

    const NoTransactionsLine = () => (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                padding: 40,
            }}
        >
            <Headline4
                translationKey="_DOWNLOAD_TAX_INFO_NO_WALLET_CAPTION_"
                fallbackText="Ingen transaktion i din pung endnu"
            />
            <ContentLarge
                translationKey="_DOWNLOAD_TAX_INFO_NO_WALLET_TEXT_"
                fallbackText="Når der sker en transaktion, vil du se det her. "
            />
        </div>
    );


    const activatedPointsBgColor = brandData?.features?.activatedPointsBgColor || styleGuide.color16
    const activatedPointsTextColor = brandData?.features?.activatedPointsTextColor || styleGuide.color1

    const pointsForActivationBgColor = brandData?.features?.pointsForActivationBgColor || styleGuide.color26
    const pointsForActivationTextColor = brandData?.features?.pointsForActivationTextColor || styleGuide.color1
    
    const shoppingPointsBgColor = brandData?.features?.shoppingPointsBgColor || styleGuide.color5
    const shoppingPointsTextColor = brandData?.features?.shoppingPointsTextColor || styleGuide.color1
    
    const currentYearActivatedPointsBgColor = brandData?.features?.currentYearActivatedPointsBgColor || styleGuide.color5
    const currentYearActivatedPointsTextColor = brandData?.features?.currentYearActivatedPointsTextColor || styleGuide.color1

    const pointsAvailableForActivationBgColor = brandData?.features?.pointsAvailableForActivationBgColor || styleGuide.color26
    const pointsAvailableForActivationTextColor = brandData?.features?.pointsAvailableForActivationTextColor || styleGuide.color1

    const expectedTaxBgColor = brandData?.features?.expectedTaxBgColor || styleGuide.color15
    const expectedTaxTextColor = brandData?.features?.expectedTaxTextColor || styleGuide.color18

    useEffect(() => {
        fetchActivePoints()
        fetchWallet()
    }, [])

    useEffect(() => {
        if (!transactions) return
        let years = transactions.map(i => i.year)
        years = [...new Set(years)]
        const formattedData = years.map(y => {
            return {
                year: y,
                data: transactions.filter(i => y === i.year)
            }
        })
        setYearWiseTransactions(formattedData)
    }, [transactions])

    useEffect(() => {
        activateComplete.activationOverview && fetchActivePoints()
    }, [activateComplete])

    useEffect(() => {
        if (!yearWiseTransactions?.length) return setSelectedYearData([])
        if (!selectedYear) return setSelectedYearData(transactions)
        const data = yearWiseTransactions.filter(i => i.year === selectedYear)
        if (!data?.length) return setSelectedYearData([])
        return setSelectedYearData(data[0].data)

    }, [selectedYear, yearWiseTransactions])

    const pointLongSuffix = translate("_POINTS_", "point")
    const pointSuffix = translate("_POINTS_SHORT_", "pt")

    return (
        <div id={props?.id || ""} className='activationSectionGrid'>
            <div className='gridSection1'>
                <div style={{ backgroundColor: activatedPointsBgColor }} className='gridSection1Box'>
                    <p
                        className='activationPragraphText'
                        style={{
                            color: activatedPointsTextColor,
                            fontSize: styleGuide.headline6FontSize,
                            fontWeight: styleGuide.contentSmallFontWeight
                        }}
                    >
                        {activationPointsGridLabels?.leftBoxLabel ? activationPointsGridLabels.leftBoxLabel : translate('_ACTIVATE_POINT_USE_SHOP_', 'Aktiverede point til brug i shoppen')}
                    </p>
                    <Headline2 style={{ color: activatedPointsTextColor }}>
                        {pointsConvertor(brandData,activatePoints?.walletBalance || '0', ' ')} <span style={{ fontSize: 16 }}>{activationPointsGridLabels?.leftBoxUnitLabel ? activationPointsGridLabels.leftBoxUnitLabel : pointLongSuffix}</span>
                    </Headline2>
                    <ButtonLarge1
                        configuredLabel={activationPointsGridLabels?.leftBoxButtonLabel}
                        translationKey="_ACTIVATE_GO_TO_SHOP_BUTTON_"
                        fallbackText="Gå til shop"
                        onClick={() => navigate(`/shop/${defaultShopID}`)}
                    />
                </div>
                <div style={{ backgroundColor: pointsForActivationBgColor }} className='gridSection1Box'>
                    <p
                        style={{
                            color: pointsForActivationTextColor,
                            fontSize: styleGuide.headline6FontSize,
                            fontWeight: styleGuide.contentSmallFontWeight
                        }}
                    >
                        {activationPointsGridLabels?.middleBoxLabel ? activationPointsGridLabels.middleBoxLabel : translate('_ACTIVATION_POINT_READY_TO_ACTIVATE_', 'Point - Klar til aktivering')}
                    </p>
                    <Headline2 style={{ color: pointsForActivationTextColor }}>
                        {pointsConvertor(brandData,activatePoints?.walletActivationBalance || '0', ' ')} <span style={{ fontSize: 16 }}>{activationPointsGridLabels?.middleBoxUnitLabel ? activationPointsGridLabels.middleBoxUnitLabel : pointLongSuffix}</span>
                    </Headline2>
                    <ButtonLarge1
                        configuredLabel={activationPointsGridLabels?.middleBoxButtonLabel}
                        translationKey="_ACTIVATE_POINT_BUTTON_"
                        fallbackText="Aktiver point"
                        onClick={() => activationSalesRef?.current?.scrollIntoView({
                            behavior: "smooth",
                            alignToTop: true,
                        })}
                    />
                </div>
                <div style={{ backgroundColor: shoppingPointsBgColor }} className='gridSection1Box'>
                    <p
                        style={{
                            color: shoppingPointsTextColor,
                            fontSize: styleGuide.headline6FontSize,
                            fontWeight: styleGuide.contentSmallFontWeight,
                            textAlign: 'center'
                        }}
                    >
                        {activationPointsGridLabels?.rightBoxLabel ? activationPointsGridLabels.rightBoxLabel : translate('_ACTIVATION_POINT_ALL_POINT_ACTIVATED_TEXT_', `Hvor meget kan jeg shoppe for. når alle mine point er aktiveret?`)}
                    </p>
                    <Headline2 style={{ color: shoppingPointsTextColor }}>
                        {pointsConvertor(brandData,activatePoints?.walletBalance + activatePoints?.walletActivationBalance || '0', ' ')} <span style={{ fontSize: 16 }}>{activationPointsGridLabels?.rightBoxUnitLabel ? activationPointsGridLabels.rightBoxUnitLabel : pointLongSuffix}</span>
                    </Headline2>
                    <p
                        style={{
                            color: shoppingPointsTextColor,
                            fontSize: styleGuide.headline6FontSize,
                            fontWeight: styleGuide.contentSmallFontWeight,
                            textAlign: 'center'
                        }}
                    >
                        {activationPointsGridLabels?.rightBoxButtonLabel ? activationPointsGridLabels.rightBoxButtonLabel : translate('_ACTIVATION_POINT_ALREADY_ACTIVATED_TEXT_', `Allerede aktiverede point + tilgængelige point`)}
                    </p>
                </div>
            </div>
            <div className='gridSection2'>
                <div className='gridSection2Box' style={{ backgroundColor: currentYearActivatedPointsBgColor }}>
                    <div>
                        <div
                            className='activationPragraphText'
                            style={{
                                color: currentYearActivatedPointsTextColor,
                                fontSize: styleGuide.headline6FontSize,
                                fontWeight: styleGuide.contentSmallFontWeight,
                            }}
                        >
                            {activationPointsGridLabels?.lineOneStartText ? activationPointsGridLabels.lineOneStartText : translate('_ACTIVATION_CURRENT_YEAR_POINT_TEXT_', `Point aktiveret i indeværende år:`)}
                        </div>
                        <Headline2 style={{ color: currentYearActivatedPointsTextColor }}>
                            {pointsConvertor(brandData,activatePoints?.walletActivationCurrentYear || '0', ' ')}
                        </Headline2>
                    </div>
                    <div className='arrowActivation'>
                        <ArrowRight style={{ width: 40, height: 40 }} fill={currentYearActivatedPointsTextColor || "white"} />
                    </div>
                    <Headline2 style={{ color: currentYearActivatedPointsTextColor, minWidth: 220, textAlign: 'right' }}>
                        {activatePoints?.walletActivationCurrentYearValue?.toLocaleString("da-DK", { style: "currency", currency: "DKK" }) || '0'}
                    </Headline2>
                    <div
                        className='activationPragraphText'
                        style={{
                            color: currentYearActivatedPointsTextColor,
                            fontSize: styleGuide.headline6FontSize,
                            fontWeight: styleGuide.contentSmallFontWeight,
                        }}
                    >
                        {activationPointsGridLabels?.lineOneEndText ? activationPointsGridLabels.lineOneEndText : translate('_ACTIVATION_CURRENT_YEAR_POINT_VALUE_TEXT_', `Værdi af point aktiveret i indeværende år (Indberettes til SKAT)`)}
                    </div>
                </div>
                <div className='gridSection2Box' style={{ backgroundColor: pointsAvailableForActivationBgColor }}>
                    <div>
                        <div
                            className='activationPragraphText'
                            style={{
                                color: pointsAvailableForActivationTextColor,
                                fontSize: styleGuide.headline6FontSize,
                                fontWeight: styleGuide.contentSmallFontWeight,
                            }}
                        >
                            {activationPointsGridLabels?.lineTwoStartText ? activationPointsGridLabels.lineTwoStartText : translate('_ACTIVATION_POINT_BALANCE_TEXT_', `Tilgængelige point til aktivering:`)}
                        </div>
                        <Headline2 style={{ color: pointsAvailableForActivationTextColor }}>
                            {pointsConvertor(brandData,activatePoints.walletActivationBalance || '0', ' ')}
                        </Headline2>
                    </div>
                    <div className='arrowActivation'>
                        <ArrowRight style={{ width: 40, height: 40 }} fill={pointsAvailableForActivationTextColor || "white"} />
                    </div>
                    <Headline2 style={{ color: pointsAvailableForActivationTextColor, minWidth: 220, textAlign: 'right' }}>
                        {activatePoints?.walletActivationBalanceValue?.toLocaleString("da-DK", { style: "currency", currency: "DKK" }) || '0'}
                    </Headline2>
                    <div
                        className='activationPragraphText'
                        style={{
                            color: pointsAvailableForActivationTextColor,
                            fontSize: styleGuide.headline6FontSize,
                            fontWeight: styleGuide.contentSmallFontWeight
                        }}
                    >
                        {activationPointsGridLabels?.lineTwoEndText ? activationPointsGridLabels.lineTwoEndText : translate('_ACTIVATION_POINT_BALANCE_VALUE_TEXT_', `Værdi af tilgængelige point til aktivering`)}
                    </div>
                </div>
                <div className='gridSection2Box' style={{ backgroundColor: expectedTaxBgColor }}>
                    <div>
                        <div
                            className='activationPragraphText'
                            style={{
                                color: expectedTaxTextColor,
                                fontSize: styleGuide.headline6FontSize,
                                fontWeight: styleGuide.contentSmallFontWeight,
                                maxWidth: 370
                            }}
                        >
                            {activationPointsGridLabels?.lineThreeStartText ? activationPointsGridLabels.lineThreeStartText : translate('_ACTIVATION_CALCULATE_POINT_CURRENT_YEAR_TEXT_', `Beregn din forventede skat for aktiverede point i indeværende år`)}
                        </div>
                    </div>
                    <div className='arrowActivation'>
                        <ArrowRight style={{ width: 40, height: 40 }} fill={expectedTaxTextColor || "white"} />
                    </div>
                    <div className='calculationDiv'>
                        <div
                            className='activationPragraphText'
                            style={{
                                color: expectedTaxTextColor,
                                fontSize: styleGuide.headline6FontSize,
                                fontWeight: styleGuide.contentSmallFontWeight,
                            }}
                        >
                            {activationPointsGridLabels?.lineThreeCenterText ? activationPointsGridLabels.lineThreeCenterText : translate('_ACTIVATION_WITHDRWAL_PERCENTAGE_TEXT_', `Indtast din trækprocent:`)}
                        </div>
                        <div>
                            <input value={percentage === null ? userData.taxPercentage : percentage} onChange={(e) => setPercentage(e.target.value)} style={{ width: 50, outline: 'none', padding: 5 }} /> %
                        </div>
                        <div
                            className='activationPragraphText'
                            style={{
                                color: expectedTaxTextColor,
                                fontSize: styleGuide.headline6FontSize,
                                fontWeight: styleGuide.contentSmallFontWeight,
                            }}
                        >
                            {activationPointsGridLabels?.lineThreeEndText ? activationPointsGridLabels.lineThreeEndText : translate('_ACTIVATION_TAX_CALCULATED_TEXT_', `Din skat er beregnet til:`)}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input disabled value={percentage !== null ? (activatePoints?.walletActivationCurrentYearValue * percentage / 100).toLocaleString("da-DK", { style: "currency", currency: "DKK" }).replace('kr.', '') : ''} style={{ outline: 'none', backgroundColor: 'transparent', border: '1px solid white', padding: 5, width: 150 }} />
                            <ContentNormal style={{ marginLeft: 4, marginTop: 4 }}>kr.</ContentNormal>
                            <IconButtonMedium title="Gem" style={{ marginLeft: 8 }} icon={
                                <Save
                                    fill='white'
                                    style={{ height: 20, width: 20 }}
                                    stroke={styleGuide.color16}
                                    strokeWidth="1.5px"
                                />
                            } onClick={saveTaxPercentage} />
                        </div>
                    </div>
                </div>
            </div>
            {
                !hideDownloadTaxInfoBasedOnYears && <>
                    <ButtonLarge1
                        showIcon={false}
                        translationKey="_DOWNLOAD_TAX_INFO_BUTTON_TEXT_"
                        fallbackText="Download previous years' tax information"
                        style={{ maxWidth: 'max-content' }}
                        onClick={() => { setShowTaxDownload(true) }}
                    />
                    <Modal size="xl" show={showTaxDownload} onHide={() => setShowTaxDownload(false)} style={{ margin: 'auto' }} >
                        <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }} >
                            <Headline4 translationKey="_DOWNLOAD_TAX_INFO_POPUP_CAPTION_">Year wise tax information</Headline4>
                        </Modal.Header>
                        <Modal.Body className="pt-0">
                            <div id="show_points_section" style={{ marginTop: 30 }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <ExportToXlsx
                                        excelData={selectedYearData}
                                        fileName={`${selectedYear || "Point"}_data_export_`}
                                        buttonText={translate("_DOWNLOAD_TAX_INFO_EXPORT_BUTTON_TEXT_", "Export")}
                                        fields={["Note", "Point", "DKK", "Date"]}
                                    />
                                    <CustomSelect
                                        label={translate("_YEAR_", "År")}
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        options={[{ value: "", label: "All" }, ...activeYears.map(i => ({ value: i, label: i }))]}
                                        style={{ width: 200 }}
                                    />
                                </div>

                                {!selectedYearData ? null : !!selectedYearData && !selectedYearData.length ? (
                                    <NoTransactionsLine />
                                ) : (
                                    // <TransactionTable transactions={selectedYearData}  />
                                    <div className="transaction-table">
                                        <div className="table-head">
                                            <div className="th tcol-5 ">{'Note'}</div>
                                            <div className="th tcol-2">{'Point'}</div>
                                            <div className="th tcol-2">{'DKK'}</div>
                                            <div className="th tcol-3">{translate('_DATE_', 'Dato')}</div>
                                        </div>
                                        <div className="table-body">
                                            {selectedYearData.map((transaction, idx) => (
                                                <div className="tr" key={idx}>
                                                    <div className="td tcol-5 ">{transaction.walletNote}</div>
                                                    <div className="td tcol-2">{transaction.Point}</div>
                                                    <div className="td tcol-2">{transaction.DKK || ' '}</div>
                                                    <div className="td tcol-3">{transaction.date}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </div>
    )
}

export default ActivationGridSection