import { useContext } from 'react'
import { AppContext } from 'contexts/AppContext';
import ReactDOMServer from 'react-dom/server';

const Translate = (props) => {
    const { langCode, translations } = useContext(AppContext);
    try {
        const renderHtml = props?.renderHtml
        if (!props.translationKey) return props.children || `missing TRANSLATION_KEY & FALLBACK_TEXT`
        const textKey = props.translationKey
        const translatedText = (textKey && translations && translations[textKey])
            ? translations[textKey]
            : props.children || `MISSING_FALLBACK_TEXT for key : ${textKey}`

        return !!renderHtml ? <span dangerouslySetInnerHTML={{__html: translatedText}} /> : translatedText

    } catch (error) {
        console.error(error)
    }
}

export const translate = (str, fallback) => {
    try {
        const ret = ReactDOMServer.renderToStaticMarkup(Translate({ translationKey: str, children: fallback }))
        return ret
    } catch (error) {
        console.error(error)
    }
}

export default Translate;

// For future use when building profile page where we can change language
/*
export const changeLanguage = async (lang) => {
    const updateLanguageCallResponse = await callAPI('/sessions/update-language', 'POST', { body: { deviceLanguage: lang } })
    if (updateLanguageCallResponse && updateLanguageCallResponse.length) {
        const object = updateLanguageCallResponse.reduce((obj, item) => (obj[item.translationKey] = item.translationValue, obj), {});
        const staticTranslations = Translations[ctxtval.langcode]
        const tranlations = { ...object, ...staticTranslations }
        setTranslations(tranlations)
        setLangCode(lang)
    }
    console.log(updateLanguageCallResponse)
}
*/
export const months = [
    {key: '_MONTH_JAN_', fallbackText: 'Januar'},
    {key: '_MONTH_FEB_', fallbackText: 'Februar'},
    {key: '_MONTH_MAR_', fallbackText: 'Marts'},
    {key: '_MONTH_APR_', fallbackText: 'April'},
    {key: '_MONTH_MAY_', fallbackText: 'Maj'},
    {key: '_MONTH_JUN_', fallbackText: 'Juni'},
    {key: '_MONTH_JUL_', fallbackText: 'Juli'},
    {key: '_MONTH_AUG_', fallbackText: 'August'},
    {key: '_MONTH_SEP_', fallbackText: 'September'},
    {key: '_MONTH_OCT_', fallbackText: 'Oktober'},
    {key: '_MONTH_NOV_', fallbackText: 'November'},
    {key: '_MONTH_DEC_', fallbackText: 'December'},
]

export const monthsSelect = [
    {label: 'Januar', value: 1},
    {label: 'Februar', value: 2},
    {label: 'Marts', value: 3},
    {label: 'April', value: 4},
    {label: 'Maj', value: 5},
    {label: 'Juni', value: 6},
    {label: 'Juli', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'Oktober', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
]

export const localeStrings = {
    dk: "da-DK",
    en: "en-UK",
}

export const formatNumber = (number=0, langCode) => {
    if(!number || isNaN(number)) return number
    if(!langCode) langCode = 'dk'
    number = Number(Number(number).toFixed(2))
    return number.toLocaleString(localeStrings[langCode])
}