import RoundIconButton2 from 'components/elements/RoundIconButton2'
import ArrowLeft from 'components/icons/ArrowLeft'
import ArrowRight from 'components/icons/ArrowRight'
import { AppContext } from 'contexts/AppContext'
import { useContext, useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './CustomCarousel.css'


const CustomCarousel = (props) => {
    const { styleGuide } = useContext(AppContext)
    const [slideNumber, setslideNumber] = useState(null)
    const [indexProp, setindexProp] = useState({})
    
    const {children, style, className, onClick, indicators, controls, nextIcon, prevIcon, wrap, interval, id, activeIndex} = props

    useEffect(() => {
        if(activeIndex && activeIndex > -1 && !interval) {
            setslideNumber(activeIndex)
        } else {
            setslideNumber(null)
        }
    }, [interval, activeIndex])
    
    useEffect(() => {
        if(!slideNumber || isNaN(slideNumber) || slideNumber < 0) {
            setindexProp({})
        } else {
            setindexProp({activeIndex: slideNumber})
        }
    },[slideNumber])

    return !children ? null : (
        <div style={style} className={`custom-carousel ${className}`} onClick={onClick} >
            <Carousel 
                id={id || ''}
                wrap={wrap === true || wrap === false ? wrap : true}
                controls={controls === true || controls === false ? controls : true}
                indicators={indicators || false}
                interval={interval} 
                fade={true}
                nextIcon={nextIcon ? nextIcon : <RoundIconButton2 style={{backgroundColor: styleGuide.color4}} icon={<ArrowRight fill={styleGuide.color1} />} /> }
                prevIcon={prevIcon ? prevIcon : <RoundIconButton2 style={{backgroundColor: styleGuide.color4}} icon={<ArrowLeft fill={styleGuide.color1} />} /> }
                {...indexProp}
            >
                { children?.map((item, idx) => (
                    <Carousel.Item key={idx}>
                        {item}
                    </Carousel.Item>
                )) }
                
            </Carousel>
        </div>
    )
}

export default CustomCarousel