
const EyeNotVisible = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg style={props.style} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00364 2.91041C10.0118 2.91041 11.6417 4.54025 11.6417 6.54843C11.6417 7.02137 11.5471 7.46521 11.3797 7.87995L13.5043 10.0045C14.603 9.08777 15.4688 7.90177 16 6.54843C14.7412 3.35425 11.6344 1.09141 7.99636 1.09141C6.97772 1.09141 6.00273 1.27331 5.1005 1.60073L6.67212 3.17235C7.08686 3.005 7.5307 2.91041 8.00364 2.91041ZM0.727603 0.924056L2.38654 2.58299L2.72124 2.91769C1.51341 3.8563 0.567531 5.10778 0 6.54843C1.25875 9.74261 4.36562 12.0055 8.00364 12.0055C9.13142 12.0055 10.2083 11.7872 11.1905 11.3943L11.4961 11.6999L13.628 13.8245L14.5521 12.9004L1.65166 0L0.727603 0.924056ZM4.75125 4.9477L5.87904 6.07549C5.84266 6.22829 5.82083 6.38836 5.82083 6.54843C5.82083 7.75625 6.79582 8.73124 8.00364 8.73124C8.16371 8.73124 8.32378 8.70941 8.47658 8.67303L9.60437 9.80082C9.11687 10.0409 8.57844 10.1864 8.00364 10.1864C5.99545 10.1864 4.36562 8.55662 4.36562 6.54843C4.36562 5.97362 4.51114 5.4352 4.75125 4.9477ZM7.88722 4.38017L10.1792 6.67212L10.1937 6.55571C10.1937 5.34789 9.21874 4.3729 8.01091 4.3729L7.88722 4.38017Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>

    )
}
export default EyeNotVisible

