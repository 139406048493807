import ButtonLarge1 from "components/elements/ButtonLarge1";
import ContentLarge from "components/elements/ContentLarge";
import Headline2 from "components/elements/Headline2";
import { AppContext } from "contexts/AppContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

const CorrectAdvanceStatement = (props) => {
  const contextState = useContext(AppContext);
  const { brandData } = contextState;
  return (
    <div
      id={props?.id || ""}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        marginTop: 100,
        // marginBottom: 100,
      }}
    >
      <Headline2
        translationKey="_PROFILE_PAGE_CORRECT_ADVANCE_STATEMENT_TITLE_"
        fallbackText="Ret din forskudsopgørelse"
        configuredLabel={!!brandData &&
          !!brandData.features &&
          brandData?.features?.correctAdvanceStatementTitle}
      />
      <div className="profileCorrectStatementInfoText">
        <ContentLarge
          configuredLabel={!!brandData &&
          !!brandData.features &&
          brandData?.features?.correctAdvanceStatementText}
          translationKey="_PROFILE_PAGE_CORRECT_ADVANCE_STATEMENT_INFO_TEXT_"
          fallbackText="Dine aktiverede point bliver automatisk indberettet til SKAT. Du skal blot huske at rette din forskudsopgørelse. Er du i tvivl om hvordan du gør, eller om hvordan beskatning af point fungerer, har vi lavet en guide til dig."
        />
      </div>
      <a
        href={
          (!!brandData &&
            !!brandData.features &&
            brandData?.features?.correctAdvanceStatementLink) ||
          "/"
        }
        style={{ textDecoration: "none" }}
      >
        <ButtonLarge1
          configuredLabel={!!brandData &&
            !!brandData.features &&
            brandData?.features?.correctAdvanceStatementButtonText}
          showIcon={false}
          translationKey="_SEE_YOUR_GUIDE_HERE_BUTTON_TEXT_"
          fallbackText="Se vores guide her"
          style={{ maxWidth: "max-content" }}
        />
      </a>
    </div>
  );
};

export default CorrectAdvanceStatement;
