import CustomSelect2 from 'components/elements/CustomSelect2';
import Headline2 from 'components/elements/Headline2'
import { AppContext } from 'contexts/AppContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { callAPI } from 'utils/API';

function StatisticsSection2(props) {
  const { styleGuide, themeConfig, langCode, brandData } = useContext(AppContext);
  const {activateComplete, setActivateComplete} = props
  const thisYear = new Date().getUTCFullYear();
  const [taxYear, setTaxYear] = useState(thisYear);
  const [stats, setStats] = useState([]);
  const statisticsSectionActivationLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.statisticsSectionActivationLabels
  ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.statisticsSectionActivationLabels : null
  
  const yearOptions = [
    thisYear,
    thisYear - 1,
    thisYear - 2,
    thisYear - 3,
    thisYear - 4,
  ].map((x) => {
    return { value: x, label: x };
  });

  const fetchGraph = async () => {
    const userGraphStats = await callAPI(`/user/walletActivationGraphData`, 'GET', {query: {year: taxYear}});
    setActivateComplete({...activateComplete, activationGraph: false})
    if (!userGraphStats || !userGraphStats.length) return setStats([]);
    userGraphStats.map((item, index) => {
      let languageCode = langCode === "dk" ? "da" : langCode
      let string = (moment(`${item.month} 1, 2012`).locale(languageCode).format("MMMM"))
      item.month = statisticsSectionActivationLabels?.monthLabels?.length && statisticsSectionActivationLabels?.monthLabels[index] ? statisticsSectionActivationLabels.monthLabels[index] : 
        string.charAt(0).toUpperCase() + string.slice(1)
    })
    return setStats(userGraphStats);
  }

  useEffect(() => {
    fetchGraph()
  },[taxYear])

  useEffect(() => {
    activateComplete?.activationGraph && fetchGraph()
  },[activateComplete])

  return (
    <div id={props?.id || ""} ref={props.staticRef} style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 100, marginBottom: themeConfig.disableWishlist ? 350 : 100, minHeight: 600 }}>
      <Headline2 configuredLabel={statisticsSectionActivationLabels?.titleText} translationKey='_PROFILE_PAGE_STATISTIC_' fallbackText='Din statistik' style={{ marginTop: 16 }} />
      <CustomSelect2
        wrapperStyle={{marginBottom: 50}}
        value={taxYear}
        onChange={(e) => setTaxYear(e.target.value)}
        options={yearOptions}
        style={{width: 100,  border: `1px solid ${styleGuide.color18}`, padding: 10}}
      />
      <ResponsiveContainer width="100%" height={600}>
        <BarChart data={stats}>
          <XAxis dataKey="month"  tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip cursor={{fill: 'transparent'}} />
          <Bar dataKey="points" fill={styleGuide.color17} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor="end" fill="#666" transform="rotate(270)">
        {payload.value}
      </text>
    </g>
  );
}

export default StatisticsSection2