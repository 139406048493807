import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';

import Headline4 from "components/elements/Headline4"
import ContentSmall from "components/elements/ContentSmall"
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import { AppContext } from 'contexts/AppContext';
import CustomCheckbox from 'components/elements/CustomCheckbox';

const GdprPopUp = ({ close, accept }) => {
  const { brandData } = useContext(AppContext)
  const [consentGDPR, setConsentGDPR] = useState(false)
  const [consentNews, setConsentNews] = useState(false)

  const defaultCookieConsentPolicy = "https://drive.google.com/file/d/1d4oS06FCAlhSWasUgZVLg5X-mYABoHfm/view"
  const contactEmail = brandData?.contactEmail || `support+${brandData?.brandSlug}@upgrader.club`
  const contactPhone = brandData?.contactPhone || '+45 3115 8411'
  const { gdprConsentText = '',
    newsConsentText = '',
    consentCookiePolicy = defaultCookieConsentPolicy } = brandData?.features
  const showCustomConsentGDPRAndNews = !!brandData?.features?.customUserConsentGDPRAndNews || false
  // const consentCookiePolicy = brandData?.features?.consentCookiePolicy || defaultCookieConsentPolicy

  return (
    <Modal show={true} onHide={close} style={{ margin: 'auto' }} centered size='lg'>
      <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }}>
        <Headline4 translationKey="_GDPR_CONSENT_CAPTION_">GDPR samtykke </Headline4>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {
          showCustomConsentGDPRAndNews
            ? <CustomGdprAndNewsBasedOnTranslation
              setConsentGDPR={setConsentGDPR}
              setConsentNews={setConsentNews}
              contactEmail={contactEmail}
              consentCookiePolicy={consentCookiePolicy}
              gdprConsentText={gdprConsentText}
              newsConsentText={newsConsentText}
            />
            : brandData?.features?.gdprText2
              ? <SecondaryGdpr />
              : <DefaultGdpr contactEmail={contactEmail} contactPhone={contactPhone} />
        }

        <div style={{
          display: 'flex',
          padding: '1rem',
          justifyContent: 'space-around',
        }}>
          <ButtonMedium1 showIcon={false} translationKey="_ACCEPT_" fallbackText="Godkend" onClick={accept} disabled={showCustomConsentGDPRAndNews && (!consentGDPR || !consentNews)} />
          <ButtonMedium4 showIcon={false} translationKey="_CANCEL_" fallbackText="Afvis GDPR" onClick={close} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default GdprPopUp;

const CustomGdprAndNewsBasedOnTranslation = ({ setConsentGDPR, setConsentNews, contactEmail, consentCookiePolicy, newsConsentText, gdprConsentText }) => {
  return <>
    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginLeft: 16, marginTop: 16 }}>
      <CustomCheckbox style={{ alignSelf: 'start' }} onChange={(e) => setConsentGDPR(e.target.checked)} />
      <div>
        {
          !!gdprConsentText
            ? <ContentSmall renderHtml={true} style={{ fontWeight: '400', textAlign: 'justified' }} >{gdprConsentText}</ContentSmall>
            : <>
              <div>
                <ContentSmall renderHtml={true} style={{ fontWeight: '400', textAlign: 'justified' }} translationKey='_GDPR_NEWS_CONSENT_DEFAULT_TEXT_1_' >
                  Jeg giver hermed samtykke til, at mine personoplysninger må behandles til brug for administration af Min Side i Santander Salesclub.
                </ContentSmall>
              </div>
              <div style={{ marginTop: 8 }}>
                <ContentSmall renderHtml={true} style={{ fontWeight: '400' }} translationKey='_GDPR_NEWS_CONSENT_DEFAULT_TEXT_2_'>
                  I det omfang jeg eksplicit har accepteret dette, må mine oplysninger også bruges til fremsendes af e-mail og sms, som alene vedrører Min Side i Santander Salesclub. Vælger du at tilbagekalde dit samtykke, vil du ikke længere kunne anvende Santander Salesclub.
                </ContentSmall>
              </div>
            </>
        }
      </div>
    </div>
    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginLeft: 16, marginTop: 32 }}>
      <CustomCheckbox style={{ alignSelf: 'start' }} onChange={(e) => setConsentNews(e.target.checked)} />
      <div>
        {
          !!newsConsentText
            ? <ContentSmall renderHtml={true} style={{ fontWeight: '400', textAlign: 'justified' }} >{newsConsentText}</ContentSmall>
            : <>
              <div>
                <ContentSmall renderHtml={true} style={{ fontWeight: '400' }} translationKey='_GDPR_NEWS_CONSENT_DEFAULT_TEXT_3_'>
                  Jeg samtykker til at modtage gode tips, tilbud, invitationer til konkurrencer og begivenheder, samt information om nyheder og særlige kampagner, der vedhører Santander Salesclub produktsortiment og medlemsservices, via e-mail og sms.
                </ContentSmall>
              </div>
              <div style={{ marginTop: 8 }}>
                <ContentSmall renderHtml={true} style={{ fontWeight: '400' }} translationKey='_GDPR_NEWS_CONSENT_DEFAULT_TEXT_4_'>
                  {`Du kan til enhver tid tilbagekalde dit samtykke ved at kontakte vores support på <a href="mailto:${contactEmail}">${contactEmail}</a> eller ved at trykke "afmeld" i mailen.`}
                </ContentSmall>
              </div>
              <div style={{ marginTop: 8 }}>
                <ContentSmall renderHtml={true} style={{ fontWeight: '400' }} translationKey='_GDPR_NEWS_CONSENT_DEFAULT_TEXT_5_'>
                  {`Det er MS Branding A/S, der som dataansvarlig står bag Santander Salesclub. Du kan læse mere om vores behandling af dine personoplysninger og rettigheder i Vilkår og Betingelser via linket <a href="${consentCookiePolicy}" target="_blank">Privatlivs- og cookiepolitik</a>.`}
                </ContentSmall>
              </div>
            </>
        }

      </div>
    </div>
  </>
}

const SecondaryGdpr = () => {
  const { brandData, styleGuide } = useContext(AppContext)
  return <div
    dangerouslySetInnerHTML={{ __html: brandData?.features?.gdprText2 }}
    style={{
      fontFamily: styleGuide?.secondaryFontFamily,
      fontWeight: styleGuide?.contentSmallFontWeight,
      fontSize: styleGuide?.contentSmallFontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide?.contentSmallLineHeight,
      color: styleGuide?.color7,
      marginTop: 5
    }}>
  </div>
}

const DefaultGdpr = ({ contactEmail = "", contactPhone = "" }) => <>
  <div>
    <ContentSmall style={{ fontWeight: '400' }} translationKey='_GDPR_CONSENT_DEFAULT_TEXT_1_'>
      Jeg giver hermed samtykke til, at MS Branding A/S må behandle mine personoplysninger til brug for administration af min profil i
      kundeklubben.
    </ContentSmall>
  </div>
  <div>
    <ContentSmall translationKey='_GDPR_CONSENT_DEFAULT_TEXT_2_'>
      I det omfang jeg eksplicit har accepteret dette, er MS Branding A/S endvidere berettiget til at benytte mine oplysninger i forbindelse
      med fremsendelse af særlige tilbud og events, som vedrører kundeklubben.
    </ContentSmall>
  </div>
  <div>
    <ContentSmall>
      Du kan til enhver tid tilbagekalde dit samtykke ved at kontakte vores support på <a href={`mailto:${contactEmail}`}>{contactEmail}</a> eller på telefon {contactPhone}.
    </ContentSmall>
  </div>
  <div>
    <ContentSmall>
      Vælger du at tilbagekalde dit samtykke vil dette eventuelt kunne medføre, at du ikke længere kan anvende kundeklubben.
    </ContentSmall>
  </div>
</>