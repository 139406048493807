import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { upgColors, fontFamily } from "styles/styles";

const Headline2 = (props) => {
  const { styleGuide } = useContext(AppContext)

    const headline2Style = {
      fontFamily: styleGuide.fontFamily,
      fontWeight: styleGuide.headline2FontWeight,
      fontSize: styleGuide.headline2FontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.headline2LineHeight,
      color: styleGuide.color7
    }

  
    return (
      <span onClick={props.onClick} style={{...headline2Style, ...props.style}} className={props.className} >
        { props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
      </span>
    )
}

export default Headline2;