import React from 'react'
import { Modal } from 'react-bootstrap'; 

import Headline4 from "components/elements/Headline4"
import ContentSmall from "components/elements/ContentSmall"
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import { translate } from 'utils/Translate';

const BrandConsentPopup = (props) => {
  const close = props.close
  const accept = props.accept
  
  return (
    <Modal show={true} onHide={close} style={{ margin: 'auto' }} centered>
      <Modal.Header className="pb-0 border-0" closeButton style={{ fontSize: 9 }} centered>
        <Headline4 translationKey="_BRAND_CONSENT_CAPTION_">Betingelser </Headline4>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div style={{marginTop: 16}}>
          <ContentSmall style={{ fontWeight: '400' }} translationKey="_BRAND_CONSENT_CONTENT_">
          Du har ikke godkendt betingelserne. Godkend ved at trykke på knappen.
          </ContentSmall>
        </div>
        <div style={{marginTop: 16}}>
          <ContentSmall>
            <a href={props.termsUrl} target="_blank">
              {translate('_BRAND_CONSENT_TERMS_LINK_TEXT_', 'Betingelser for brug af klubben')}
            </a>
          </ContentSmall>
        </div>
        <div style={{
          display: 'flex',
          padding: '1rem',
          justifyContent: 'space-around',
        }}>
          <ButtonMedium1 showIcon={false} translationKey="_ACCEPT_" fallbackText="Godkend" onClick={accept} />
          <ButtonMedium4 showIcon={false} translationKey="_CANCEL_" fallbackText="Afvis" onClick={close} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BrandConsentPopup;