const Tick = (props) => {
    const pathFill = props.fill || 'white';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25006 9.44994L2.80007 6.99994L1.9834 7.8166L5.25006 11.0833L12.2501 4.08327L11.4334 3.2666L5.25006 9.44994Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    )
}
export default Tick


