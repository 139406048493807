import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { upgColors, fontFamily } from "styles/styles";

const ExplainerTextTiny = (props) => {
  const { styleGuide } = useContext(AppContext)
    const explainerTextTinyStyle = {
      fontFamily: styleGuide.secondaryFontFamily,
      fontWeight: styleGuide.explainerTextTinyFontWeight,
      fontSize: styleGuide.explainerTextTinyFontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.explainerTextTinyLineHeight,
      color: styleGuide.color7
    }

  
    return (
      <span onClick={props.onClick} style={{...explainerTextTinyStyle, ...props.style}} className={props.className}>
        <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>
      </span>
    )
}

export default ExplainerTextTiny;