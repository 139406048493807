import React, { useContext } from 'react'
import { AppContext } from "contexts/AppContext";
import "./ProgressBarWithTooltip.css";
import { translate } from 'utils/Translate';

const ProgressBarWithTooltip = (props) => {
    const { styleGuide, langCode, brandData } = useContext(AppContext);
    const min = props.min;
    const max = props.max;
    const diff = max - min;
    const BarValue = min==0 && max==0? 100 : (min / max) * 100 > 100 ? '100' : `${(min/max)*100}`
    const statisticsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.statisticsSectionLabels
    ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.statisticsSectionLabels : null

    return (
        <>
            <div style={{width:'100%',height:'100%'}}>
            <div className="progress">
                <div className="progress-bar" role="progressbar" aria-valuenow={min} aria-valuemin={min} aria-valuemax={max} aria-label="100"
                    style={{ width: `${BarValue}%`,backgroundColor:styleGuide.color16 }}>
                        { diff > 0 ? <span><p>{statisticsSectionLabels?.youAreMissingText || translate('_USER_MISSING_POINTS_', 'Du mangler')}</p><h6> {max - min}</h6></span> : null }
                        {/* {current} */}
                    </div>
                </div>
                <div className='min-max-value'>
                <div className="current_points" style={{ color:BarValue > 4 ? 'white' : 'black'}}>0</div>
                <div className="max_points" style={{ color:BarValue > 99 ? 'white' : 'black'}}>{max}</div>
                </div>
            </div>
        </>
    )
}

export default ProgressBarWithTooltip