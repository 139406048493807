import ButtonMedium1 from "components/elements/ButtonMedium1"
import ButtonMedium3 from "components/elements/ButtonMedium3"
import Headline4 from "components/elements/Headline4"
import ContentSmall from "components/elements/ContentSmall"
import { Link } from "react-router-dom"
import {Paper} from "@mui/material"
import './CookiePopUp.css'
import { translate } from "utils/Translate"

const CookiePopUp = (props) => {
    const callbackFunction = props.callback
    function createCookie() {
        document.cookie = "cookieConsent"
        callbackFunction(true)
    }
    return (
        <Paper square={true} 
            className='cookie-popup-wrapper'
            style={{
                width: 375,
                height: 375,
                maxWidth: '90vw',
                maxHeight: '90vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                zIndex: 2,
                padding: 0,
                filter: 'drop-shadow(0px 0px 100px rgba(34, 34, 34, 0.15))'
            }}>
            <div style={{
                flexDirection: 'column',
                display: 'flex',
                padding: 40,
                backgroundImage: 'url(/cookie.svg)',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                width: '100%',
                backgroundSize: 'contain',
                backgroundPosition: '100% 100px',
            }}>
                <div><Headline4 style={{fontWeight: '400'}} translationKey="_COOKIE_USAGE_CAPTION_">Vores hjemmeside anvender cookies </Headline4></div>
                <div><ContentSmall translationKey="_COOKIE_READ_MORE_TEXT_">Du kan læse mere om hvilke cookies, som siden bruger på Cookiepolitik.</ContentSmall></div>
                <div><ContentSmall><Link to={'/cookie-policy'} target="_blank">{translate('_COOKIE_POLICY_LINK_', 'Gå til cookiepolitik')}</Link></ContentSmall></div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', padding: '24px 10px', backgroundColor: '#F0F0F0', justifyContent: 'space-around'}}>
                <ButtonMedium1 showIcon={false} onClick={() => createCookie()} translationKey="_COOKIE_ACCEPT_" >Tillad cookies</ButtonMedium1>
                <ButtonMedium3 showIcon={false} onClick={() => alert("Vores side kræver cookie tilladelse. Venligst accepter cookies for at benytte siden.")} translationKey="_COOKIE_REJECT_">Afvis cookies</ButtonMedium3>
            </div>
        </Paper>
    )
}

export default CookiePopUp;