import Translate from "utils/Translate";
import React, { useContext } from 'react'
import './css/ButtonStyles.css'
import Tag from 'components/icons/Tag';
import Close from 'components/icons/Close';
import { createUseStyles } from "react-jss";
import { AppContext } from 'contexts/AppContext';

const jss = createUseStyles({
    labelSticker: {
        height: (styleGuide)=>styleGuide.labelTagHeight,
    },
  
    "@media screen and (max-width: 768px)": {
        labelSticker: {
            top: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? styleGuide.productNormalHeightMobile * 65 / 100 : styleGuide.productNormalHeightMobile * 73 / 100,
        },
    }, 
  });
const LabelSticker = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide);
    const LabelStickerStyle = {
        fontFamily: styleGuide.secondaryFontFamily,
        color: props.type === 'SALE' ? styleGuide.color18 : styleGuide.color1,
        backgroundColor: props.type === 'SALE' ? styleGuide.color19 : styleGuide.color18,
        fontWeight: '300',
        border: 'none',
        fontSize: styleGuide.captionFontSize,
        padding: '5px 14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'max-content',
        zIndex: 10,
        position: 'absolute',
        left: '-16px',
        bottom: '90px'
    }
    let text = {translationKey: null, fallbackText: ' '}
    if(props.type === 'SALE'){
        text = { translationKey: '_PRODUCT_STATUS_SALE_', fallbackText: 'TILBUD'}
    }else if(props.type === 'NEW'){
        text = { translationKey: '_PRODUCT_STATUS_NEW_', fallbackText: 'NYHED'}
    }else if(props.type === 'POPULAR'){
        text = { translationKey: '_PRODUCT_STATUS_POPULAR_', fallbackText: 'POPULÆR'}
    }else if(props.type === 'PRICETAG'){
        text = { translationKey: '_PRODUCT_PRICE_', fallbackText: `Priser fra ${props.price} kr.`}
    }    

    return (
        <span
            style={{...LabelStickerStyle, ...props.style}}
            className={classes[props.className]}
        >   
            <Translate translationKey={text.translationKey || props.translationKey}>{text.fallbackText}</Translate>
        </span>
    )
}

export default LabelSticker;