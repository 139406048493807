import { AppContext } from "contexts/AppContext"
import React, { useEffect, useState, useContext } from 'react'
import { callAPI } from "utils/API";
import {styled,Paper,Table,TableBody,TableRow,TableContainer,TableCell,TableHead,tableCellClasses} from '@mui/material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      border:'none'
    },
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight:'bolder'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Team = () => {
    const [teamData, setTeamData] = useState([])

    const getTeamData = async()=> {
        let getAllSubordinateUsersOfBrand = await callAPI('/supervisors/getAllSubordinateUsersOfBrand', "GET");
        setTeamData(getAllSubordinateUsersOfBrand)
    }

    useEffect(() => {
    getTeamData()
    }, [])
    
    return (
        <TableContainer sx={{marginTop:'35px', boxShadow:'none'}}component={Paper}>
            <Table sx={{ minWidth: 700}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell align="left">UID</StyledTableCell>
                        <StyledTableCell align="left">Navn</StyledTableCell>
                        <StyledTableCell align="left">Mobile</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="left">Point</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teamData?.length?teamData.map((data,idx) => (
                        <StyledTableRow hover key={idx}>
                            <StyledTableCell component="th" scope="row">
                                {data.UserID}
                            </StyledTableCell>
                            <StyledTableCell align="left">{data.userUID}</StyledTableCell>
                            <StyledTableCell align="left">{data.userName}</StyledTableCell>
                            <StyledTableCell align="left">{data.userPhone}</StyledTableCell>
                            <StyledTableCell align="left">{data.userEmail}</StyledTableCell>
                            <StyledTableCell align="left">{data.points}</StyledTableCell>
                        </StyledTableRow>
                    )):null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default Team;
