import Translate from "utils/Translate";
import React, { useContext, useState } from 'react'
import { upgColors } from 'styles/styles'
import ArrowRightAlt from 'components/icons/ArrowRightAlt';
import './css/ButtonStyles.css'
import Tag from 'components/icons/Tag';
import Close from 'components/icons/Close';
import { AppContext } from 'contexts/AppContext';

const LabelTag2 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const showIcon = (typeof props.showIcon === 'boolean') ? props.showIcon : true
    const showClose = (typeof props.showClose === 'boolean') ? props.showClose : true
    const LabelTag2Style = {
        fontFamily: styleGuide.secondaryFontFamily,
        color: styleGuide.color7,
        backgroundColor: styleGuide.color3,
        borderRadius: styleGuide.buttonBorderRadius,
        height: styleGuide.labelTagHeight,
        fontWeight: '600',
        border: 'none',
        fontSize: styleGuide.labelTagFontSize,
        padding: '5px 14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'max-content'
    }

    return (
        <span
            style={{...LabelTag2Style, ...props.style}}
            className={props.className}
            onClick={props.onClick}
        >   
            {showIcon 
                ?   props.icon 
                        ? props.icon
                        : <Tag style={{ marginRight:'10px', height: 10, width: 10 }}  fill={upgColors.white} />
                :   null
            }
            <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>
            {showClose 
                ?   <Close onClick={props.closeHandler} style={{ marginLeft: '12px', height: 10, width: 10 }}  fill={upgColors.grayDarkest} />
                :   null
            }
        </span>
    )
}

export default LabelTag2;