import { useCallback, useEffect, useRef, useState } from "react"

const Image = (props) => {
  const modifiedSrc = props.src.replace("cdn.upgrader.club", "cdn.upgraderadmin.dk");
  return (props.type === 'img')
    ? <NativeImage onClick={props.onClick} className={props.className} src={modifiedSrc} style={ props.style } />
    : <BackgroundImage onClick={props.onClick} size={props.size} className={props.className} src={modifiedSrc} style={props.style}>{ props.children }</BackgroundImage>
}

const NativeImage = (props) => {
  return (
    <img loading="lazy" onClick={props.onClick} className={props.className} style={{...props.style}} src={props.src} />
  )
}

const BackgroundImage = (props) => {
  const {className, onClick, src, position, size, style, children, lazy} = props
  // const [showImage, setShowImage] = useState(false)
  const [source, setSource] = useState("");
  const imageRef = useRef(null);

  const callback = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setSource(src);
    }
  }, [src]);

  useEffect(() => {
    // if(lazy === undefined) return setSource(src);
    
    const observer = new IntersectionObserver(callback, {rootMargin: "150px"});
    observer.observe(imageRef.current);
    return () => {
      observer.disconnect();
    };
  }, [callback, imageRef]);

  // useEffect(() => {
  //   if(className?.includes('lazy-visible')) return setShowImage(true)
  // },[className])

  // useEffect(() => console.log(showImage, className, src), [showImage])

  return (
    <div 
    ref={imageRef}
    onClick={onClick}
    className={className || ''}
    style={{
      backgroundRepeat: 'no-repeat',
      backgroundImage: source,
      backgroundPosition: position || 'center 0',
      backgroundSize: size || 'cover',
      ...style
    }}>
      {children}
    </div>
  )
}

export default Image;