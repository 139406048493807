const LinkedIn = (props) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';
    return (
        <svg style={props.style} width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.62995 22.6354H0.63061V7.5909H5.62995V22.6354ZM3.13028 5.53727H3.09311C1.42047 5.53727 0.333252 4.37571 0.333252 2.93538C0.333252 1.45788 1.45764 0.333496 3.16745 0.333496C4.87726 0.333496 5.92731 1.45788 5.96448 2.93538C5.96448 4.37571 4.87726 5.53727 3.13028 5.53727ZM23.6666 22.6354H18.6673V14.5881C18.6673 12.5624 17.9424 11.1871 16.1304 11.1871C14.7458 11.1871 13.9281 12.1163 13.5657 13.0177C13.4356 13.3429 13.3984 13.789 13.3984 14.235V22.6354H8.3991C8.3991 22.6354 8.46414 9.00335 8.3991 7.5909H13.3984V9.71887C14.0582 8.6967 15.2476 7.22849 17.9053 7.22849C21.1948 7.22849 23.6666 9.37505 23.6666 14.0027V22.6354Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth}/>
        </svg>
        
    )
}
export default LinkedIn

