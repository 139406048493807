import { callAPI } from "./API"

const Activity = (props) => {
    // activityType: click, 
    // activityName: post/shop/product, 
    // activityArgument: subActivity like FAQ for Post activity (optional), 
    // activityArgumentID: PostID/BlogID/ShopID
    const {activityType, activityName, activityArgument, activityArgumentID} = props
    callAPI('/activity','POST', {body: {activityType, activityName, activityArgument, activityArgumentID}})
}

export default Activity;