import React from 'react'
import { AppContext } from "contexts/AppContext";
import { useEffect, useContext, useState } from 'react'
import { callAPI } from "utils/API";
import "./Register.css"
import { translate } from 'utils/Translate';


const RegisterForm = ({setError,setSuccess}) => {
    const { styleGuide, brandData } = useContext(AppContext);
    const [departments,setDepartments]=useState([])
    const [fieldForm, setFieldForm] = useState({phoneCountryCode:'+45'})
    const [validFields, setValidFields] = useState({
        name:'',
        email:'',
        mobilePhoneNumber:'',
        companyAddress:'',
        companyPostalCode:'',
        companyCity:'',
        companyDepartment:'',
        termsAndConditionsAccepted:'',
        phoneCountryCode:'+45',
        userUID:'',
        userCompanyUID: ''
    });
    const [isFormValid, setIsFormValid] = useState();
    const [showErrors, setShowErrors] = useState(false);
    
    const description = brandData && brandData.features.registerUser && brandData.features.registerUser.description;
    const header=brandData && brandData.features.registerUser && brandData.features.registerUser.header
    const consent = brandData && brandData.features.registerUser && brandData.features.registerUser.consent;
    const isOkBrand = brandData && brandData.BrandID === 187 // for OK brand
    
    const getUserDepartments = async() => {
        let result = await callAPI('/user/departments', 'GET')
        if (result) {
            setDepartments(result)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (isFormValid) {
            const {
                name,
                email,
                mobilePhoneNumber,
                companyAddress,
                companyPostalCode,
                companyCity,
                companyDepartment,
                termsAndConditionsAccepted,
                phoneCountryCode,
                userUID,
                userCompanyUID
            } = fieldForm
            const body = {
                userName: name,
                userEmail: email.trim(),
                userPhone: `${phoneCountryCode || '+45'}${mobilePhoneNumber}`.trim(),
                userAddress: companyAddress,
                userZipcode: companyPostalCode,
                userCity: companyCity,                
                termsAndConditionsAccepted,
                userUID
            }
            if(isOkBrand){
                body.userCompany = companyDepartment
                body.userCompanyUID = userCompanyUID
            }else{
                body.userDepartment = companyDepartment
            }
            const response = await callAPI(`/user/register`, "POST", {body});
            if (response.status === 'ok') {
                setSuccess(true)
            }else {
                setError(true)
            }
        } else {
            setShowErrors(true)
        }
    }
    

    const handleInputChange = (e) => {
        var target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const data = { ...fieldForm, [target.name]: value };
        setFieldForm(data); 
        validateForm(data)
    }

    const getPublicCVRInformation = async (userCompanyUID) => {
        if (!userCompanyUID) {
            console.log("No CVR number provided");
            return;
        }

        try {
            let companyInformation = await callAPI(`/cvr/${userCompanyUID}`, "GET");
            
            if (!companyInformation) {
                console.log("No data could be found");
            } else {
                const cvrFields = {
                    ...fieldForm,
                    companyAddress: companyInformation.address,
                    companyPostalCode: companyInformation.zipcode,
                    companyCity: companyInformation.city,
                    companyDepartment: companyInformation.name,
                };

                const data = {...fieldForm, ...cvrFields};

                setFieldForm(data); 
                validateForm(data)
            }
        } catch (error) {
            console.log("Error fetching CVR information", error);
        }
    };

    const validateForm = (data) => {
        const {
            name,
            email,
            mobilePhoneNumber,
            companyAddress,
            companyPostalCode,
            companyCity,
            companyDepartment,
            termsAndConditionsAccepted,
            phoneCountryCode,
            userUID,
            userCompanyUID
        } = data
    
        const userNameRegex = /^([^0-9]*)$/;
        const phoneNumberRegex = /^([+]?)([0-9]*)$/
        const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
    
        const validfields = {
            name: !!name && userNameRegex.test(name),
            email: !!email && !!email.match(emailRegex),
            mobilePhoneNumber: !!mobilePhoneNumber && phoneNumberRegex.test(mobilePhoneNumber),
            phoneCountryCode: phoneCountryCode ? phoneNumberRegex.test(phoneCountryCode) : true, // empty is also valid will auto add +45 on submit
            companyAddress: !!companyAddress,
            companyPostalCode: !!companyPostalCode,
            companyCity: !!companyCity,
            companyDepartment: !!companyDepartment,
            termsAndConditionsAccepted: !!termsAndConditionsAccepted
        }
        if(!isOkBrand){
            validfields.userUID = !!userUID
        }

        if(userCompanyUID && userCompanyUID.length == 8) {
            getPublicCVRInformation(userCompanyUID);
        } 

        const isformvalid = Object.keys(validfields).reduce((accumulator, currentKey) => {
            return accumulator && validfields[currentKey]
        }, true)
        setIsFormValid(isformvalid)
        setValidFields(validfields)
    }

    useEffect(() => {
        if(!isOkBrand){
            getUserDepartments()
        }
    }, [])

    return (
        <div style={{ minHeight: "100vh" }} className="register_div">
            {!!header && <div style={{margin:'auto',width:'70%',justifyContent:'center'}}>
                <h5 className="register_headline">{header}</h5>
            </div>}
            {!!description && <div>
                <div style={{ width: "45px", height: "2px", backgroundColor: "#2e2e2e", margin: "auto", marginBottom: "1rem", marginTop: "1.5rem" }}></div>
                <h6
                    className='description_message'
                    dangerouslySetInnerHTML={{ __html: description }}>
                </h6>
                <div style={{ width: "45px", height: "2px", backgroundColor: "#2e2e2e", margin: "auto", }}></div>
            </div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <div>
                        <label className="label_style" for='register-form-name'>{translate("_REGISTER_FORM_NAME_LABEL_", "Navn:")}</label>
                        <input name="name" placeholder={translate("_REGISTER_FORM_NAME_PLACEHOLDER_", "Navn")} type="text" className={showErrors && !validFields.name?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)}/>
                    </div>
                    {!isOkBrand && <div>
                        <label className="label_style" for='register-form-name'>{translate("_REGISTER_FORM_UID_LABEL_", "Medarbejdernummer")}</label>
                        <input name="userUID" id='register-form-name' placeholder={translate("_REGISTER_FORM_UID_PLACEHOLDER_", "Medarbejdernummer")} type="text" className={showErrors && !validFields.userUID?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)}/>
                    </div>}
                    <div>
                        <label className="label_style" for='register-form-email'>{translate("_REGISTER_FORM_EMAIL_LABEL_", "Arbejds e-mail:")}</label>
                        <input name="email" id='register-form-email' placeholder={translate("_REGISTER_FORM_EMAIL_PLACEHOLDER_", "Arbejds e-mail")} type="text" className={showErrors && !validFields.email?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)}/>
                    </div>
                    <div>
                        <label className="label_style" for='register-form-mobilePhoneNumber'>{translate("_REGISTER_FORM_MOBILE_LABEL_", "Mobilnummer:")}</label>
                        <div className="mobile_controller ">
                            <div>
                                <input name="phoneCountryCode" id='register-form-phoneCountryCode' disabled type="text" className="form-control country_code" value={"+45"} />
                            </div>
                            <div className='mobile_input'>
                                <input name="mobilePhoneNumber" id='register-form-mobilePhoneNumber' placeholder={translate("_REGISTER_FORM_MOBILE_PLACEHOLDER_", "Mobilnummer")} type="text" maxLength={8} className={showErrors && !validFields.mobilePhoneNumber?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                    </div>
                    {!!isOkBrand && <div>
                        <label className="label_style" for='register-form-companyUID'>{translate("_REGISTER_FORM_CVR_LABEL_", "CVR:")}</label>
                        <input name="userCompanyUID" id='register-form-companyUID' placeholder={translate("_REGISTER_FORM_CVR_PLACEHOLDER_", "CVR")} type="text" className="form-control" onChange={(e)=>handleInputChange(e)} />
                    </div>}
                    <div>
                        <label className="label_style" for='register-form-companyDepartment'>{translate("_REGISTER_FORM_COMPANY_LABEL_", "Virksomhed:")}</label>
                        <div>
                            {!isOkBrand ? <select style={{width:'100%'}} name="companyDepartment" id='register-form-companyDepartment' placeholder="Afdeling" className={showErrors && !validFields.companyDepartment?"is_invalid":"form-control selector_div"} onChange={(e)=>handleInputChange(e)}>
                                <option>Vælg afdeling</option>
                                {departments && departments.length && (departments.map((e) => <option value={e.departmentName}>{ e.departmentName}</option>))}
                            </select> :
                                <input name="companyDepartment" value={fieldForm.companyDepartment} id='register-form-companyDepartment' placeholder={translate("_REGISTER_FORM_COMPANY_PLACEHOLDER_", "Virksomhed")} type="text" className={showErrors && !validFields.companyDepartment?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)} />
                            }
                        </div>
                    </div>
                    {!isOkBrand && <div>
                        <label className="label_style" for='register-form-companyAddress'>{translate("_REGISTER_FORM_ADDRESS_LABEL_", "Adresse:")}</label>
                        <input name="companyAddress"  value={fieldForm.companyAddress}  id='register-form-companyAddress' placeholder={translate("_REGISTER_FORM_ADDRESS_PLACEHOLDER_", "Adresse")} type="text" className={showErrors && !validFields.companyAddress?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)} />
                    </div>}
                    {!!isOkBrand && <div>
                        <label className="label_style" for='register-form-companyAddress'>{translate("_REGISTER_FORM_ADDRESS_LABEL_", "Virksomhedens adresse:")}</label>
                        <input name="companyAddress"  value={fieldForm.companyAddress}  id='register-form-companyAddress' placeholder={translate("_REGISTER_FORM_ADDRESS_PLACEHOLDER_", "Virksomhedens adresse")} type="text" className={showErrors && !validFields.companyAddress?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)} />
                    </div>}
                    <div className="mobile_controller">
                        <div>
                            <label className="label_style" for='register-form-companyPostalCode'>{translate("_REGISTER_FORM_POSTNUMBER_LABEL_", "Postnr:")}</label>
                            <input name="companyPostalCode" value={fieldForm.companyPostalCode}  id='register-form-companyPostalCode' placeholder={translate("_REGISTER_FORM_POSTNUMBER_PLACEHOLDER_", "Postnr")} type="text" className={showErrors && !validFields.companyPostalCode?"is_invalid":"form-control postal_div"} onChange={(e)=>handleInputChange(e)} />
                        </div>
                        <div className='address_div'>
                            <label className="label_style" for='register-form-companyCity'>{translate("_REGISTER_FORM_CITY_LABEL_", "By:")}</label>
                            <input name="companyCity" value={fieldForm.companyCity} id='register-form-companyCity' placeholder={translate("_REGISTER_FORM_CITY_PLACEHOLDER_", "By")} type="text" className={showErrors  && !validFields.companyCity?"is_invalid":"form-control"} onChange={(e)=>handleInputChange(e)} />
                        </div>
                    </div>
                    <div className='mobile_controller'>
                        <div>
                            <input required name="termsAndConditionsAccepted" id='register-form-rules-checkbox' type="checkbox" className="custom-control-input" onChange={(e)=>handleInputChange(e)} />
                        </div>
                        <div>
                            <label className="terms_condition" for='register-form-rules-checkbox'>
                                {!!consent 
                                ?  <h6 className='description_message' dangerouslySetInnerHTML={{ __html: consent }} />
                                : 'Jeg giver hermed samtykke til at Tryg må videregive mine oplysninger til brug i Tryg Pointshop (navn, adresse, medarbejdernummer,telefonnummer, e-mail og afdeling) til brug for oprettelse, administration, tildeling af point samt eventuel overførsel af point fratidligere pointshop'}
                            </label>
                        </div>
                    </div>
                    <div className="black_button_div">
                        <button style={{ backgroundColor: styleGuide.color16 }} className="standardBtn_black">{translate("_REGISTER_FORM_BUTTON_LABEL_", "Indsend")}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default RegisterForm