import { AppContext } from "contexts/AppContext"
import { useContext, useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { starkMsalConfig } from "utils/msalAuthConfig";
import { PublicClientApplication } from '@azure/msal-browser';

const msalInstance = new PublicClientApplication(starkMsalConfig);

const Logout = (props) => {
    const navigate = useNavigate();
    const {setUserData, setMenuData, userData} = useContext(AppContext)
    useEffect(() => {
        const token = localStorage.getItem('accessToken')
        if(userData && userData.UserID && !token) {
            setUserData(null)
        }

        if (userData.BrandID === 87) {
            msalInstance.logoutRedirect({
                postLogoutRedirectUri: "/login"
            });
        }
    }, [userData, setUserData, navigate]);

    return <Navigate to="/login" />;
}
export default Logout 