import Headline2 from "components/elements/Headline2";
import RoundIconButton2 from "components/elements/RoundIconButton2"
import ArrowLeft from "components/icons/ArrowLeft";
import ArrowRight from "components/icons/ArrowRight";
import { useContext, useEffect, useState } from "react";
import { callAPI } from "utils/API";
import { createUseStyles } from "react-jss";
import { AppContext } from "contexts/AppContext";
import Translate, { translate } from "utils/Translate";
import Headline3 from "components/elements/Headline3";
import ButtonLarge4 from "components/elements/ButtonLarge4";
import ExplainerText from "components/elements/ExplainerText";
import ProgressBarWithTooltip from "components/blocks/ProgressBarWithTooltip/ProgressBarWithTooltip";
import moment from 'moment'
import { months } from 'utils/Translate'
import { Area, AreaChart, CartesianGrid, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./Profile.css"
import { pointsConvertor } from "utils/general";

const jss = createUseStyles({
  RoundButton: {
    backgroundColor: (styleGuide) => styleGuide.color16,
    height: 60,
    width: 60,
    marginLeft: 8,
    marginRight: 8,
    '&:disabled': {
      backgroundColor: (styleGuide) => styleGuide.color4
    }
  },
  StatBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 35px',
    width: '204px',
    height: '134px',
    background: 'white',
    boxShadow: (styleGuide) => `0px 0px 70px #C4C4C4`
  },
  StatBoxArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    background: 'white',
    transform: 'matrix(0.71, -0.7, 0.72, 0.71, 0, 0)',
    marginTop: '-16px',
    marginLeft: '86px',
    boxShadow: (styleGuide) => `-1px 1px 0px ${styleGuide.color3}`
  }
});



export const StatisticSection = (props) => {
  const { styleGuide, langCode, brandData, userData, setUserData, accessibleShops, themeConfig } = useContext(AppContext);
  const classes = jss(styleGuide)
  const [totalGraphData, setTotalGraphData] = useState(null)
  const [graphData, setGraphData] = useState([])
  const [monthlyResults, setMonthlyResults] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [personalBest, setPersonalBest] = useState(0)
  const [currentMonth, setCurrentMonth] = useState(0)
  const [userGoals, setUserGoals] = useState(null)
  const [monthName, setMonthName] = useState('');
  const [yearName, setYearName] = useState('');
  
  const monthNumber1MonthAgo = moment().subtract(0, 'month').month()
  const monthNumber2MonthsAgo = moment().subtract(1, 'month').month()
  const monthNumber3MonthsAgo = moment().subtract(2, 'month').month()
  const pointSuffix = translate("_POINTS_SHORT_", "pt")
  const statisticsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.statisticsSectionLabels
  ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.statisticsSectionLabels : null

  const monthLabels = [
    { configuredLabel: statisticsSectionLabels?.subtitlePageOneText, label: 'For sidste måned', key: '_PROFILE_STATISTICS_BOX_LABEL_MONTH1_' },
    { configuredLabel: statisticsSectionLabels?.subtitlePageTwoText, label: 'For de sidste 2 måneder', key: '_PROFILE_STATISTICS_BOX_LABEL_MONTH2_' },
    { configuredLabel: statisticsSectionLabels?.subtitlePageThreeText, label: 'For de sidste 3 måneder', key: '_PROFILE_STATISTICS_BOX_LABEL_MONTH3_' }]

  const fetchStatisticsData = async () => {
    let results = await callAPI('user/getStatisticsData', 'GET')
    if (results?.month) {
      setMonthName(results.month)
    }
    if (results?.year) {
      setYearName(results.year)
    }
    if (results.monthlyResults?.length) {
      setMonthlyResults(results.monthlyResults[0])
      if (results.monthlyResults[0]?.totalReceivedCurrentMonth) {
        setCurrentMonth(results.monthlyResults[0].totalReceivedCurrentMonth)
      }
      setTotalGraphData(results.monthlyGraphData)
    }
    if (results?.personalBest) {
      setPersonalBest(results.personalBest)
    }
  }

  const fetchUserGoalsData = async () => {
    let result = await callAPI('user/getUserGoals', 'GET')
    if (result) {
      setUserGoals(result)
    }
  }

  const handlePageClick = (nextPage) => {
    if (nextPage) {
      setCurrentPage(currentPage + 1)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  const prevMonthYear = moment().subtract(1, 'months').year();
  const prev2MonthYear = moment().subtract(2, 'months').year();
  const prev3MonthYear = moment().subtract(3, 'months').year();

  const prevMonth = moment().subtract(1, 'month').month()
  const prev2Month = moment().subtract(2, 'month').month()
  const prev3Month = moment().subtract(3, 'month').month()

  // const graphBottomGradient = styleGuide.color16 + '80'
  const graphBottomGradient = styleGuide.color16 + 'CC'

  useEffect(() => {
    fetchStatisticsData()
    fetchUserGoalsData()
  }, [])

  useEffect(() => {
    if (!totalGraphData) return setGraphData([])

    const data = []
    if (currentPage >= 2) {
      data.push(totalGraphData[monthNumber3MonthsAgo])
    }
    if (currentPage >= 1) {
      data.push(totalGraphData[monthNumber2MonthsAgo])
    }
    if (currentPage >= 0) {
      data.push(totalGraphData[monthNumber1MonthAgo])
    }
    return setGraphData(data.flat(2))
  }, [currentPage, totalGraphData])

  const GraphBlock = (props) => {
    const { styleGuide } = useContext(AppContext)
    const splitToChunks = (originalArray, parts) => {
      let result = [];
      const array = [...originalArray]
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
    }

    let data = splitToChunks(graphData, currentPage+1).map(item => item.sort((a, b) => b - a)[0])
    data = data.flatMap((point, idx) => {
      return { point: point || 1, idx: idx + 1 }
    })
    // if(data && data[0] && !data[0].point) data[0] = {point: 1, idx: 0}
    data.splice(0, 0, { point: 1, idx: 0 });
    data.splice(data.length, 0, { point: 1, idx: 0 });

    const graphLabel = (props) => {
      if(!props) return null
      return [0, data.length-1].includes(props.index || null) ? null : props.value || 'nil'
    }

    return !graphData || !graphData.length ? null : <>
      <div id='graph-container'>
      <ResponsiveContainer width="100%" height='100%' >
        <AreaChart data={data}
          margin={{ top: 200, right: 0, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorArea" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={styleGuide.color16} stopOpacity={1} />
              <stop offset="95%" stopColor={styleGuide.color16} stopOpacity={0.05} />
            </linearGradient>
          </defs>
          <XAxis hide dataKey="idx" axisLine={false} tickLine={false} />
          <YAxis hide dataKey="point" />
          
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <Tooltip /> */}
          <Area animationDuration={500} connectNulls={false} type="monotone" dataKey="point" stroke={styleGuide.color16} fillOpacity={1} fill="url(#colorArea)" label={() => graphLabel()}>
          </Area>
        </AreaChart>
        </ResponsiveContainer>
        </div>
    </>
  }

  return <>
    <div id={props?.id || ""} ref={props.staticRef} style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop:  !!brandData?.features?.displayProfilePointGrid && !!brandData?.features?.displayProfilePointExpiryGrid && !!brandData?.features?.displayProfileTaxGrid ? 100 : 200, marginBottom: themeConfig.disableWishlist ? 350 : 100, minHeight: 600 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', zIndex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
          <Headline2 configuredLabel={statisticsSectionLabels?.titleText} translationKey='_PROFILE_PAGE_STATISTIC_' fallbackText='Din statistik' style={{ marginTop: 16 }} />
          <Headline3 configuredLabel={monthLabels[currentPage].configuredLabel} translationKey={monthLabels[currentPage].key} fallbackText={monthLabels[currentPage].label} style={{ marginTop: 4, color: styleGuide.color5 }} />
        </div>
        <div style={{ display: 'flex', padding: 8, flexDirection: 'row' }}>
          <RoundIconButton2 icon={<ArrowLeft fill="white" />} className={classes.RoundButton} disabled={currentPage <= 0} onClick={() => handlePageClick(false)} />
          <RoundIconButton2 icon={<ArrowRight fill="white" />} className={classes.RoundButton} disabled={currentPage >= 2} onClick={() => handlePageClick(true)} />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-around', width: "100%", marginTop: 32, position: 'relative' }}>
        <div id='graph-block' className="graph-block"
          style={{
            minHeight: 700,
            // background: `linear-gradient(to bottom, transparent 71%, ${graphBottomGradient} 71.5%, transparent 100%)`
          }}
        >
        <GraphBlock />
          <div className='goal-container'>
            {brandData?.features?.enableUserPersonalRecord && <div className='div-one'>
              <div className='personal-record-box'>
                <ExplainerText style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px' }} configuredLabel={statisticsSectionLabels?.personalRecordTitleText}
                  fallbackText={currentMonth > personalBest ? translate('_NEW_PERSONAL_RECORD_', 'Ny personlige rekord!') : translate('_BEAT_PERSONAL_RECORD_', 'Slå din personlige rekord')} /><br />
                <ExplainerText style={{ color: '#8C8C8C', lineHeight: '18px' }} > {statisticsSectionLabels?.personalRecordSubtitleText || translate('_PERSONAL_RECORD_', `Personlig rekord`)}
                  {!!monthName && <ExplainerText style={{ color: '#8C8C8C', lineHeight: '18px' }} translationKey={months[monthName-1].key} fallbackText={`: ${months[monthName-1].fallbackText} `} />}
                  {!!yearName && <ExplainerText style={{ color: '#8C8C8C', lineHeight: '18px' }} fallbackText={translate('_YEAR_', `${yearName}`)} />}
                </ExplainerText>
                <div className='received-points'>
                  <Headline3 style={{ color: styleGuide.color16, lineHeight: '70px', fontSize: '40px', fontWeight: 600 }} translationKey=''>{pointsConvertor(brandData,currentMonth || `0`, pointSuffix)}</Headline3>
                  </div>
                </div>
              <div style={{ width: '100%', height: '40px' }}>
                <ProgressBarWithTooltip min={monthlyResults?.totalReceivedCurrentMonth || '0'} max={personalBest} />
              </div>
            </div>}
            {!!userGoals && brandData?.features?.enableUserGoal && <div className='div-two'>
          <div className='goal-box'>
              <div className='goal-setting'>
              <ExplainerText style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px' }} configuredLabel={statisticsSectionLabels?.personalGoalTitleText} fallbackText={translate('_THE_PERSONAL_GOAL_', 'Det personlige mål')} /><br/>
              <ExplainerText style={{ color: styleGuide.color6, lineHeight: '18px' }} configuredLabel={statisticsSectionLabels?.personalGoalSubtitleText} fallbackText={translate('_MONTHLY_GOAL_', `Denne måneds mål: ${pointsConvertor(brandData,userGoals?.currentMonth || 0 , pointSuffix)}`)} />
            </div>
            <div className='received-points'>
              <Headline3 style={{ color: styleGuide.color16, lineHeight: '70px', fontSize: '40px', fontWeight: 600 }} translationKey=''>{`${pointsConvertor(brandData,monthlyResults?.totalReceivedCurrentMonth || '0', pointSuffix)}`}</Headline3>
            </div>
            </div>
            <div style={{width: '100%', height: '40px'}}>
              <ProgressBarWithTooltip min={monthlyResults?.totalReceivedCurrentMonth || '0'} max={ userGoals?.currentMonth || '0'}/>
            </div>
        </div>}
      </div>
    </div>
        {currentPage > 1 &&
          <div style={{ flexDirection: 'column' }}>
            <div className={classes.StatBox}>
              <ExplainerText style={{ color: styleGuide.color6,lineHeight: '20px',fontSize:'14px'}} translationKey={months[prev3Month].key}>{months[prev3Month].fallbackText}
                <ExplainerText style={{ color: styleGuide.color6,lineHeight: '20px',fontSize:'14px' }} translationKey=''>, {prev3MonthYear}</ExplainerText>
              </ExplainerText>
              <Headline3 style={{ color: styleGuide.color16 }} translationKey=''>{monthlyResults ? monthlyResults.totalReceived3MonthsAgo || '0' : '0'}</Headline3>
              {brandData && brandData.features && brandData.features.KPI && <ExplainerText style={{ color: styleGuide.color16, lineHeight: '20px',fontSize:'14px' }} fallbackText={translate('_TWO_MONTHs_AGO_GOAL_', `Mål: ${userGoals?.last3Month || 0 }`)} />}</div>
            <div className={classes.StatBoxArrow}></div>
          </div>}

        {currentPage > 0 &&
          <div style={{ flexDirection: 'column' }}>
            <div className={classes.StatBox}>
              <ExplainerText style={{ color: styleGuide.color6,lineHeight: '20px',fontSize:'14px' }} translationKey={months[prev2Month].key}>{months[prev2Month].fallbackText}
                <ExplainerText style={{ color: styleGuide.color6,lineHeight: '20px',fontSize:'14px' }} translationKey=''>, {prev2MonthYear}</ExplainerText>
              </ExplainerText>
              <Headline3 style={{ color: styleGuide.color16 }} translationKey=''>{monthlyResults ? monthlyResults.totalReceived2MonthsAgo || '0' : '0'}</Headline3>
              {brandData && brandData.features && brandData.features.KPI && <ExplainerText style={{ color: styleGuide.color16, lineHeight: '20px',fontSize:'14px' }} fallbackText={translate('_LAST_MONTH_GOAL_', `Mål: ${userGoals?.last2Month || 0 }`)} />}
            </div>
            <div className={classes.StatBoxArrow}></div>
          </div>}

        <div style={{ flexDirection: 'column' }}>
          <div className={classes.StatBox}>
            <ExplainerText style={{ color: styleGuide.color6,lineHeight: '20px',fontSize:'14px'}} translationKey={months[prevMonth].key}>{months[prevMonth].fallbackText}
              <ExplainerText style={{ color: styleGuide.color6,lineHeight: '20px',fontSize:'14px'}} translationKey=''>, {prevMonthYear}</ExplainerText>
            </ExplainerText>
            <Headline3 style={{ color: styleGuide.color16 }} translationKey=''>{monthlyResults ? monthlyResults.totalReceivedLastMonth || '0' : '0'}</Headline3>
            {brandData && brandData.features && brandData.features.KPI && <ExplainerText style={{ color: '#999999', lineHeight: '20px',fontSize:'14px' }} fallbackText={translate('_CURRENT_MONTH_GOAL_', `Mål: ${userGoals?.lastMonth || 0 }`)} />}
          </div>
          <div className={classes.StatBoxArrow}></div>
        </div>


      </div>
    </div>
  </>
}