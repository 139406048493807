import MUICheckbox from '@mui/material/Checkbox'; // MUICheckbox is a custom alias for the default export
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckboxFilled, CheckboxEmpty } from 'components/icons/Checkbox';
import { AppContext } from 'contexts/AppContext';
import { useContext } from 'react';

const CustomCheckbox = (props) => {
    const {styleGuide} = useContext(AppContext)
    const {className, label, checked, onChange} = props
    return <FormControlLabel 
        style={props.style}
        label={label || <span style={{marginRight: 0}}></span>}
        control={
            <MUICheckbox 
                disabled={props.disabled || false}
                checked={checked}
                onChange={onChange}
                className={className || ''} 
                icon={<CheckboxEmpty />} 
                checkedIcon={<CheckboxFilled style={{opacity: props.disabled ? 0.5 : 1}} fill={styleGuide.color16} />} 
            />
        } 
    />
}

export default CustomCheckbox

