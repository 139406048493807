import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "contexts/AppContext";
import Image from "components/elements/Image";
import Headline3 from "components/elements/Headline3";
import CustomInput from "components/elements/CustomInput";
import CustomSelect from "components/elements/CustomSelect";
import { translate } from "utils/Translate";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import { callAPI } from "utils/API";
import moment from "moment";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ContentNormal from "components/elements/ContentNormal";
import RoundIconButton1 from "components/elements/RoundIconButton1";
import CaretDown from "components/icons/CaretDown";
import TransactionTable, { SimpleTable } from "components/blocks/TransactionTable/TransactionTable";
import Headline4 from "components/elements/Headline4";

let initReport = {
  claimDate: moment().format("YYYY-MM-DD"),
  CustomerProductID: "",
  customerName: "",
  financeSum: "",
  reference: "",
};

const Report = () => {
  const contextState = useContext(AppContext);
  const { styleGuide, brandData } = contextState;
  const [report, setReport] = useState(initReport);
  const [rewardProduct, setRewardProduct] = useState([]);
  const [claims, setClaims] = useState([]);
  const bannerImageHeight = 560;
  const reportBannerImage =
    brandData.features.reportBannerImageURL ||
    "https://i.imgur.com/NPB3IhS.png";
  const reportTextColor = brandData.features.reportTextColorBlack
    ? styleGuide.color18
    : styleGuide.color1;

  const fetchRewardProducts = async () => {
    let reaction = await callAPI("/reward-products", "GET");
    if (!reaction.length) return setRewardProduct([])
    setRewardProduct(reaction);
  };

  const sendReport = async () => {
    let reaction = await callAPI("/claimsReport", "POST", {
      body: { report },
    });
    if (reaction.protocol41 === true) {
      setReport({
        claimDate: moment().format("YYYY-MM-DD"),
        CustomerProductID: "",
        customerName: "",
        financeSum: "",
        reference: "",
      })
    }
    await fetchClaims()
  };

  const fetchClaims = async () => {
    let claims = await callAPI("/claims", "GET");
    if (!claims.length) return setClaims([])
    claims.sort((a,b) => new Date(b.claimDate).getTime() - new Date(a.claimDate).getTime())
    setClaims(claims);
  }

  const translateClaimsStatus = (status) => {
    if(!status) return ""
    switch (status.toLowerCase()) {
      case "rejected":
        return translate("_CLAIMS_STATUS_REJECTED_","Afvist")
      case "new":
        return translate("_CLAIMS_STATUS_NEW_","Ny")
      case "approved":
        return translate("_CLAIMS_STATUS_APPROVED_","Godkendt")
    
      default:
        return "";
    }
  }

  useEffect(() => {
    fetchRewardProducts();
    fetchClaims();
  }, []);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {!!reportBannerImage && (
          <Image
            src={`url(${reportBannerImage})`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 0,
              height: bannerImageHeight,
              width: "100%",
            }}
          ></Image>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: bannerImageHeight,
          marginRight: 24,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: bannerImageHeight / 1.4,
          }}
        >
          <div>
            <Headline3
              style={{
                fontSize: 60,
                lineHeight: "70px",
                color: reportTextColor,
              }}
            >
              {translate("_CLAIMS_BANNER_TITLE_", "Indberet salg")}
            </Headline3>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 30,
          marginBottom: 80,
        }}
      >
        <div
          className="settingInputbox"
          style={{ display: "flex", gap: 40, width: "50%" }}
        >
          <span style={{ flex: 1 }}>
            <CustomInput
              id="date"
              type="date"
              label={"Dato for salg"}
              defaultValue={moment().format("YYYY-MM-DD")}
              value={report.claimDate}
              onChange={(e) => {
                setReport({ ...report, claimDate: e.target.value });
              }}
            />
          </span>
          <span style={{ flex: 1 }}>
            <CustomSelect
              label={translate("_SMARTGROUP_SELECT_", "Finansiering")}
              value={report.CustomerProductID}
              onChange={(e) =>
                setReport({ ...report, CustomerProductID: e.target.value })
              }
              options={rewardProduct?.map((product) => {
                return {
                  value: product.CustomerProductID,
                  label: product.moduleName,
                };
              })}
            />
          </span>
        </div>
        <div
          className="settingInputbox"
          style={{ display: "flex", gap: 40, width: "50%" }}
        >
          <span style={{ flex: 1 }}>
            <CustomInput
              type="text"
              label={translate("_CLAIMS_CUSTOMER_NAME_LABEL_", "Kundenavn")}
              value={report.customerName}
              onChange={(e) => {
                setReport({ ...report, customerName: e.target.value });
              }}
            />
          </span>
          <span style={{ flex: 1 }}>
            <CustomInput
              type="text"
              label={translate("_CLAIMS_FUND_AMOUNT_LABEL_","Finansieringsbeløb")}
              value={report.financeSum}
              onChange={(e) => {
                setReport({ ...report, financeSum: e.target.value });
              }}
            />
          </span>
        </div>
        <div className="settingInputbox" style={{ width: "50%" }}>
          <CustomInput
            type="text"
            label={translate("_CLAIMS_CONTRACT_NUMBER_LABEL_","Kontraktnummer")}
            value={report.reference}
            onChange={(e) => {
              setReport({ ...report, reference: e.target.value });
            }}
          />
        </div>
        <ButtonLarge1
          showIcon={false}
          translationKey="_SAVE_CHANGES_"
          fallbackText="Indsend"
          style={{ maxWidth: "max-content", marginTop: 30 }}
          onClick={() => {
            sendReport();
          }}
        />
        <Accordion defaultExpanded={true} >
          <AccordionSummary expandIcon={<RoundIconButton1 icon={<CaretDown fill="white" />} />} >
            <Headline4
              translationKey="_CLAIMS_OVERVIEW_SECTION_TITLE_"
              fallbackText="Indberetnings status"
              style={{ padding: "30px 20px" }}
            />
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: "30px",
              borderTop: `1px solid ${styleGuide.color4}`,
              overflow: 'scroll'
            }}
          >
            {
              !!claims.length
                ? <SimpleTable
                  headers={[
                    { className: "th tcol-2", value: translate("_CLAIMS_OVERVIEW_HEADER_DATE_", "Date") },
                    { className: "th tcol-2", value: translate("_CLAIMS_OVERVIEW_HEADER_TYPE_", "Type") },
                    { className: "th tcol-2", value: translate("_CLAIMS_OVERVIEW_HEADER_CUSTOMER_", "Customer Name") },
                    { className: "th tcol-2", value: translate("_CLAIMS_OVERVIEW_HEADER_AMOUNT_", "Finance amount") },
                    { className: "th tcol-2", value: translate("_CLAIMS_OVERVIEW_HEADER_CONTRACT_", "Contract number") },
                    { className: "th tcol-2", value: translate("_CLAIMS_OVERVIEW_HEADER_STATUS_", "Status") },
                  ]}
                  rows={claims.map(c => [
                    { className: "th tcol-2", value: moment(c.claimDate).format("DD/MM/YYYY") },
                    { className: "th tcol-2", value: c.customerProductName },
                    { className: "th tcol-2", value: c.customerName },
                    { className: "th tcol-2", value: c.financeSum },
                    { className: "th tcol-2", value: c.reference },
                    { className: "th tcol-2", value: translateClaimsStatus(c.saleStatus) }
                  ])
                  }
                />
                : <ContentNormal 
                  translationKey="_CLAIMS_SECTION_NO_CLAIMS_TEXT_"
                  fallbackText="Der er ingen indberetninger endnu"
                />
            }
          </AccordionDetails>
        </Accordion>


      </div>
    </>
  );
};

export default Report;
