const Heart2 = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';
    return (
        <svg style={props.style} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 14L6.84 12.9929C2.72 9.42997 0 7.08011 0 4.19619C0 1.84632 1.936 0 4.4 0C5.792 0 7.128 0.617984 8 1.59455C8.872 0.617984 10.208 0 11.6 0C14.064 0 16 1.84632 16 4.19619C16 7.08011 13.28 9.42997 9.16 13.0005L8 14Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth}/>
        </svg>
    )
}
export default Heart2
