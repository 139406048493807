import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const ContentSmall = (props) => {
    const { styleGuide } = useContext(AppContext)
    const contentSmallStyle = {
      fontFamily: styleGuide.secondaryFontFamily,
      fontWeight: styleGuide.contentSmallFontWeight,
      fontSize: styleGuide.contentSmallFontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.contentSmallLineHeight,
      color: styleGuide.color7
    }
  
    return (
      <span onClick={props.onClick} style={{...contentSmallStyle, ...props.style}} className={props.className}>
        {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate renderHtml={props.renderHtml || false} translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
      </span>
    )
}

export default ContentSmall;