import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const QuoteLarge = (props) => {
  const { styleGuide } = useContext(AppContext)
    const includeQuotes = (typeof props.includeQuotes === 'boolean') ? props.includeQuotes : true
    const quoteLargeStyle = {
      fontFamily: styleGuide.secondaryFontFamily,
      fontWeight: styleGuide.quoteLargeFontWeight,
      fontSize: styleGuide.quoteLargeFontSize,
      fontStyle: 'italic',
      lineHeight: styleGuide.quoteLargeLineHeight,
      color: styleGuide.color7
    }
    
    return (
      <span onClick={props.onClick} style={{...quoteLargeStyle, ...props.style}} className={props.className} >
        {includeQuotes && `"`}
        <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>
        {includeQuotes && `"`}
      </span>
    )
}

export default QuoteLarge;