import Translate from "utils/Translate";
import { upgColors, fontFamily } from "styles/styles";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";

const Headline6 = (props) => {
  const { styleGuide } = useContext(AppContext)

    const headline6Style = {
      fontFamily: styleGuide.fontFamily,
      fontWeight: styleGuide.headline6FontWeight,
      fontSize: styleGuide.headline6FontSize,
      fontStyle: 'normal',
      lineHeight: styleGuide.headline6LineHeight,
      color: styleGuide.color7
    }

  
    return (
      <span title={props.title || ""} onClick={props.onClick} style={{...headline6Style, ...props.style}} className={props.className}>
        {props.configuredLabel ? <span>{props.configuredLabel}</span> : <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>}
      </span>
    )
}

export default Headline6;