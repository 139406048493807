

const Edit = (props) => {
    const pathFill = props.fill || 'white';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return <>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12.6671V16H3.33287L13.1626 6.17025L9.82975 2.83737L0 12.6671ZM15.74 3.59282C15.8225 3.51061 15.8878 3.41294 15.9324 3.30543C15.977 3.19791 16 3.08265 16 2.96624C16 2.84984 15.977 2.73458 15.9324 2.62706C15.8878 2.51954 15.8225 2.42188 15.74 2.33966L13.6603 0.259951C13.5781 0.177549 13.4805 0.112173 13.3729 0.0675666C13.2654 0.0229603 13.1502 0 13.0338 0C12.9174 0 12.8021 0.0229603 12.6946 0.0675666C12.5871 0.112173 12.4894 0.177549 12.4072 0.259951L10.7807 1.88639L14.1136 5.21927L15.74 3.59282Z" 
            fill={pathFill}/>
        </svg>
    </>
}
export default Edit