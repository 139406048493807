import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
import ButtonMedium1 from "./ButtonMedium1"

const ExportToXlsx = ({excelData, fileName, buttonText, fields}) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"
    const selectedFields = (rows) => rows.map(r => {
        const o = {}
        fields.forEach(f => {
            o[f] = r[f]
        });
        return o
    })

    const downloadData = !fields?.length ? excelData : selectedFields(excelData)
    const exportToExcel = async() => {
        const ws = XLSX.utils.json_to_sheet(downloadData)
        const wb = { Sheets: { "data": ws }, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type: "array"})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }
    return <ButtonMedium1 onClick={exportToExcel} fallbackText={buttonText} />
    
}

export default ExportToXlsx