import { AppContext } from 'contexts/AppContext'
import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'

const jss = createUseStyles({
    roundIconButton3: {
        backgroundColor: (styleGuide) => styleGuide.color1,
        color: (styleGuide) => styleGuide.color7,
        borderRadius: '50%',
        height: (styleGuide) => styleGuide.roundButtonSize,
        width: (styleGuide) => styleGuide.roundButtonSize,
        fontWeight: '600',
        border: 'none',
        padding: '9px 8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& path': {
            fill: (styleGuide) => styleGuide.color7,
        },
        '&:hover':{
            color: (styleGuide) => styleGuide.color16,
            '& path': {
                fill: (styleGuide) => styleGuide.color16,
            }
        },
        
        '&:disabled':{
            backgroundColor: (styleGuide) => styleGuide.color1,
            opacity: 0.7,
            cursor: 'not-allowed'
        }
    }
})

const RoundIconButton3 = (props) => {
    const { styleGuide } = useContext(AppContext)
    const classes = jss(styleGuide)

    return (
        <button
            onClick={props.onClick}
            style={{...props.style}}
            disabled={props.disabled}
            className={`${classes.roundIconButton3} ${props.className}`}
        > 
            {props.icon}
        </button>
    )
}

export default RoundIconButton3;