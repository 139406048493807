import React, { useContext,useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { callAPI, callKlaviyoClientAPI } from "utils/API";
import { AppContext } from "contexts/AppContext";
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss'
import { styled, Dialog, DialogContent, DialogTitle, DialogActions, Box } from '@mui/material';
import './CPRPopUp.css';
import Activity from 'utils/Activity';
import { translate } from 'utils/Translate';

const jss = createUseStyles({
  GodkendButton: {
    backgroundColor: (styleGuide) => styleGuide.color16,
    color:'#ffffff'
  }
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
};

export const CPRPopUp=()=> {
  const { styleGuide,brandData, langCode, userData } = useContext(AppContext);
  const [CPRNumber, setCPRNumber] = useState('');
  const [CPRValidationFailed, setCPRValidationFailed] = useState(false);
  const [consent, setConsent] = useState(false);
  const [showCPRModal, setShowCPRModal] = useState(false);
  const navigate = useNavigate();
  const classes = jss(styleGuide)
  const CPRPopupLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.homePageLabels?.CPRPopupLabels
     ? brandData.features.upgraderClubConfigurableLabels[langCode].homePageLabels.CPRPopupLabels : null
 
  const updateCPRNumber = (e) => {
    setCPRValidationFailed(false)
    let value = e.target.value
    if (value.match(/^[0-9-]+$/)) {
      value = !value.includes('-') ? value.match(/.{1,6}/g).join('-') : value
      if (value.length === 11) {
        let cprNumber = value.split('-')
        let dateOfBirth = cprNumber[0]
        let sequenceNumber = cprNumber[1]
        if (!dateOfBirth.match(/^(0[1-9]|1[0-9]|2[0-9]|3[01])(0[1-9]|1[012])[0-9]{2}$/)) {
          setCPRValidationFailed(true)
        }
        if (sequenceNumber.match(/^0+$/)) {
          setCPRValidationFailed(true)
        }
      }
      setCPRNumber(value)
    } else {
      setCPRNumber(value)
      value !== '' &&  setCPRValidationFailed(true)
    }
  }

  const updateConsent = (e) => {
    setConsent(e.target.checked)
  }

  const getUserCPR = async() => {
    let getCPR = await callAPI("cpr/existsCPR", "GET")
      if (getCPR && !getCPR.error) {
        setShowCPRModal(false)
      }
      else {
        setShowCPRModal(true)
    }
  }
  
  const saveCPRNumber = async() => {
    Activity({activityType: 'click',activityName: 'Home',activityArgument: 'CPR',activityArgumentID: ''})
    let saveCPR = await callAPI("/cpr/saveCPR", "POST",
      {
        body: {
          dataToEncrypt:CPRNumber,
          consent:consent
        }
      });
    if (saveCPR && !saveCPR.error) {
      setShowCPRModal(false)
      localStorage.setItem('cprExists', 1)
      // callKlaviyoClientAPI(userData, "consentCPR", {UserID: userData.UserID}, null)
    }
  }
  
  const clearSession = () => {
    localStorage.clear()
    navigate('/logout')
  }

  const closeCPRModal = () => {
    setShowCPRModal(false)
    clearSession();
  }

  useEffect(() => {
    getUserCPR()
  },[])
    


  return (
    <>
      {showCPRModal && <div>
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={showCPRModal}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            sx={{ paddingLeft: '25px' }}>
            CPR nr.
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <p>Indtast CPR nr.:</p>
            <input
              style={{
                fontFamily: styleGuide.secondaryFontFamily,
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: 1.5,
                width: '60%',
                marginTop: '-10px',
                padding: '5px 10px 5px 10px',
                border: '1px solid lightgray',
                borderRadius: '5px',
                color: '#495057'
              }}
              type='text'
              name='CPRNumber'
              id='CPRNumber'
              placeholder='xxxxxx-xxxx'
              value={CPRNumber}
              onChange={(e) => updateCPRNumber(e)}
              required
              maxLength='11'
            />
            {CPRValidationFailed && <p style={{ color: 'red', marginLeft: '3px', fontSize: '15px' }}>Venligst indtast korrekt CPR nummer</p>}
            <Box sx={{ marginTop: '24px' }}>
              <p
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                {CPRPopupLabels?.CPRPopUpApprovalText ? CPRPopupLabels.CPRPopUpApprovalText : brandData && brandData.features && brandData.features.CPRPopUpApprovalText ? brandData.features.CPRPopUpApprovalText : translate('_CPR_APPROVAL_TEXT_', `Dit CPR-nummer skal indtastes ved første login. Dine point bliver først skattepligtige, når du aktiverer dem. Du kan trygt indtaste dit CPR-nummer, da der ikke sker nogen indberetning før aktivering. Værdien af dine aktiverede point indberettes automatisk til SKAT via systemet. Læs mere i `)}
                <a
                  rel='noopener noreferrer'
                  style={{ color: '#0056b3' }}
                  href={CPRPopupLabels?.CPRPopUpTermsURL ? CPRPopupLabels.CPRPopUpTermsURL : brandData && brandData.features && brandData.features.CPRPopUpTermsURL ? brandData.features.CPRPopUpTermsURL :'_blank'}
                  target='_blank'
                >
                  {' '} {CPRPopupLabels?.CPRPopUpTermsLinkText ? CPRPopupLabels.CPRPopUpTermsLinkText : translate('_CPR_CONDITIONS_LINK_TEXT_', 'betingelser')}.
                </a>
              </p>
            </Box>
            <Box>
              <p
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginTop: '10px'
                }}
              >
                <input type='checkbox'
                  value={consent}
                  onChange={(e) => updateConsent(e)}
                  style={{ marginRight: '12px' }} />
                {CPRPopupLabels?.CPRPopUpCheckboxText ? CPRPopupLabels.CPRPopUpCheckboxText : brandData && brandData.features && brandData.features.CPRPopUpCheckboxText ? brandData.features.CPRPopUpCheckboxText : translate('_CPR_AGREE_CHECKBOX_TEXT_',`Jeg accepterer, at værdien af mine aktiverede point indberettes til SKAT.`)}
              </p>
            </Box>
          </DialogContent>
          <DialogActions>
            <button
              className={`save_cpr ${classes.GodkendButton}`}
              style={{ padding: '5px 10px 5px 10px', height: '35px', border: 'none', borderRadius: '5px' }}
              onClick={(e) => saveCPRNumber()}
              disabled={!CPRNumber || CPRValidationFailed || CPRNumber.length !== 11 || !consent}
            >
              {translate('_ACCEPT_', 'Godkend')}
            </button>
            <button
              style={{ padding: '5px 10px 5px 10px', backgroundColor: styleGuide.color16, height: '35px', border: 'none', borderRadius: '5px', color: '#ffffff' }}
              onClick={() => closeCPRModal()}
            >
              {translate('_CANCEL_', 'Afvis')}
            </button>
          </DialogActions>
        </BootstrapDialog>
      </div>}
    </>
  );
}


