
const ArrowRightAlt = (props) =>{
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg style={props.style} width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
            <path d="M36 4L34.59 5.41L40.17 11H4V13H40.17L34.59 18.59L36 20L44 12L36 4Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth}/>
        </svg>
    )
}
export default ArrowRightAlt;