const Facebook = (props) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';
    return (
        <svg style={props.style} width="14" height="28" viewBox="0 0 14 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2323 5.09428H13.6666V0.854282C13.2466 0.796504 11.8023 0.666504 10.1202 0.666504C6.61051 0.666504 4.20626 2.87428 4.20626 6.93206V10.6665H0.333252V15.4065H4.20626V27.3332H8.95476V15.4076H12.6711L13.2611 10.6676H8.95365V7.40206C8.95476 6.03206 9.32361 5.09428 11.2323 5.09428Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth}/>
        </svg>
    )
}
export default Facebook