import React, { useEffect, useState, useContext } from "react";
import './TransactionTable.css'
import { translate } from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { pointsConvertor } from "utils/general";

const TransactionTable = ({ transactions, canSendBudgetPoints }) => {
  const { langCode, brandData } = useContext(AppContext)
  const pointsSectionLabels = brandData.features?.upgraderClubConfigurableLabels && brandData.features.upgraderClubConfigurableLabels[langCode]?.profilePageLabels?.pointsSectionLabels
    ? brandData.features.upgraderClubConfigurableLabels[langCode].profilePageLabels.pointsSectionLabels : null
  if (brandData.BrandID === 87) {
    return (
      <>
          <div className="transaction-table">
            <div className="table-head">
              <div className="th tcol-2">{pointsSectionLabels?.columnTwoLabel ? pointsSectionLabels.columnTwoLabel : 'Point'}</div>
              <div className="th tcol-2">Dato optjent / brugt</div>
              <div className="th tcol-2">Tilgængelig</div>
              <div className="th tcol-2">Udløber</div>
              <div className="th tcol-3">Point type</div>
              {/* <div className="th tcol-2 ">Note</div> */}
            </div>
            <div className="table-body">
              {transactions.map((transaction, idx) => (
                <div className="tr" key={idx}>
                  <div className="td tcol-2">{pointsConvertor(brandData,transaction.walletAmountPoints, ' ')}</div>
                  <div className="td tcol-2">{transaction.date}</div>
                  <div className="td tcol-2">{transaction.validFrom}</div>
                  <div className="td tcol-2">{transaction.expirationDate}</div>
                  <div className="td tcol-3">{transaction.type}</div>
                  {/* <div className="td tcol-2 ">{transaction.walletNote}</div> */}
                </div>
              ))}
            </div>
          </div>
      </>
    ); 
} else {
  return (
    <>
      {canSendBudgetPoints ? (
        <div className="transaction-table">
          <div className="table-head">
            <div className="th tcol-4">Note</div>
            <div className="th tcol-1">Point</div>
            {brandData.BrandID !== 87 && (
              <div className="th tcol-1">Sum</div>
            )}
            <div className="th tcol-2">{translate('_DATE_', 'Dato')}</div>
            <div className="th tcol-2">{translate('_NAME_', 'Navn')}</div>
            <div className="th tcol-2">Email</div>
            <div className="th tcol-1">UID</div>
          </div>
          <div className="table-body">
            {transactions.map((transaction, idx) => (
              <div className="tr" key={idx}>
                <div className="td tcol-4">{transaction.walletNote}</div>
                <div className="td tcol-1">{pointsConvertor(brandData,transaction.walletAmountPoints, ' ')}</div>
                {brandData.BrandID !== 87 && (
                    <div className="td tcol-1">{pointsConvertor(brandData,transaction.sum, ' ')}</div>
                )}
                <div className="td tcol-2">{transaction.date}</div>
                <div className="td tcol-2">{transaction.Navn}</div>
                <div className="td tcol-2">{transaction.Email}</div>
                <div className="td tcol-1">{transaction.UID}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="transaction-table">
          <div className="table-head">
            <div className="th tcol-5 ">{pointsSectionLabels?.columnOneLabel ? pointsSectionLabels.columnOneLabel : 'Note'}</div>
            <div className="th tcol-2">{pointsSectionLabels?.columnTwoLabel ? pointsSectionLabels.columnTwoLabel : 'Point'}</div>
            {brandData.BrandID !== 87 && (
                    <div className="th tcol-2">{pointsSectionLabels?.columnThreeLabel ? pointsSectionLabels.columnThreeLabel : 'Sum'}</div>
            )}
            <div className="th tcol-3">{pointsSectionLabels?.columnFourLabel ? pointsSectionLabels.columnFourLabel : translate('_DATE_', 'Dato')}</div>
          </div>
          <div className="table-body">
            {transactions.map((transaction, idx) => (
              <div className="tr" key={idx}>
                <div className="td tcol-5 ">{transaction.walletNote}</div>
                <div className="td tcol-2">{pointsConvertor(brandData,transaction.walletAmountPoints, ' ')}</div>
                {brandData.BrandID !== 87 && (
                  <div className="td tcol-2">{pointsConvertor(brandData,transaction.sum, ' ')}</div>
                )}
                <div className="td tcol-3">{transaction.date}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  ); 
}

};

export default TransactionTable;

export const SimpleTable = (props) => {
  const { headers, rows } = props
  // rows and headers follow this structure 
  // [{value: "", className: ""}, {value: "", className: ""} ...]

  return <>
    <div className="transaction-table">
      <div className="table-head">
        {
          headers.map((h, idx) => <div key={`${h}-${idx}`} className={h.className || "th tcol-2"}>{h.value || ""}</div>)
        }
      </div>
      <div className="table-body">
        {rows.map((r, idx) => (
          <div className="tr" key={`tr-${idx}`}>
            {r.map((item,i) => <div key={`tr-${idx}-${i}`}className={item.className || "td tcol-2"}>{item.value || ""}</div>)}            
          </div>
        ))}
      </div>
    </div>
  </>
}