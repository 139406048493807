import Translate from "utils/Translate";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { upgColors } from "styles/styles";
import { createUseStyles } from "react-jss";

const jss = createUseStyles({
  themeTitle: {
    fontSize: (styleGuide)=>styleGuide.headline1FontSize,
    lineHeight: (styleGuide)=>styleGuide.headline1LineHeight,
  },
  
  "@media screen and (max-width: 580px)":{
    themeTitle: {
      fontSize: (styleGuide)=>styleGuide.headline1FontSize - 10,
      lineHeight: (styleGuide)=>styleGuide.headline1LineHeight - 10,
  },
  },
})

const Headline1 = (props) => {
  const { styleGuide } = useContext(AppContext);
  const classes = jss(styleGuide);

    const headline1Style = {
      fontFamily: styleGuide.fontFamily,
      fontWeight: styleGuide.headline1FontWeight,
      fontStyle: 'normal',
      color: styleGuide.color7,
      fontSize: styleGuide.headline1FontSize,
      lineHeight: styleGuide.headline1LineHeight
    }

  
    return (
      <span style={{ ...headline1Style, ...props.style }} className={`${props.className ? props.className : ''} ${classes[props.className]}`}>
        <Translate translationKey={props.translationKey}>{props.fallbackText || props.children}</Translate>
      </span>
    )
}

export default Headline1;