import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "contexts/AppContext";
import { translate } from "utils/Translate";
import Image from "components/elements/Image";
import "./PersonalShopper.css";
import CustomInput2 from "components/elements/CustomInput2";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import { callAPI } from "utils/API";
import { newLineToBreakTag } from "utils/general";
import ContentNormal from "components/elements/ContentNormal";
import Headline2 from "components/elements/Headline2";
import RoundIconButton1 from "components/elements/RoundIconButton1";
import Plus from "components/icons/Plus";
import Dash from "components/icons/Dash";

let mailInit = {
  nameOfProduct: "",
  links: [""],
  antal:"",
  specification: "",
  note:""
}

const PersonalShopper = () => {
  const contextState = useContext(AppContext);
  const { brandData } = contextState;
  const bannerImageHeight = 560;
  const personalShopperBannerImage =
    brandData.features.personalShopperBannerImageURL ||
    "https://i.imgur.com/NPB3IhS.png";
  const imageHeight = brandData.features.personalShopperImageHeight
    ? `${brandData.features.personalShopperImageHeight}px`
    : bannerImageHeight;
  const [pageHeight, setPageHeight] = useState("100vh")
  
  return (
    <>
    <div id="personalShopperContent" style={{height: pageHeight}}>
      <div
        style={{ display: "flex", flexDirection: "column" }}
      >
        {!!personalShopperBannerImage ? (
          <>
            <Image
              src={`url(${personalShopperBannerImage})`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 0,
                height: imageHeight,
                width: "100%",
              }}
            ></Image>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: imageHeight,
          marginRight: 24,
        }}
      >
      </div>
      <WriteToUs imageHeight={imageHeight} setPageHeight={(num) => {
        setPageHeight(num)
      }}/>
    </div>
    </>
  );
};

export const WriteToUs = (props) => {
  const contextState = useContext(AppContext);
  const { styleGuide, brandData, langCode, userData} = contextState;
  const [mail, setMail] = useState(mailInit)
  const [links, setLinks] = useState([""])
  const writeToUsRef = useRef(null)
  const {setPageHeight, imageHeight} = props
  const personalShopperTextColor = brandData.features.personalShopperTextColorBlack ? styleGuide.color18 : styleGuide.color1;
  let customerNumber;
  const sendMailToUs =  async () => {
    if (brandData.BrandID === 87) {
      if (userData.selectedCustomerNumber) {
        customerNumber = userData.selectedCustomerNumber
      }
    }
    const response = await callAPI("/personalShopper/writetous","POST", {body: {mailContent: {...mail, links}, brand: brandData}}, null, customerNumber ? customerNumber : null)
    if(response.message === "Email sent Sucessfully") {
      alert("Din besked er afsendt")
    } else {
      alert(response.error)
    }
    setMail(mailInit)
    setLinks([])
  }


  const handleLinksChange = (evt, idx) => {
    const newLinks = links.map((link, sidx) => {
      if (idx !== sidx) return link;
      return evt.target.value
    });
    setLinks(newLinks)
  };

  const handleAddLink = () => {
    setLinks([...links, ""])
  };

  const handleRemoveLink = idx => {
    setLinks(links.filter((s, sidx) => idx !== sidx))
  };

  let { personalShopperDescription } = brandData && brandData.features
  const description = personalShopperDescription && typeof personalShopperDescription === 'object' ?
    personalShopperDescription.hasOwnProperty(langCode) ? personalShopperDescription[langCode] : 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium, illum doloribus. Corrupti aut sint praesentium mollitia, nihil aperiam alias fugiat inventore.'
    : personalShopperDescription ? personalShopperDescription : 'Lorem ipsum dolor sit amet consectetur adipisicing elit.Laudantium, illum doloribus. Corrupti aut sint praesentium mollitia, nihil aperiam alias fugiat inventore.'
  
  useEffect(()=>{
    const bannerHeight = isNaN(imageHeight) ? Number(imageHeight?.match(/\d/g)?.join("")) : Number(imageHeight)
    const writeToUsBlockHeight = writeToUsRef?.current?.clientHeight || 0

    setPageHeight( bannerHeight + writeToUsBlockHeight - 100)
  },[writeToUsRef?.current?.clientHeight])

  useEffect(()=>{
    document.querySelector(".page-wrapper").style.backgroundColor = styleGuide.color14
    return () => document.querySelector(".page-wrapper").style.backgroundColor = "white"
  },[])

  return <>
    <div ref={writeToUsRef} className="writeToUs" style={{ position: "absolute", top: imageHeight, left: 0, right: 0, zIndex: 0, width: "100%", backgroundColor: styleGuide.color14, height: "max-content" }}>
      <div className="personal-shopper-wrapper">
        <div>
          <Headline2 style={{ color: personalShopperTextColor }} translationKey="_PERSONAL_SHOPPER_PAGE_TITLE_" fallbackText="Personal Shopper" />
          <br />
          <br />
          <ContentNormal renderHtml={true}  style={{ color: personalShopperTextColor }} fallbackText={newLineToBreakTag(description)} />
        </div>
        <div>
          <Headline2 style={{ color: personalShopperTextColor }} translationKey="_WRITE_TO_US" fallbackText="Skriv til os" />
          <br />
          <br />
          <ContentNormal
            style={{ color: personalShopperTextColor, marginBottom: "40px" }}
            renderHtml={true} 
            translationKey="_WRITE_TO_US_NOTE"
            fallbackText="Send os et link til det produkt, du godt kunne tænke dig. Så vil vores Personal Shopper se om det er muligt at opfylde dit ønske."
          />
          
          <CustomInput2 value={mail.nameOfProduct} placeholder={translate("_PERSONAL_SHOPPER_QUESTION_ENTITY_PLACEHOLDER_", "Skriv produkt, mærke eller lignende")} style={{ color: personalShopperTextColor, marginBottom: "40px", marginTop: 30 }} placeholderColor={styleGuide.color16} onChange={(e) => { setMail({ ...mail, nameOfProduct: e.target.value }) }} />
          
          {
            links?.map((link, idx) => <div 
              key={idx} 
              style={{display: "flex", justifyContent:"space-between"}}
              >
              <CustomInput2 
                value={link} 
                placeholderColor={styleGuide.color16} 
                placeholder={translate("_PERSONAL_SHOPPER_LINK_PLACEHOLDER_", "Indsæt link")} 
                style={{ color: personalShopperTextColor, marginBottom: "40px" }} 
                wrapperStyle={{width: "80%"}}
                onChange={(e) => handleLinksChange(e, idx)} 
              />
              {
                !(idx === links.length - 1)
                ?  <RoundIconButton1 
                    style={{maxWidth: "20%"}} 
                    icon={<Dash width={24} height={24} fill="white" />} 
                    onClick={() => handleRemoveLink(idx)}
                  />
                :  <RoundIconButton1 
                    style={{maxWidth: "20%"}} 
                    icon={<Plus width={24} height={24} fill="white" />} 
                    onClick={() => handleAddLink()}
                  />
              }
            </div>)
          }

          <CustomInput2 
            type="number"
            value={mail.antal} 
            placeholderColor={styleGuide.color16} 
            placeholder={translate("_PERSONAL_SHOPPER_NUMBER_OF_ITEMS_PLACEHOLDER_", "Antal")} 
            style={{ color: personalShopperTextColor, marginBottom: "40px" }} 
            onChange={(e) => { setMail({ ...mail, antal: e.target.value >= 0 ? e.target.value : 0 }) }} 
          />
      
          <CustomInput2 value={mail.specification} placeholderColor={styleGuide.color16} placeholder={translate("_PERSONAL_SHOPPER_DETAILS_PLACEHOLDER_", "Indtast specifikationer: størrelse, farve, udgave eller lign.")} style={{ color: personalShopperTextColor, marginBottom: "40px" }} onChange={(e) => { setMail({ ...mail, specification: e.target.value }) }} />
          
          <CustomInput2 value={mail.note} placeholder={translate("_PERSONAL_SHOPPER_NOTE_PLACEHOLDER_", "Note")} style={{ color: personalShopperTextColor, marginBottom: "60px" }} placeholderColor={styleGuide.color16} onChange={(e) => { setMail({ ...mail, note: e.target.value }) }} />
          <ButtonLarge1 fallbackText="Send" style={{ fontSize: "18px", lineHeight: "28px", borderRadius: "14px" }} onClick={sendMailToUs} ></ButtonLarge1>
        </div>
      </div>

    </div>
  </>
}

export default PersonalShopper;
