import { AppContext } from "contexts/AppContext";
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";

const jss = createUseStyles({
  cookiePage: {
    width: '100%',
    padding: '5% 10%',
    '& h1':{
      fontFamily: (styleGuide) => styleGuide.fontFamily,
      fontSize: (styleGuide) => styleGuide.headline1FontSize,
      fontWeight: '600',
      color: (styleGuide) => styleGuide.color7,
      marginBottom: 50
    },
    '& h3':{
      fontFamily: (styleGuide) => styleGuide.fontFamily,
      fontSize: (styleGuide) => styleGuide.headline3FontSize,
      fontWeight: '600',
      color: (styleGuide) => styleGuide.color7
    },
    '& p, & ul, & li':{
      fontFamily: (styleGuide) => styleGuide.secondaryFontFamily,
      fontSize: (styleGuide) => styleGuide.contentLargeFontSize,
      lineHeight: (styleGuide) => styleGuide.contentLargeLineHeight,
      marginBottom: 0
    }
  },
  '@media (min-width:1280px)':{
    cookiePage: {
      maxWidth: '75%',
      overflowWrap: 'anywhere',
      padding: '50px 100px'
    },
  }
})

const CookiePage = (props) => {
  const { langCode, brandData, styleGuide } = useContext(AppContext);
  const classes = jss(styleGuide)

  return <div className={classes.cookiePage}>
    <h1>
      { brandData?.features?.cookiePageClub?.[langCode]?.header ? brandData.features.cookiePageClub[langCode].header :  brandData.features?.cookiePageClub?.header || 'Cookie Policy' }
    </h1>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', whiteSpace: 'pre-line' }}
      dangerouslySetInnerHTML={{
        __html: brandData?.features?.cookiePageClub?.[langCode]?.content ? brandData.features.cookiePageClub[langCode].content : brandData.features?.cookiePageClub?.content || 'Cookie Policy'
      }}
    />
  </div>
};

export default CookiePage;
