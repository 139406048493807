const CaretDown2 = ( props ) => {
    const pathFill = props.fill || 'black';
    const pathStroke = props.stroke || 'inherit';
    const strokeWidth = props.strokeWidth || '1px';

    return (
        <svg style={props.style} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">
            <path d="M1.88 10L8 3.81916L14.12 10L16 8.09717L8 -6.99382e-07L1.66351e-07 8.09716L1.88 10Z" fill={pathFill} stroke={pathStroke} strokeWidth={strokeWidth} />
        </svg>
    )
}
export default CaretDown2
