import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import Image from 'components/elements/Image';
import './Information.css'
import { translate } from 'utils/Translate';
import ButtonLarge1 from 'components/elements/ButtonLarge1';
import ButtonMedium1 from 'components/elements/ButtonMedium1';
import ButtonLarge3 from 'components/elements/ButtonLarge3';
import Activity from 'utils/Activity';

function hexToRgb(hex, a) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${a})` : null;
}


const Information = (props) => {
  const navigate = useNavigate()
  const {information, buttonTitle} = props
  const { styleGuide, brandData } = useContext(AppContext);
 
  const informationColor = brandData && brandData.features && brandData.features.informationTextColorBlack
  const informationButtonTitle = buttonTitle

  const colorhex1 = hexToRgb(styleGuide.color16, 0)
  const colorhex2 = hexToRgb(styleGuide.color16, 0.5)
  
  return <div style={{...props.styles, position: "relative"}}>
    <Image
      className="informationModal"
      src={`url(${information?.bannerImage})`}
      style={{
        width: "100%",
        objectFit: 'cover'
      }}
    > 
      <div className="informationBox informationBoxFalse" style={{background: `linear-gradient(248.66deg, ${colorhex1} 0%, ${colorhex2} 100%)`}}>
        <div style={{color: informationColor ? styleGuide.color18 : styleGuide.color1}} className='informationTitle'>{information.elementTitle}</div>
        <div style={{color: informationColor ? styleGuide.color18 : styleGuide.color1}} className="informationContent informationContentFalse">{information.elementContent}</div>
        <div className="informationButtonM">
          <ButtonMedium1 onMedium1 showIcon={false} style={{ maxWidth: 'max-content' }}
            fallbackText={informationButtonTitle ? translate('_INFORMATION_BUTTON_TITLE_', informationButtonTitle) : ''}
            onClick={() => {
              Activity({activityType: 'click',activityName: 'home',activityArgument: 'Post',activityArgumentID: information.PostID})
              navigate(`post/${information.PostID}`)}}/>
          </div>
        <div className="informationButtonL">
          <ButtonLarge1 style={{ maxWidth: 'max-content' }}
            fallbackText={informationButtonTitle ? translate('_INFORMATION_BUTTON_TITLE_', informationButtonTitle) : ''}
            onClick={() => {
              Activity({activityType: 'click',activityName: 'home',activityArgument: 'Post',activityArgumentID: information.PostID})
              navigate(`post/${information.PostID}`)}}/>
        </div>
      </div>
    </Image>
  </div>
}

export default Information