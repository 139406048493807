import React, { createContext, useEffect, useState } from "react";
import { definedStyles } from 'theme/styles'
import { config } from "theme/config";

export const LangCodes = {
    ENGLISH : 'en',
    DANISH : 'dk'
}

export const Themes = ['ThemeONE', 'ThemeTWO', 'ThemeTHREE', 'ThemeFOUR']

export const AppContext = createContext()

export const AppProvider = props => {
    const [defaultTheme, setDefaultTheme] = useState('ThemeONE')
    const [styleGuide, setStyleGuide] = useState(definedStyles[defaultTheme])
    const [themeConfig, setThemeConfig] = useState(config[defaultTheme])
    const [userData, setUserData] = useState(null)
    const [brandData, setBrandDataState] = useState(null)
    const [langCode, setLangCode] = useState(LangCodes.DANISH)
    const [translations, setTranslations] = useState({});
    const [menuData, setMenuData] = useState(null);
    const [accessibleBlogs, setAccessibleBlogs] = useState([])
    const [accessibleShops, setAccessibleShops] = useState([])
    const [accessibleHOFs, setAccessibleHOFs] = useState([])
    const [basket, setBasket] = useState([])
    const [statusScroll, setStatusscroll] = useState(false);
    const [pointStatus, setPointstatus] = useState(false);
    const [showBasketQuickView, setshowBasketQuickView] = useState(false)
    const [maintenance, setMaintenance] = useState(false)
    const [downForMaintenance,setDownForMaintenance]=useState(false)
    const [isSuperAdmin,setIsSuperAdmin]=useState(false)
    const setBrandData = (newState = {}) => {
        const brandTheme = (newState && newState.features && newState.features.BrandTheme && Themes.includes(newState.features.BrandTheme)) ? newState.features.BrandTheme : defaultTheme
        const color14 = newState && newState.features && newState.features.primaryColor1 || definedStyles[brandTheme].color14
        const color15 = newState && newState.features && newState.features.primaryColor2 || definedStyles[brandTheme].color15
        const color16 = newState && newState.features && newState.features.secondaryColor1 || definedStyles[brandTheme].color16
        const color17 = newState && newState.features && newState.features.secondaryColor2 || definedStyles[brandTheme].color17
        const color26 = newState && newState.features && newState.features.tertiaryColor1 || definedStyles[brandTheme].color26
        const fontFamily =  newState && newState.brandHeaderFont && newState.brandHeaderFont.fontName || definedStyles[brandTheme].fontFamily
        const secondaryFontFamily =  newState && newState.brandBodyFont && newState.brandBodyFont.fontName || definedStyles[brandTheme].secondaryFontFamily
        const headline1FontSize =  newState && newState.brandHeaderFontSize ? newState.brandHeaderFontSize : definedStyles[brandTheme].headline1FontSize
        const headline2FontSize =  newState && newState.brandHeaderFontSize ? newState.brandHeaderFontSize - 10 : definedStyles[brandTheme].headline2FontSize
        const headline3FontSize =  newState && newState.brandHeaderFontSize ? newState.brandHeaderFontSize - 20 : definedStyles[brandTheme].headline3FontSize
        const headline4FontSize =  newState && newState.brandHeaderFontSize ? newState.brandHeaderFontSize - 26 : definedStyles[brandTheme].headline4FontSize
        const headline5FontSize =  newState && newState.brandHeaderFontSize ? newState.brandHeaderFontSize - 32 : definedStyles[brandTheme].headline5FontSize
        const headline6FontSize =  newState && newState.brandHeaderFontSize ? newState.brandHeaderFontSize - 34 : definedStyles[brandTheme].headline6FontSize
        const captionFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize - 4 : definedStyles[brandTheme].captionFontSize
        const contentLargeFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize + 4 : definedStyles[brandTheme].contentLargeFontSize
        const contentNormalFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize  : definedStyles[brandTheme].contentNormalFontSize
        const contentSmallFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize - 4 : definedStyles[brandTheme].contentSmallFontSize
        const explainerTextFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize - 4 : definedStyles[brandTheme].explainerTextFontSize
        const explainerTextTinyFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize - 6 : definedStyles[brandTheme].explainerTextTinyFontSize
        const quoteLargeFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize + 4 : definedStyles[brandTheme].quoteLargeFontSize
        const quoteNormalFontSize =  newState && newState.brandBodyFontSize ? newState.brandBodyFontSize : definedStyles[brandTheme].quoteNormalFontSize
        if(newState && newState.brandHeaderFont && newState.brandHeaderFont.fontUrl) {
            const headerFontLink = document.createElement('link');
            headerFontLink.href = newState.brandHeaderFont.fontUrl;
            headerFontLink.rel = 'stylesheet';
            document.body.style.fontFamily = fontFamily;
            document.head.appendChild(headerFontLink)
        }
        if(newState && newState.brandBodyFont && newState.brandBodyFont.fontUrl) {
            const bodyFontLink = document.createElement('link');
            bodyFontLink.href = newState.brandBodyFont.fontUrl;
            bodyFontLink.rel = 'stylesheet';
            document.head.appendChild(bodyFontLink)
        }
        const deviceLanguage = localStorage.getItem('langCode')
        setBrandDataState(newState)
        setDefaultTheme(brandTheme)
        setStyleGuide({
            ...definedStyles[brandTheme], 
            color14, color15, color16, color17, color26, 
            fontFamily, secondaryFontFamily,
            headline1FontSize, headline2FontSize, headline3FontSize, headline4FontSize, headline5FontSize, headline6FontSize,
            captionFontSize, contentLargeFontSize, contentNormalFontSize, contentSmallFontSize, explainerTextFontSize, explainerTextTinyFontSize, quoteLargeFontSize, quoteNormalFontSize
        })
        setThemeConfig(config[brandTheme])
        setLangCode(deviceLanguage ? deviceLanguage : newState?.LanguageID === 2 ? LangCodes.ENGLISH : LangCodes.DANISH)
        updateTranslations()
    }
    const value = {
        defaultTheme,
        styleGuide,
        themeConfig,
        userData,
        setUserData,
        langCode,
        setLangCode,
        translations,
        setTranslations,
        brandData,
        setBrandData,
        menuData,
        setMenuData,
        accessibleBlogs,
        setAccessibleBlogs,
        accessibleShops,
        setAccessibleShops,
        accessibleHOFs,
        setAccessibleHOFs,
        basket, 
        setBasket,
        showBasketQuickView, 
        setshowBasketQuickView,
        statusScroll, 
        setStatusscroll,
        pointStatus,
        setPointstatus,
        maintenance,
        setMaintenance,
        downForMaintenance,
        setDownForMaintenance,
        isSuperAdmin,
        setIsSuperAdmin
    }
    const updateTranslations = () => {
        if(!brandData) return
        const translationValues = {}
        brandData.translations.filter(item => item.languageKey === langCode).forEach(element => {
            translationValues[element.translationKey] = element.translationValue
        });
        setTranslations(translationValues)
    }

    useEffect(() => {
        updateTranslations()
    },[langCode, brandData])

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    )
}