import { LogLevel } from "@azure/msal-browser";
import { STARK_MSAL } from "MSALAuthConfig";

export const starkMsalConfig = {
    auth: {
        clientId: STARK_MSAL.CLIENT_ID,
        authority:STARK_MSAL.AUTHORITY,
        knownAuthorities: STARK_MSAL.KNOWN_AUTHORITY,
        redirectUri: STARK_MSAL.REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage", 
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
        allowNativeBroker: false,
    },
};

export const loginRequest = {
    scopes: ["openid", "profile"]
}