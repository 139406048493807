import { translate } from "utils/Translate";
import ContentLarge from "components/elements/ContentLarge";
import ContentNormal from "components/elements/ContentNormal";
import ContentSmall from "components/elements/ContentSmall";
import Headline1 from "components/elements/Headline1";
import Headline2 from "components/elements/Headline2";
import Headline3 from "components/elements/Headline3";
import Headline4 from "components/elements/Headline4";
import Headline5 from "components/elements/Headline5";
import Headline6 from "components/elements/Headline6";
import QuoteLarge from "components/elements/QuoteLarge";
import QuoteNormal from "components/elements/QuoteNormal";
import ExplainerText from 'components/elements/ExplainerText';
import ExplainerTextTiny from "components/elements/ExplainerTextTiny";
import ButtonLarge1 from "components/elements/ButtonLarge1";
import ButtonLarge2 from "components/elements/ButtonLarge2";
import ButtonLarge3 from "components/elements/ButtonLarge3";
import ButtonLarge4 from "components/elements/ButtonLarge4";
import IconButtonLarge from "components/elements/IconButtonLarge";
import Heart2 from "components/icons/Heart2";
import { upgColors } from "styles/styles";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium2 from "components/elements/ButtonMedium2";
import ButtonMedium3 from "components/elements/ButtonMedium3";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import IconButtonMedium from "components/elements/IconButtonMedium";
import Cart1 from "components/icons/Cart1";
import ArrowLeft from "components/icons/ArrowLeft";
import RoundIconButton1 from "components/elements/RoundIconButton1";
import RoundIconButton2 from "components/elements/RoundIconButton2";
import RoundIconButton3 from "components/elements/RoundIconButton3";
import RoundIconButton4 from "components/elements/RoundIconButton4";
import LabelTag1 from "components/elements/LabelTag1";
import LabelTag2 from "components/elements/LabelTag2";
import CustomInput from "components/elements/CustomInput";
import { useContext, useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import CustomSelect from "components/elements/CustomSelect";
import CustomCheckboxSet from "components/elements/CustomCheckboxSet";
import { AppContext } from "contexts/AppContext";
import { callAPI } from 'utils/API';
import CaptionText from "components/elements/CaptionText";
import Image from "components/elements/Image";
import ProductLarge from "components/elements/ProductLarge";
import MultipleSelectCheckmarks from "components/elements/MultipleSelectCheckmarks";
import CustomInput2 from "components/elements/CustomInput2";

const Example = () => {
    const { styleGuide, setBrandData } = useContext(AppContext)
    const [sampletext1, setsampletext1] = useState('')
    const [sampletext2, setsampletext2] = useState('')
    const [sampletext3, setsampletext3] = useState('')
    const [sampletext4, setsampletext4] = useState('')
    const [sampletext5, setsampletext5] = useState('')
    const [sampletext6, setsampletext6] = useState('')
    const [sampleMultiSelect1, setsampleMultiSelect1] = useState([])
    const [sampleCheckboxSet1, setsampleCheckboxSet1] = useState([])
    const [sampletext7, setsampletext7] = useState('')
    const [samplepass, setsamplepass] = useState('')

    const handleMultiSelectChange = (event) => {
      const {target: { value }} = event;
      setsampleMultiSelect1(value);
    };

    const handleCheckboxSetChange = (event) => {
      const {target: { value }} = event;
      setsampleCheckboxSet1(value);
    };

    useEffect(async () => {
      const brandInfo = await callAPI('/brand/setup','GET')
      if(brandInfo) {
        setBrandData(brandInfo)
      }
    },[])
    const [multiSelectValue, setMultiSelectValue] = useState([]);
    const setMultiSelectValueArray = (val) => {
      let currentArray = multiSelectValue
      currentArray.push(val)
      setMultiSelectValue(currentArray)
    }
    const handleChangeMultiSelect = (event) => {
      const {
          target: { value },
      } = event;

      setMultiSelectValue(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
      );
    };
    useEffect(() => {
      console.log(multiSelectValue)
    },[multiSelectValue])

    return (
      <div style={{ padding: 20, fontFamily: styleGuide.fontFamily }}>
        <MultipleSelectCheckmarks 
            options={[
              'one',
              'two',
              'thrëę',
              'four',
            ]}
            label='Multi select'
            wrapperStyle={{marginBottom: 10}}
            value={multiSelectValue}
            onChange={handleChangeMultiSelect}
            renderValue={() => multiSelectValue.join(', ')}
        />

        <div style={{backgroundColor: styleGuide.color14, padding: 10}}>
          
          <Headline1 style={{color: styleGuide.color1}} translationKey=''>...headline1...</Headline1>
          <Headline1 translationKey=''>...headline1...</Headline1>
          <br/>
          <Headline2 style={{color: styleGuide.color1}} translationKey=''>...headline2...</Headline2>
          <Headline2 translationKey=''>...headline2...</Headline2>
          <br/>
          <Headline3 style={{color: styleGuide.color1}} translationKey=''>...headline3...</Headline3>
          <Headline3 translationKey=''>...headline3...</Headline3>
          <br/>
          <Headline4 style={{color: styleGuide.color1}} translationKey=''>...headline4...</Headline4>
          <Headline4 translationKey=''>...headline4...</Headline4>
          <br/>
          <Headline5 style={{color: styleGuide.color1}} translationKey=''>...headline5...</Headline5>
          <Headline5 translationKey=''>...headline5...</Headline5>
          <br/>
          <Headline6 style={{color: styleGuide.color1}} translationKey=''>...headline6...</Headline6>
          <Headline6 translationKey=''>...headline6...</Headline6>
          <br/>
          <br/>
          
          =======================================================
          <br/>
          <br/>
          <ButtonLarge1 fallbackText="button large1 with icon"/>
          <br/>
          <br/>
          <ButtonLarge1 showIcon={false} fallbackText="button large1 without icon" />
          <br/>
          <br/>
          <ButtonLarge2 fallbackText="button large2 with icon"></ButtonLarge2>
          <br/>
          <br/>
          <ButtonLarge2 showIcon={false} fallbackText="button large2 without icon"></ButtonLarge2>
          <br/>
          <br/>
          <ButtonLarge3 fallbackText="button large3 with icon"></ButtonLarge3>
          <br/>
          <br/>
          <ButtonLarge3 showIcon={false} fallbackText="button large3 without icon"></ButtonLarge3>
          <br/>
          <br/>
          <ButtonLarge4 fallbackText="button large4 with icon"></ButtonLarge4>
          <br/>
          <br/>
          <ButtonLarge4 icon={<Heart2 fill='white' stroke={upgColors.grayDarkest} strokeWidth="1.5px" style={{marginLeft: '64px'}}/>} fallbackText="button large4 with custom icon"></ButtonLarge4>
          <br/>
          <br/>
          <IconButtonLarge icon={<Heart2 fill='white' stroke={upgColors.grayDarkest} strokeWidth="1.5px"/>}></IconButtonLarge>
          <br/>
          <br />
          <IconButtonLarge inverse={true} icon={<Heart2 fill='white' stroke={upgColors.grayDarkest} strokeWidth="1.5px"/>}></IconButtonLarge>
          <br/>
          <br />
        </div>
          =======================================================
          <br/>
        <br />
        <div style={{backgroundColor: styleGuide.color15, padding: 10}}>
          <ButtonMedium1 fallbackText="button Medium1 with icon"></ButtonMedium1>
          <br/>
          <br/>
          <ButtonMedium1 fallbackText="13.023 pt" icon={<Cart1 fill='white' style={{marginLeft: '34px'}}/>}></ButtonMedium1>
          <br/>
          <br/>
          <ButtonMedium1 showIcon={false} fallbackText="button Medium1 without icon"></ButtonMedium1>
          <br/>
          <br/>
          <ButtonMedium2 fallbackText="button Medium2 with icon"></ButtonMedium2>
          <br/>
          <br/>
          <ButtonMedium2 showIcon={false} fallbackText="button Medium2 without icon"></ButtonMedium2>
          <br/>
          <br/>
          <ButtonMedium3 fallbackText="button Medium3 with icon"></ButtonMedium3>
          <br/>
          <br/>
          <ButtonMedium3 showIcon={false} fallbackText="button Medium3 without icon"></ButtonMedium3>
          <br/>
          <br/>
          <ButtonMedium4 fallbackText="button Medium4 with icon"></ButtonMedium4>
          <br/>
          <br/>
          <ButtonMedium4 fallbackText="13.023 pt" showIcon={false}></ButtonMedium4>
          <br/>
          <br/>
          <ButtonMedium4 icon={<Heart2 fill='white' stroke={upgColors.grayDarkest} strokeWidth="1.5px" style={{marginLeft: '64px'}}/>} fallbackText="button Medium4 with custom icon"></ButtonMedium4>
          <br/>
          <br/>
          <IconButtonMedium icon={<Heart2 fill='white' style={{height: 10, width: 10}} stroke={upgColors.grayDarkest} strokeWidth="1.5px"/>}></IconButtonMedium>
          <br/>
          <br/>
          <IconButtonMedium inverse={true} icon={<Heart2 fill='white' style={{height: 10, width: 10}} stroke={upgColors.grayDarkest} strokeWidth="1.5px"/>}></IconButtonMedium>
          <br/>
          <br/>
          <RoundIconButton1 icon={<ArrowLeft fill="white"/>}></RoundIconButton1>
          <br/>
          <br/>
          <RoundIconButton2 icon={<ArrowLeft fill="white"/>}></RoundIconButton2>
          <br/>
          <br/>
          <RoundIconButton3 icon={<ArrowLeft fill="white"/>}></RoundIconButton3>
          <br/>
          <br/>
          <RoundIconButton4 icon={<ArrowLeft fill="white"/>}></RoundIconButton4>
          <br/>
          <br />
          =======================================================  
          <br/>
          <br />
          <CaptionText style={{ color: styleGuide.color3 }} translationKey=''>...CaptionText...</CaptionText>
          <CaptionText translationKey=''>...CaptionText...</CaptionText>
          <br/>
          <br />
          =======================================================  
          <br/>
          <br />
          <QuoteLarge includeQuotes={false} style={{color: styleGuide.color1}} translationKey=''>...QuoteLarge...</QuoteLarge>
          <QuoteLarge translationKey=''>...QuoteLarge...</QuoteLarge>
          <br/>
          <QuoteNormal style={{color: styleGuide.color1}} translationKey=''>...QuoteNormal...</QuoteNormal>
          <QuoteNormal includeQuotes={false} translationKey=''>...QuoteNormal...</QuoteNormal>
          <br/>
          <br/>
          =======================================================
          <br/>
          <br/>
          <ContentSmall style={{color: styleGuide.color1}} translationKey=''>...ContentSmall...</ContentSmall>
          <ContentSmall translationKey=''>...ContentSmall...</ContentSmall>
          <br/>
          <ContentNormal style={{color: styleGuide.color1}} translationKey=''>...ContentNormal...</ContentNormal>
          <ContentNormal translationKey=''>...ContentNormal...</ContentNormal>
          <br/>
          <ContentLarge style={{color: styleGuide.color1}} translationKey=''>...ContentLarge...</ContentLarge>
          <ContentLarge translationKey=''>...ContentLarge...</ContentLarge>
          <br/>
          <br/>
          =======================================================
          <br/>
          <br/>
          <ExplainerText style={{color: styleGuide.color1}} translationKey=''>...ExplainerText...</ExplainerText>
          <ExplainerText translationKey=''>...ExplainerText...</ExplainerText>
          <br/>
          <ExplainerTextTiny style={{color: styleGuide.color1}} translationKey=''>...ExplainerTextTiny...</ExplainerTextTiny>
          <ExplainerTextTiny translationKey=''>...ExplainerTextTiny...</ExplainerTextTiny>
          <br/>
          <br />
          <Row>
            <Col sm={3}>
              <CustomInput2 placeholder="placeholder" value={sampletext6} onChange={(e) => setsampletext6(e.target.value)}/>
            </Col>
          </Row>
          </div>

          =======================================================
          <br/>
          <br />
          <LabelTag1 fallbackText="13.023 pt"></LabelTag1>
          <br/>
          <br/>
          <LabelTag1  showClose={false} fallbackText="label/tag without close"></LabelTag1>
          <br/>
          <br/>
          <LabelTag2 fallbackText="13.023 pt"></LabelTag2>
          <br/>
          <br/>
          <Row>
            <Col sm={3}>
              <CustomInput label="label" placeholder="placeholder" value={sampletext1} onChange={(e) => setsampletext1(e.target.value)}/>
            </Col>
            <Col sm={3}>
              <CustomInput label={translate('_PASSWORD_LABEL_','password')} placeholder="placeholder" value={samplepass} onChange={(e) => setsamplepass(e.target.value)} type='password' required/>
            </Col>
            <Col sm={3}>
              <CustomInput disabled label={translate('_PASSWORD_LABEL_','disabled and empty')} placeholder="" value="" type='email' required showLock={true}/>
            </Col>
            <Col sm={3}>
              <CustomInput disabled label={translate('_PASSWORD_LABEL_','disabled but has some value')} placeholder="" value="some text" type='email' required showLock={true}/>
            </Col>
          </Row>
          <br/>
          <br/>
          <Row>
            <Col sm={3}>
              <CustomInput error={false} helpObj={{helpText: translate('_SOME_', 'help text'), errorText: 'error text' }} label="label" placeholder="placeholder" value={sampletext2} onChange={(e) => setsampletext2(e.target.value)}/>
            </Col>
            <Col sm={3}>
              <CustomInput required error={!sampletext3} helpObj={{helpText: translate('_SOME_', 'help text'), errorText: 'error text - mandatory field' }} label="label" placeholder="placeholder" value={sampletext3} onChange={(e) => setsampletext3(e.target.value)}/>
            </Col>
          </Row>
          <br/>
          <br/>
          <Row>
            <Col sm={3}>
              <CustomSelect 
                helpObj={{helpText: translate('_SOME_', 'help text'), errorText: 'error text' }} 
                label="select field" 
                value={sampletext4} 
                onChange={(e) => setsampletext4(e.target.value)}
                options={[
                  {value: 10, label: 'ten'},
                  {value: 20, label: 'twenty'},
                  {value: 30, label: 'thirty'},
                  {value: 40, label: 'forty'},
                  {value: 50, label: 'fifty'},
                ]}
                />
            </Col>
            <Col sm={3}>
              <CustomSelect 
                emptyValue={{text: 'empty value'}}
                error={!sampletext5}
                helpObj={{helpText: translate('_SOME_', 'help text'), errorText: 'error text - this needs a value' }} 
                label="select field" 
                value={sampletext5} 
                onChange={(e) => setsampletext5(e.target.value)}
                options={[
                  {value: 10, label: 'ten'},
                  {value: 20, label: 'twenty'},
                  {value: 30, label: 'thirty'},
                  {value: 40, label: 'forty'},
                  {value: 50, label: 'fifty'},
                ]}
                />
            </Col>
            <Col sm={3}>
              <CustomSelect 
                disabled
                label="value = previous item for demo" 
                value={sampletext5} 
                onChange={(e) => setsampletext5(e.target.value)}
                options={[
                  {value: 10, label: 'ten'},
                  {value: 20, label: 'twenty'},
                  {value: 30, label: 'thirty'},
                  {value: 40, label: 'forty'},
                  {value: 50, label: 'fifty'},
                ]}
                />
            </Col>
            <Col sm={3}>
              <CustomSelect 
                helpObj={{helpText: translate('_SOME_', 'help text - click the checkbox'), errorText: 'error text' }} 
                label="select field" 
                value={sampleMultiSelect1}
                multiple
                renderValue={(selected) => selected.join(', ')}
                onChange={(e) => handleMultiSelectChange(e)}
                options={[
                  {value: 'ten', label: 'ten'},
                  {value: 'twenty', label: 'twenty'},
                  {value: 'thirty', label: 'thirty'},
                  {value: 'forty', label: 'forty'},
                  {value: 'fifty', label: 'fifty'},
                ]}
              />
            </Col>
          </Row>
          <br />
          <br />
          {/* <Row>
            <Col sm={3}>
              <CustomCheckboxSet 
              value={sampleCheckboxSet1}
              onChange={(e) => handleCheckboxSetChange(e)}
              items={[
                {label: 'option1', value: 'option1'},
                {label: 'option2', value: 'option2'},
                {label: 'option3', value: 'option3'},
                {label: 'option4', value: 'option4'},
                {label: 'option5', value: 'option5'},
              ]} 
              />
            </Col>
            <Col sm={3}></Col>
            <Col sm={3}></Col>
            <Col sm={3}></Col>
          </Row> */}
      </div>
    )
}
export default Example;